import React, { useState, useEffect } from 'react'
import { Button, Columns, Heading, Box, Image, Card, Table, Form } from 'react-bulma-components/dist'
import Images from './Images'
import { CustomerPic, Measurement } from '../utilities/Classes'
import { imageUrlForPostures, MeasurementType } from '../utilities/enums'
import cogoToast from 'cogo-toast'


const ClientMeasurementsInfo = (props) => {


    // const [images, setImages] = useState(props.images ?? [])
    // const [measurements, setMeasurements] = useState(props.measurements ?? [])


    const getHWS = () => {
        let cust = props.customer

        let hws = {
            height: cust.height ?? null,
            heightUnit: cust.heightUnit ?? null,
            weight: cust.weight ?? null,
            weightUnit: cust.weightUnit ?? null,
            shoeSize: cust.shoeSize ?? null,
            shoeSizeUnit: cust.shoeSizeUnit ?? null
        }

        return hws
    }

    let [hws, setHws] = useState(getHWS())
    let [note, setNote] = useState(props.customer.measurementNote ?? "")

    let [isSaving, setIsSaving] = useState(false)


    let hwsValUpdated = (type, event) => {
        let newVal = event.target.value 
        let hwsCopy = {...hws}
        if (type === "height"){
            hwsCopy.height = parseFloat(newVal) 
        }
        if (type === "weight"){
            hwsCopy.weight = parseFloat(newVal)
        }
        if (type === "shoeSize"){
            hwsCopy.shoeSize = parseFloat(newVal)
        }
        setHws(hwsCopy)
    }

    let hwsUnitUpdated = (type, event) => {
        let newVal = event.target.value 
        let hwsCopy = {...hws}
        if (type === "heightUnit"){
            hwsCopy.heightUnit = newVal
        }
        if (type === "weightUnit"){
            hwsCopy.weightUnit = newVal
        }
        if (type === "shoeSizeUnit"){
            hwsCopy.shoeSizeUnit = newVal
        }
        setHws(hwsCopy)
    }
    
    let updateHWSPressed = () => {
        console.log("Values sending before Updating are")
        console.log(hws)
        props.hwsUpdated(hws)
    }

    let updateNote = () => {
        props.updateNote(note)
    }


    let notesChanged = (val) => {
        setNote(val ?? "")
    }

    // useEffect(() => {
    const searchForCustomerImages = () => {
        // console.log('IMAGE SEARCH NEEDED = ' + props.imageSearchNeeded)

        if (props.customer && (props.customer.objectId != null)) {
            if (props.imageSearchNeeded === true) {
                CustomerPic.getAll(props.customer, (success, pics, errorMsg) => {
                    if (success) {
                        props.customerImagesFetched(pics)


                    } else {
                        cogoToast.error('Could not fetch Client Images. An error occured.')
                        // console.log(errorMsg)
                    }
                    searchForCustomerMeasurements()

                })
            }
        }
    }

    // searchForCustomerImages()

    // }, [ props ])


    const searchForCustomerMeasurements = () => {
        console.log('Measurements SEARCH NEEDED = ' + props.measurementSearchNeeded)

        if (props.customer && (props.customer.objectId != null)) {
            if (props.measurementSearchNeeded === true) {

                Measurement.getAll(MeasurementType.body, props.customer, null, (succ, mts, msg) => {
                    if (succ) {

                        let mtts = mts

                        if (props.customer.conciergeMeasurements) {
                            console.log("CONCIERGE MEASUREMENTS ARE")
                            console.log(props.customer.conciergeMeasurements)
                            // mtts.concat(props.customer.conciergeMeasurements)

                            let thisCM = props.customer.conciergeMeasurements[0]

                            mtts = mts.concat(props.customer.conciergeMeasurements)

                            console.log("FINAL MTS IS")
                            console.log(mtts)

                        }

                        // console.log('ATTAINED MEAUSUREMENTS')
                        // console.log(mts)

                        props.customerMeasurementsFetched(mtts)

                    } else {
                        cogoToast.error('Could not fetch measurements. An error occured.')
                        // console.log(msg)
                    }
                })

            }
        }
    }



    const imagesSelected = (imgObj) => {
        console.log('IMAGE IS SELECTED')
        props.imageSelected(imgObj)
    }


    const measurementSelected = (mtObj) => {
        console.log('Measurement IS SELECTED')
        props.measurementIsSelected(mtObj)
    }




    const hwsFields = () => {

        return (

            <Box className="is-shadowless">
                <Columns className="is-narrow-tablet">
                    <Columns.Column>
                        <div className="">
                            <label className="label type55 has-text-grey">Height</label>
                            <div className='field  has-addons minWidth140'>
                                <p className="control ">
                                    <input name='height' type="number" step="0.01"  value={hws.height ? hws.height : ""} className='input  has-text-weight-semibold type65 ' placeholder='' onChange={e => { hwsValUpdated("height",e)}}  />
                                </p>
                                <p className="control">
                                    <span className="select  has-text-grey type65" >
                                        <select className='select has-text-grey' value={hws.heightUnit ?? 'Ft'} name='heightUnit' onChange={(e) => {hwsUnitUpdated("heightUnit", e)}}>
                                            <option value='Ft'  >Ft</option>
                                            <option value='Cms'  >Cms</option>
                                            <option value='Inchs'  >Inchs</option>
                                        </select>
                                    </span>
                                </p>
                            </div>

                        </div>
                    </Columns.Column>

                    <Columns.Column>
                        <div className='   '>
                            <div className="label type55 has-text-grey">Weight</div>
                            <div className="field  has-addons minWidth140">
                                <p className="control ">
                                    <input name='weight' type="number" step="0.01" value={hws.weight ? hws.weight : ""} className='input has-text-weight-semibold type65 ' placeholder='' onChange={e => { hwsValUpdated("weight",e)}} />
                                </p>
                                <p className="control">
                                    <span className="select has-text-grey type65" >
                                        <select className='select has-text-grey' value={hws.weightUnit ?? 'Kg'} name='weightUnit' onChange={(e) => {hwsUnitUpdated("weightUnit", e)}}>
                                            <option value='Kg'  >Kg</option>
                                            <option value='Lb'  >Lb</option>
                                        </select>
                                    </span>
                                </p>
                            </div>

                        </div>

                    </Columns.Column>
                    <Columns.Column>
                        {/* marginHorizontal8 */}
                        <div className='   '>
                            <div className="label type55 has-text-grey">ShoeSize</div>
                            <div className="field  has-addons minWidth140">
                                <p className="control ">
                                    <input name='shoeSize' type="number" step="0.01" value={hws.shoeSize ? hws.shoeSize : ""} className='input  has-text-weight-semibold type65 ' placeholder='' onChange={e => { hwsValUpdated("shoeSize",e)}}/>
                                </p>
                                <p className="control">
                                    <span className="select has-text-grey type65" >
                                        <select className='select has-text-grey' value={hws.shoeSizeUnit ?? 'Inchs'} name='shoeSizeUnit' onChange={(e) => {hwsUnitUpdated("shoeSizeUnit", e)}}>
                                            <option value='Inchs'  >Inchs</option>
                                            <option value='Ft'  >Ft</option>
                                            <option value='Cms'  >Cms</option>
                                        </select>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-narrow">
                        {/* marginHorizontal8 */}
                        <div className=' is-pulled-right'>
                            <div className="label type55 has-text-grey-light">·</div>
                            <Button disabled={props.isUpdaingCust} loading={props.isUpdaingCust} className="is-small is-light " onClick={updateHWSPressed}>Update</Button>
                        </div>
                    </Columns.Column>

                </Columns>
                <div className="is-hidden-tablet">
                    <br />
                    <br />
                </div>
            </Box>




        )

    }


    const bodyImagesContainer = () => {
        searchForCustomerImages()

        return (
            <div className='marginTop10'>
                <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={props.addImagePressed}> Add </Button>
                <Heading className="type55 has-text-weight-semibold marginBottom6">Body Images </Heading>
                <div className=" bodyInfoContainer pad10v5w">

                    {
                        props.images.map((im, index) => {
                            return <div key={index} className="bodyInfoImage">
                                {<Image src={im.url} size={128} onClick={() => { imagesSelected(im) }} />}
                            </div>
                        })
                    }

                    {/* <div key={1} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div>
                    <div key={2} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div>
                    <div key={3} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div>
                    <div key={4} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div>
                    <div key={5} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div>
                    <div key={6} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} onClick={imagesSelected} />
                    </div> */}
                </div>
            </div>
        )

    }


    const measurementsContainer = () => {


        return (
            <div className='marginTop10'>
                <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={props.addMeasurementPressed}> Add </Button>
                <Heading className="type55 has-text-weight-semibold marginBottom6">Measurements </Heading>
                <div className=" measurementInfoContainer pad10v5w">

                    {
                        props.measurements.map((mt, index) => {
                            return <div key={index} className="bodyInfoMeasurement">
                                {
                                    <Box className='is-radiusless' onClick={() => { measurementSelected(mt) }} >
                                        <div className='type55 has-text-weight-semibold meginBottom10'> {mt.name} </div>
                                        <Table className='is-hoverable is-size-7'>
                                            <tbody>
                                                {
                                                    mt.measures.map((ms, index) => {
                                                        return <tr key={index}><td className='type50'>{ms.name}</td><td className='has-text-right type50'>{ms.value}&nbsp;{ms.unit}</td></tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Box>
                                }
                            </div>
                        })
                    }

                    {/* <div key={1} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div>
                    <div key={2} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div>
                    <div key={3} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div>
                    <div key={4} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div>
                    <div key={5} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div>
                    <div key={6} className="bodyInfoMeasurement">
                        <Card > Some Content</Card>
                    </div> */}
                </div>
            </div>
        )

    }

    const postureImagesContainer = () => {

        let postures = []
        if (props.customer) {
            if (props.customer.selectedPostures != null) {
                postures = props.customer.selectedPostures
            }
        }

        return (
            <div className='marginTop10'>
                <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={props.editPosturesPressed}> Edit </Button>
                <Heading className="type55 has-text-weight-semibold marginBottom6">Postures </Heading>
                <div className=" bodyInfoContainer pad10v5w">

                    {
                        postures.map((ps, index) => {
                            return <div key={index} className="bodyInfoImage">
                                {
                                    <div className="has-background-white">
                                        <Image src={imageUrlForPostures(ps)} size={128} />
                                        <p className="has-text-centered type55 has-text-grey"> {ps} </p>
                                    </div>
                                }
                            </div>
                        })
                    }

                    {/* <div key={1} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={2} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={3} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={4} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={5} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={6} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div> */}
                </div>
            </div>
        )

    }


    const patternsContainer = (thisCust) => {

        let patterns = []
        if (thisCust) {
            if (thisCust.customMadePatterns != null) {
                patterns = thisCust.customMadePatterns
            }
        }

        return (
            <div className='marginTop10'>
                <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={props.addPatternPressed}> Add </Button>
                <Heading className="type55 has-text-weight-semibold marginBottom6"> Custom Patterns </Heading>
                <div className=" bodyInfoContainer pad10v5w">

                    {
                        patterns.map((pr, index) => {
                            return <div key={index} className="bodyInfoPattern has-background-white" onClick={() => { props.editPatternPressed({ pattern: pr, index: index }) }}>
                                {/* return <div key={index} className="bodyInfoPattern has-background-white" onClick={ () => {props.editPatternPressed({pattern:pr, index: index})} }> */}

                                <div className="has-background-white  pad10">
                                    <br />
                                    <br />
                                    <p className="has-text-centered type55 has-text-grey is-vcentered"> {pr} </p>
                                </div>
                            </div>
                        })
                    }

                    {/* <div key={1} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={2} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={3} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={4} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={5} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div>
                    <div key={6} className="bodyInfoImage">
                        <Image src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original" size={128} />
                    </div> */}
                </div>
            </div>
        )

    }


    const notesContainer = (thisCust) => {

        return (
            <div className='marginTop10'>
                <Heading  className="type55 has-text-weight-semibold marginBottom6"> Note </Heading>
                <div className=" marginTopMinus10   p-4">

                    <Form.Textarea size="small" className="has-background-white-bis " rows={8} fixedSize={true} placeholder="Write note here.."  value={note} onChange={(e) => { notesChanged(e.target.value) } } />
                    <Button disabled={props.isUpdaingCust} loading={props.isUpdaingCust} size="small" className=" is-light is-pulled-right" onClick={updateNote}> Update </Button>
                </div>
            </div>
        )

    }


    const view = () => {
        return (
            <div>
                {hwsFields()}
                <br />
                {bodyImagesContainer()}
                <br />
                {measurementsContainer()}
                <br />
                {postureImagesContainer()}
                <br />
                {patternsContainer(props.customer)}
                <br />
                {notesContainer(props.customer)}
            </div>


        )
    }

    return (
        view()
    )

}


export default ClientMeasurementsInfo