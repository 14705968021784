import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns, Heading } from 'react-bulma-components/dist'
import { Formik, Form, Field } from 'formik'
import { Profession_options, SpendRange_options, ClientCategory_options } from '../utilities/enums'
import { Customer } from '../utilities/Classes'
import { countryList } from '../utilities/countryOpts'

import FormikDatePicker from '../components/FormikDatePicker';
import cogoToast from 'cogo-toast'
import { client_defaultCountry, client_favCountryList, config_cust_captureAddress, config_cust_captureProfession } from '../ClientInfo/clientInfo'
import { createCustomId, randomAlphabets, randomIntegerString } from '../utilities/utils'


const ClientInfoForm = (props) => {


    let custInfo = {
        name: '',
        country: client_defaultCountry,
        phone1: props.phoneFromSearch ?? '',

        email1: '',
        birthday: null,

        address: '',
        state: '',
        postal: '',

        profession: 'None',
        company: '',

        specialOccName: '',
        specialOccDate: null,

        category: 'None',
        spendRange: 'None',
        objectId: null
    }


    let isNewCustomer = props.customer ? false : true


    const saveCustomer = (values, callback) => {


        if (values.name === null || values.name === "" || values.name === " ") {
            cogoToast.warn("Name is essential.")
            callback({ success: false, customer: null, msg: "Missing: Name is essential." })
            return
        }

        if (values.phone1 === null || values.phone1 === "" || values.phone1 === " ") {
            cogoToast.warn("Phone is essential.")
            callback({ success: false, customer: null, msg: "Missing: Phone is essential." })
            return
        }


        // if ((values.phone1 === null || values.phone1 === "" || values.phone1 === " ") && (values.email1 === null || values.email1 === "" || values.email1 === " ")){
        //     cogoToast.warn("Phone or Email. Atleast one of these is essential.")
        //     callback({ success: false, customer: null, msg: "Missing: Phone or Email. Atleast one of these is essential." })
        //     return
        // }


        console.log('SAVING CUSTOMER')

        // setTimeout(() => {
        //     callback({ success: true, message: 'User signed In succesfully. ' })
        // }, 800);

        if (isNewCustomer) {

            let cust = new Customer(
                values.name,
                null,
                values.state,
                values.postal,
                values.address,
                values.country,
                values.phone1, null,
                values.email1, null,
                values.company,
                null,
                null,
                values.birthday,
                null,
                null,
                null,
                null,
                values.specialOccName,
                values.specialOccDate,
                null,
                0,
                null,
                null,
                null,
                values.category,
                values.profession,
                values.spendRange,
                null,
                null,
                null,
                0,
                null,
                null)


                // cust.customId = "CL-" + randomAlphabets(3) + randomIntegerString(3) + randomAlphabets(1) + randomIntegerString(2)


            cust.add((succ, errMsg) => {

                if (succ === true) {
                    // console.log('Customer Saved')
                    cogoToast.success('Client Saved')
                    callback({ success: true, customer: cust, msg: '' })
                } else {
                    cogoToast.error('Could not save Client. Check internet and try again.')
                    // console.log('Customer Save Failed')
                    callback({ success: false, customer: null, msg: errMsg })
                }

            })

        } else {

            props.customer.name = values.name
            props.customer.country = values.country
            props.customer.phone1 = values.phone1
            props.customer.email1 = values.email1
            props.customer.birthday = values.birthday
            props.customer.address = values.address
            props.customer.state = values.state
            props.customer.postal = values.postal
            props.customer.profession = values.profession
            props.customer.company = values.company
            props.customer.specialOccName = values.specialOccName
            props.customer.specialOccDate = values.specialOccDate
            props.customer.category = values.category
            props.customer.spendRange = values.spendRange

            props.customer.update((succ, errMsg) => {

                if (succ === true) {
                    // console.log('Customer Updated')
                    cogoToast.success('Client Updated')
                    callback({ success: true, customer: props.customer, msg: '' })
                } else {
                    // console.log('Customer Updated Failed')
                    cogoToast.error('Could not update Client. Check internet and try again.')
                    callback({ success: false, customer: null, msg: errMsg })
                }

            })


        }



    }


    const initialValues = () => {
        if (isNewCustomer) {
            return custInfo
        } else {
            let existingCustomer = props.customer
            custInfo.name = existingCustomer.name
            custInfo.country = existingCustomer.country
            custInfo.phone1 = existingCustomer.phone1
            custInfo.email1 = existingCustomer.email1
            custInfo.birthday = existingCustomer.birthday
            custInfo.address = existingCustomer.address
            custInfo.state = existingCustomer.state
            custInfo.postal = existingCustomer.postal
            custInfo.profession = existingCustomer.profession
            custInfo.company = existingCustomer.company
            custInfo.specialOccName = existingCustomer.specialOccName
            custInfo.specialOccDate = existingCustomer.specialOccDate
            custInfo.category = existingCustomer.category
            custInfo.spendRange = existingCustomer.spendRange
            custInfo.objectId = existingCustomer.objectId

            return custInfo
        }
    }


    return (

        <div className='is-two-fifths  paddingHor20'>
            {/* <div className='is-two-fifths paymentBoxBGColor paddingHor20'> */}

            <br />
            {/* <Heading size={7}> SHIPPING ADDRESS </Heading> */}

            <Formik

                enableReinitialize={true}
                initialValues={initialValues()}

                validate={(values) => {
                    const errors = {};
                    return errors;
                }}

                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true)

                    console.log('Customer Save Submitted : ', data)


                    saveCustomer(data, (response) => {
                        setSubmitting(false)
                        // resetForm(true)

                        if (response.success === true) {
                            props.customerSaved(response.customer)
                        } else {
                            props.errorOccured(response.msg)
                        }
                    })


                    // setSubmitting(true)
                    // console.log('Form Submitted : ', data) 


                    // setSubmitting(false)
                    // resetForm(true)


                }}

                validator={() => ({})}


            >
                {({ setFieldValue, setFieldTouched, values, isSubmitting, errors, touched, isValidating }) => (

                    <Form >
                        {/* <div className='field is-horizontal  '> */}

                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Name</label> */}
                            <Field name='name' type='input' className='input has-text-weight-semibold type65 is-capitalized' placeholder='Name' />
                        </div>

                        <div className='field marginHorizontal8 has-addons  '>
                            {/* <label className="label type60 ">Phone</label> */}
                            <p className="control">
                                <span className="select has-text-weight-semibold type65">
                                    <Field as='select' className='select has-background-white-bis ' name='country'>
                                        <optgroup label="Fav">
                                            {client_favCountryList()}
                                        </optgroup>

                                        <optgroup label="All">
                                            {
                                                countryList.map((thisCountry) => {
                                                    let val = `${thisCountry.ccode} (${thisCountry.mcode})`
                                                    return <option value={val} >{val}</option>
                                                })
                                            }
                                            {/* <option value='NA (+264)' >NA (+264)</option>
                                            <option value='BW (+267)'  >BW (+267)</option>
                                            <option value='ZW (+263)'  >ZW (+263)</option>
                                            <option value='ZA (+27)' >ZA (+27)</option>
                                            <option value='DE (+49)'  >DE (+49)</option> */}
                                        </optgroup>

                                    </Field>
                                </span>
                            </p>
                            <p className="control is-fullwidth is-expanded">
                                <Field name='phone1' type='input' className='input has-text-weight-semibold type65 is-fullwidth is-expanded' placeholder='Phone' />
                            </p>

                        </div>

                        {/* </div> */}

                        <div className='field is-horizontal   '>

                            <div className='field marginHorizontal8'>
                                {/* <label className="label type60 ">Email</label> */}
                                <Field name='email1' type='input' className='input has-text-weight-semibold type65 minWidth205' placeholder='Email' />
                            </div>

                            <div className='field marginHorizontal8'>
                                {/* <label className="label type60 ">birthday</label> */}
                                {/* <Field name='birthday' type='date' className='input has-text-weight-semibold type65 minWidth192' placeholder='dd-mm-yyyy (Date Of Birth)' /> */}

                                <FormikDatePicker placeHolderText={'Birth Date mm/dd/yy'} value={values.birthday} onChange={setFieldValue} onBlur={setFieldTouched} error={errors.birthday} label={'BDAY'} name={'birthday'} className=' minWidth186 ' />


                            </div>

                        </div>




                        {
                            config_cust_captureAddress ?
                                <div className=''>
                                    <label className="label type55 marginHorizontal8 marginTop20 ">Address</label>

                                    <div className='field marginHorizontal8 is-fullwidth'>
                                        <Field name='address' type='input' className='input has-text-weight-semibold type65 is-fullwidth' placeholder='Address Lines' />
                                    </div>

                                    <div className='field is-horizontal  '>
                                        <div className='field marginHorizontal8'>
                                            <Field name='state' type='input' className='input has-text-weight-semibold type65 minWidth205' placeholder='State' />
                                        </div>

                                        <div className='field marginHorizontal8'>
                                            <Field name='postal' type='input' className='input has-text-weight-semibold type65 minWidth205' placeholder='Postal' />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }


                        {
                            config_cust_captureProfession ?
                                <div className=''>
                                    <label className="label type55  marginHorizontal8 marginTop20">Profession</label>

                                    <div className='field is-horizontal  '>
                                        <div className='field marginHorizontal8'>

                                            <p className="control">
                                                <span className="select is-fullwidth has-text-weight-semibold  type65">
                                                    {Profession_options('profession', 'type60 has-background-white-bis  minWidth205')}
                                                </span>
                                            </p>

                                            {/* <Field name='profession' type='input' className='input has-text-weight-semibold type65' placeholder='Select' /> */}
                                        </div>

                                        <div className='field marginHorizontal8'>
                                            <Field name='company' type='input' className='input has-text-weight-semibold type65 minWidth205' placeholder='Company' />
                                        </div>
                                    </div>
                                </div>

                                :
                                null
                        }



                        <div className=''>
                            <label className="label type55 marginHorizontal8 marginTop20">Special Occasion</label>

                            <div className='field is-horizontal  '>
                                <div className='field marginHorizontal8'>
                                    <Field name='specialOccName' type='input' className='input has-text-weight-semibold type65 minWidth205' placeholder='Name' />
                                </div>

                                <div className='field marginHorizontal8'>
                                    <FormikDatePicker placeHolderText={'Occasion Date mm/dd/yy'} value={values.specialOccDate} onChange={setFieldValue} onBlur={setFieldTouched} error={errors.birthday} label={'Date'} name={'specialOccDate'} className=' minWidth186 ' />

                                    {/* <Field name='specialOccDate' type='date' className='input has-text-weight-semibold type65 minWidth192' placeholder='dd-mm-yyyy (Spcl Date)' /> */}
                                </div>
                            </div>
                        </div>


                        <div className=''>
                            <label className="label type55 marginHorizontal8 marginTop20">Internal (for Staff)</label>

                            <div className='field is-horizontal  '>
                                <div className='field marginHorizontal8'>

                                    <p className="control">
                                        <span className="select is-fullwidth has-text-weight-semibold type65">
                                            {ClientCategory_options('category', 'type60  minWidth205 has-background-white-bis ')}
                                        </span>
                                    </p>
                                    {/* <Field name='category' type='input' className='input has-text-weight-semibold type65' placeholder='Ca..' /> */}
                                </div>

                                <div className='field marginHorizontal8'>


                                    <p className="control">
                                        <span className="select is-fullwidth has-text-weight-semibold type65">
                                            {SpendRange_options('spendRange', 'type60  minWidth205 has-background-white-bis ')}
                                        </span>
                                    </p>

                                    {/* <Field name='spendRange' type='input' className='input has-text-weight-semibold type65' placeholder='Rn..' /> */}
                                </div>
                            </div>
                        </div>


                        <br />


                        <div className='field is-grouped signUpButton marginHorizontal8'>
                            {/* <div className='control is-expanded'>
                                <Button disabled={false} className='button is-outlined is-fullwidth' type='reset'>Reset</Button>
                            </div> */}
                            <div className='control is-expanded'>
                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className='button is-black is-fullwidth marginTop10' type='submit'> Save </Button>
                            </div>
                        </div>

                    </Form>

                )}

            </Formik>
        </div>


    )
}

export default ClientInfoForm