
import Parse from 'parse'
import { getMiddleBoolVal } from './helper'
import { getCurrentLocation, getCurrentUser, getLocations } from './Store';
import { provideCompressedFile } from './helper'
import cogoToast from 'cogo-toast';
import { faLowVision } from '@fortawesome/free-solid-svg-icons';
import { showOnlyClientFeature } from '../ClientInfo/clientInfo';


const UsersClass = Parse.Object.extend("Users");
const AgentClass = Parse.Object.extend("Agent");
const PositionClass = Parse.Object.extend("Position");
const LocationClass = Parse.Object.extend("Location");
const ConfigClass = Parse.Object.extend("Config");

export const UserType = {
    user: 'user',
    agent: 'agent',
}

export const LocationType = {
    store: 'Store',
    franchise: 'Franchise',
    factory: "Factory",
    warehouse: "Warehouse"

}

export class TWConfig {

    constructor(userCanChangeOwnPass, logoutAfterHours, objectId, emailTo_NewOrder, emailTo_InfoComplete) {
        this.userCanChangeOwnPass = userCanChangeOwnPass
        this.logoutAfterHours = logoutAfterHours
        this.objectId = objectId
        this.emailTo_NewOrder = emailTo_NewOrder
        this.emailTo_InfoComplete = emailTo_InfoComplete
    }

    update(callback) {
        // Object Id will always be there as we are not creating location on front end. Only updating them

        var query = new Parse.Query(ConfigClass);
        query.get(this.objectId)
            .then((cn) => {
                // The object was retrieved successfully.
                cn.set("userCanChangeOwnPass", this.userCanChangeOwnPass)
                cn.set("logoutAfterHours", this.logoutAfterHours)
                cn.set("emailTo_NewOrder", this.emailTo_NewOrder)
                cn.set("emailTo_InfoComplete", this.emailTo_InfoComplete)

                cn.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }

    static initFromPFObject(obj) {
        let userCanChangeOwnPass = obj.get('userCanChangeOwnPass')
        let logoutAfterHours = obj.get('logoutAfterHours')

        let emailTo_NewOrder = obj.get('emailTo_NewOrder')
        let emailTo_InfoComplete = obj.get('emailTo_InfoComplete')

        let objId = obj.id
        let cn = new this(userCanChangeOwnPass, logoutAfterHours, objId, emailTo_NewOrder, emailTo_InfoComplete)
        return cn
    }



    static getConfig(callback) {
        console.log('FETCH CONFIG NOW')

        var query = new Parse.Query(ConfigClass);
        query.first()
            .then((cn) => {
                if (cn !== null) {
                    console.log('GOT CONFIG')
                    console.log(cn)


                    let thisCn = this.initFromPFObject(cn)

                    console.log('CONVERTED CONFIG')
                    console.log(thisCn)
                    callback(true, thisCn, '')
                }

            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, [], 'Error =' + error.message)
            });

    }


}


export class Location {


    constructor(name, address, contact, code, objectId, geoLocation, type, primaryLink, primaryLinkId, activeStocks, activeSelling, activeMaking, activeEditing, linkedIds) {
        this.name = name
        this.address = address
        this.contact = contact
        this.code = code
        this.objectId = objectId
        this.geoLocation = geoLocation
        this.type = type
        this.primaryLink = primaryLink
        this.primaryLinkId = primaryLinkId
        this.activeStocks = activeStocks
        this.activeSelling = activeSelling
        this.activeMaking = activeMaking
        this.activeEditing = activeEditing
        this.linkedIds = linkedIds
        // this.linkedLocations = linkedLocations

        if (type === LocationType.store && activeSelling == null) {
            activeSelling = true
        }

        if (type === LocationType.franchise && activeSelling == null) {
            activeSelling = true
        }

        if (type === LocationType.factory && activeMaking == null) {
            activeMaking = true
        }

        if (type === LocationType.warehouse && activeStocks == null) {
            activeStocks = true
        }

        this.isMain = false
        this.isFranchise = false
        this.isHidden = false

    }


    // update(completion: @escaping(_ success: Bool, _ errorMsg: String) -> ()) {
    update(callback) {

        // Object Id will always be there as we are not creating location on front end. Only updating them

        var query = new Parse.Query(LocationClass);
        query.get(this.objectId)
            .then((loc) => {
                // The object was retrieved successfully.



                loc.set("name", this.name)
                loc.set("address", this.address)
                loc.set("contact", this.contact)
                loc.set("code", this.code)
                // loc.set("objectId", objectId)
                loc.set("type", this.type)
                loc.set("activeStocks", this.activeStocks)
                loc.set("activeSelling", this.activeSelling)
                loc.set("activeMaking", this.activeMaking)
                loc.set("activeEditing", this.activeEditing)
                loc.set("linkedIds", this.linkedIds)
                
                loc.set("isFranchise", this.isFranchise)
                loc.set("isHidden", this.isHidden)

                loc.save()

                // optionals
                // if (primaryLink !== null)  {
                //     ob["primaryLink"] = primaryLink.toPFObject()
                //     ob["primaryLinkId"] = primaryLink.objectId
                // }

                // if (this.primaryLinkId !== null)  {
                //     loc.set("primaryLinkId", this.primaryLinkId)
                //     if (this.primaryLink == null) {
                //         let locs = getLocations()
                //         let thisLoc = locs.filter( (itm) => { return itm.objectId === this.primaryLinkId })
                //         if (thisLoc != null) {
                //             loc.set("primaryLink", thisLoc[0].toPFObject())
                //         }
                //     }
                // }

                // if let loctn = geoLocation {
                //     // save Location
                //     let point = PFGeoPoint.init(latitude: loctn.latitude, longitude: loctn.longitude)
                //     ob["geoLocation"] = point
                // }

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }


    static areEqualInValue(loc1, loc2) {
        var isEqual = true
        if (loc1.code !== loc2.code) {
            isEqual = false
        }
        if (loc1.objectId !== loc2.objectId) {
            isEqual = false
        }
        return isEqual
    }


    // Get all
    // static  getAll(completion: @escaping(_ success: Bool, _ result: [Location], _ errorMsg: String) -> ()) {

    static getAll(callback) {

        // let query = PFQuery(className: "Location")
        // query.order(byAscending: "createdAt")
        // query.whereKey("show", notEqualTo: false)
        // //        query.includeKey("associatedFactories")
        // query.cachePolicy = .networkOnly


        var query = new Parse.Query(LocationClass);
        query.ascending('createdAt')
        query.notEqualTo('show', false)
        // query.include('associatedFactories')
        query.find()
            .then((locs) => {

                // console.log('RAW LOCATIONS ARE')
                // console.log(locs)
                if (locs !== null) {
                    let convertedLocs = []
                    for (let i = 0; i < locs.length; i++) {
                        const thisLocObj = locs[i];
                        // CONVERT LOCS TO LOCAION OBJECTS
                        let thisLoc = this.initFromPFObject(thisLocObj)
                        if (thisLoc !== null) {
                            convertedLocs.push(thisLoc)
                        } else {
                            console.log('Could not convert Object to Location while getting all Locations')
                        }

                    }
                    callback(true, convertedLocs, '')

                }

            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, [], 'Error =' + error.message)
            });


    }


    static initFromObject(obj) {
        let name = obj.name
        let address = obj.address
        let contact = obj.contact
        let code = obj.code
        let objectId = obj.id
        let type = obj.type
        var linkedIds = []
        if (obj.linkedIds !== null) {
            linkedIds = obj.linkedIds
        }
        let primaryLinkId = null
        let primaryLink = null
        let primaryLinkPFObj = obj.primaryLink
        let geoLocation = null

        // making Link from Id & Vice-Versa
        // print(obj)


        if (primaryLinkPFObj !== null) {
            // console.log('PRIMARU LINK = ')
            // console.log(primaryLinkPFObj)
            // primaryLink = obj.get('primaryLink')
            // console.log('PRIMARU LINK ID= ')
            // console.log(primaryLinkPFObj.id)
            //            let locations = getLocationsFromDefaults()
            //            let thisLoc = locations.filter({ $0.objectId == primeLoc.objectId! })
            //            if !thisLoc.isEmpty {
            // if (obj.get('primaryLink') !== null) {
            // primaryLink = Location.initFromPFObject(obj.get('primaryLink'))
            // }
            primaryLinkId = primaryLinkPFObj.objectId
            //            }
        }

        // if (obj.get('primaryLinkId') !== null) {
        //     primaryLinkId = obj.get('primaryLinkId')
        //     if (primaryLink == null) {
        //         // let locations = getLocationsFromDefaults()
        //         // let thisLoc = locations.filter({ $0.objectId == pId })
        //         // if !thisLoc.isEmpty {
        //         //     primaryLink = thisLoc.first!
        //         // }
        //     }
        // }

        // if (obj.get('geoLocation') !== null) {
        //     // save Location
        //     // geoLocation = GeoLocation.init(latitude: loctn.latitude, longitude: loctn.longitude)
        // }


        let activeStocks = obj.activeStocks
        let activeEditing = obj.activeEditing
        let activeSelling = obj.activeSelling
        let activeMaking = obj.activeMaking


        let location = new Location(name, address, contact, code, objectId, geoLocation, type, primaryLink, primaryLinkId, activeStocks, activeSelling, activeMaking, activeEditing, linkedIds)

        return location
    }


    // initFromPFObjet
    static initFromPFObject(obj) {
        let name = obj.get('name')
        let address = obj.get('address')
        let contact = obj.get('contact')
        let code = obj.get('code')
        let objectId = obj.id
        let type = obj.get('type')
        var linkedIds = []
        if (obj.get('linkedIds') !== null) {
            linkedIds = obj.get('linkedIds')
        }
        let primaryLinkId = null
        let primaryLink = null
        let primaryLinkPFObj = obj.get('primaryLink')
        let geoLocation = null

        // making Link from Id & Vice-Versa
        // print(obj)


        if (primaryLinkPFObj != null) {
            // console.log('PRIMARU LINK = ')
            // console.log(primaryLinkPFObj)
            // primaryLink = obj.get('primaryLink')
            // console.log('PRIMARU LINK ID= ')
            // console.log(primaryLinkPFObj.id)
            //            let locations = getLocationsFromDefaults()
            //            let thisLoc = locations.filter({ $0.objectId == primeLoc.objectId! })
            //            if !thisLoc.isEmpty {
            // if (obj.get('primaryLink') !== null) {
            // primaryLink = Location.initFromPFObject(obj.get('primaryLink'))
            // }
            // primaryLinkId = primaryLinkPFObj.id
            //            }
        }

        // if (obj.get('primaryLinkId') !== null) {
        //     primaryLinkId = obj.get('primaryLinkId')
        //     if (primaryLink == null) {
        //         // let locations = getLocationsFromDefaults()
        //         // let thisLoc = locations.filter({ $0.objectId == pId })
        //         // if !thisLoc.isEmpty {
        //         //     primaryLink = thisLoc.first!
        //         // }
        //     }
        // }

        // if (obj.get('geoLocation') !== null) {
        //     // save Location
        //     // geoLocation = GeoLocation.init(latitude: loctn.latitude, longitude: loctn.longitude)
        // }


        let activeStocks = obj.get('activeStocks') ?? null
        let activeEditing = obj.get('activeEditing') ?? null
        let activeSelling = obj.get('activeSelling') ?? null
        let activeMaking = obj.get('activeMaking') ?? null


        let location = new Location(name, address, contact, code, objectId, geoLocation, type, primaryLink, primaryLinkId, activeStocks, activeSelling, activeMaking, activeEditing, linkedIds)

        let isMain = obj.get('isMain') 
        if (isMain) {
            location.isMain = true
        }
        
        let isFranchise = obj.get('isFranchise')
        if (isFranchise) {
            location.isFranchise = true
        }
        
        let isHidden = obj.get('isHidden')
        if (isHidden) {
            location.isHidden = isHidden
        }


        return location
    }

    toPFObject() {

        let thisObj = new LocationClass()
        thisObj.id = this.objectId
        return thisObj

        // var query = new Parse.Query(LocationClass);
        // query.get(this.objectId)
        //     .then((loc) => {
        //         return loc
        //     }, (error) => {
        //         console.log('Error =' + error.message)
        //         return null
        //     });
    }


    // Get Current OrderNo
    //  getCurrentOrderNo(completion: @escaping(_ success: Bool, _ orderNo: Int ? , _ errorMsg: String) -> ()) {
    getCurrentOrderNo(callback) {

        var query = new Parse.Query(LocationClass);
        query.includeKey('currentOrderNo')

        query.get(this.objectId)
            .then((thisLoc) => {
                if (thisLoc !== null) {

                    if (thisLoc.currentOrderNo !== null) {
                        callback(true, thisLoc.currentOrderNo, "")
                    }
                    // if let currentOrderNo = obj.value(forKey: "currentOrderNo") as ?Double {
                    //     print("RESULT WAS DOUBLE")
                    //     completion(true, Int(currentOrderNo), "")
                    // }

                    // callback(true, convertedLocs, '')

                }

            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, null, 'Error =' + error.message)
            });


    }


}




export class Agent {

    //TODO 
    // AGENT LOGIN FLOW 
    // AGENT HAS NO POSITION... SO ON POSITION CHECK >> WE SET IT MANUALLY, AS THERE ONLY SELECT PAGES TO SHOW TO AGENT
    // AGENT SEGMENT 
    // AGENT RESERVE ORDER Screen
    // AGENT STAGES___ AGENT ADDED, AGENT SENT, AGENT RECIEVED

    constructor(name, agentId, password, phone, email, description, objectId, image, imageUrl, isDeleted) {
        this.name = name
        this.agentId = agentId
        this.phone = phone
        this.email = email
        this.description = description
        this.password = password
        this.objectId = objectId
        this.image = image
        this.imageUrl = imageUrl
        this.isDeleted = isDeleted
        this.file = null
        this.userType = UserType.Agent
    }


    static init() {
        let nA = new this(null, null, null, null, null, null, null, null, null, null)
        return nA
    }

    static copyFrom(obj) {

        let nu = this.init()

        nu.name = obj.name
        nu.agentId = obj.agentId
        nu.phone = obj.phone
        nu.email = obj.email
        nu.description = obj.description
        nu.password = obj.password
        nu.objectId = obj.objectId
        nu.image = obj.image
        nu.imageUrl = obj.imageUrl
        nu.isDeleted = obj.isDeleted
        nu.file = obj.file
        nu.userType = obj.userType

        return nu
    }


    static initFromPFObject(obj,) {
        let name = obj.get('name')
        let agentId = obj.get('agentId')
        let password = obj.get('password') ?? '••••••••'
        let phone = obj.get('phone')
        let email = obj.get('email')
        let description = obj.get('description')
        let objectId = obj.id

        let isDeleted = obj.get('isDeleted')
        let imageUrl = obj.get('imageUrl')
        let userType = obj.get('userType')

        let thisAgent = new this(name, agentId, password, phone, email, description, objectId, null, imageUrl, isDeleted)
        thisAgent.userType = userType
        return thisAgent
    }

    readerValue() {
        return this.name + ` (${this.agentId})`
    }

    // add(completion: @escaping(_ success: Bool, _ errorMsg: String) -> ()) {
    add(callback) {
        let newUser = new Agent()

        newUser.set('name', this.name)
        newUser.set('agentId', this.agentId)
        newUser.set('password', this.password)
        newUser.set('phone', this.phone)
        newUser.set('email', this.email)
        newUser.set('description', this.description)
        newUser.set('isDeleted', this.isDeleted)
        newUser.set('userType', this.userType)

        if (this.imageUrl !== null) {
            newUser.set('imageUrl', this.imageUrl)
        }

        newUser.save()
            .then((newUser) => {
                this.objectId = newUser.id
                console.log('New Agent Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new agent, with error code: ' + error.message);
                callback(false, error.message)
            });

    }



    uploadImage(file, callback) {

        // let image = this.image 
        if (file == null) {
            callback(false, 'No Agent image to add.')
        }

        provideCompressedFile(file, 200, 0.3 , (succ, file) => {

            if (succ) {
                console.log('UPLOADING COMPRESSED FILE')
            } else {
                cogoToast.warn('This image could not be compressed. Upload will take time.')
                console.log('UPLOADING ORGINAL FILE')
            }


            var name = `agentPhoto${this.username ?? 'NoUserName'}.png`
            var parseFile = new Parse.File(name, file, "image/png");


            parseFile.save()
                .then((fileObj) => {

                    // The object was retrieved successfully.

                    let fileUrl = fileObj.url()
                    if (fileUrl != null) {

                        this.imageUrl = fileUrl

                        this.update((succ, errMsg) => {
                            callback(succ, fileUrl, 'Error while update ImageUrl to Agent after image Uploaded =' + errMsg)
                        })

                    } else {
                        console.log("Could not get Agent file url.")
                        callback(false, null, 'Could not get Agent pic file url.')
                        return
                    }


                }).catch((error) => {
                    // The file either could not be read, or could not be saved to Parse.
                    callback(false, null, "Error while uploading Agent Profile file or finding user while doing it." + error.message)

                })
        })


    }


    update(callback) {
        if (this.objectId == null) {
            console.log("Error : No object id to update Agent")
            callback(false, 'Error =' + 'Error : No object id to update Agent')
            return
        }

        var query = new Parse.Query(Agent);
        query.get(this.objectId)
            .then((agent) => {
                // The object was retrieved successfully.

                agent.set('name', this.name)
                agent.set('agentId', this.agentId)
                agent.set('password', this.password)
                agent.set('phone', this.phone)
                agent.set('email', this.email)
                agent.set('description', this.description)
                agent.set('isDeleted', this.isDeleted)
                agent.set('userType', this.userType)

                if (this.imageUrl !== null) {
                    agent.set('imageUrl', this.imageUrl)
                }

                agent.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }

    static getAgent(agentId, password, callback) {

        const userQuery = new Parse.Query(AgentClass);
        userQuery.equalTo("agentId", agentId);
        userQuery.equalTo("password", password);

        userQuery.find().then((objects) => {
            console.log('All Agents =')
            console.log(objects)
            if (objects.length > 0) {
                let thisAgent = Agent.initFromPFObject(objects[0], false)
                if (thisAgent !== null) {
                    callback(true, thisAgent, '')
                }
            } else {
                callback(false, null, "Invalid")
            }

        }, (error) => {
            console.log('Error while getting Agents ', error.message)
            callback(false, null, error.message)
        })
    }


    static getAll = (callback) => {

        const userQuery = new Parse.Query(AgentClass);
        userQuery.ascending('createdAt')
        // userQuery.exclude('password')

        userQuery.find().then((objects) => {
            console.log('All Agents =')
            console.log(objects)
            if (objects.length > 0) {

                let allAgents = []
                for (let i = 0; i < objects.length; i++) {
                    const thisAgentObj = objects[i];
                    let thisAgent = User.initFromPFObject(thisAgentObj, false)
                    if (thisAgent !== null) {
                        allAgents.push(thisAgent)
                    }
                }
                callback(true, allAgents, '')
            } else {
                callback(false, null, "No Agents")
            }

        }, (error) => {
            console.log('Error while getting all Agents ', error.message)
            callback(false, null, error.message)
        })
    }


    delete(callback) {
        if (this.objectId == null) {
            callback(false, 'No Id of the user to delete')
        }

        let thisObj = new AgentClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting user ', error.message)
            callback(false, error.message)
        });

    }


    toPFObject() {
        let thisObj = new AgentClass()
        thisObj.id = this.objectId
        return thisObj
    }


}


export class User {


    constructor(name, username, password, phone, email, position, objectId, location, locationId, image, imageUrl, isAdmin, isDeleted) {
        this.name = name
        this.username = username
        this.password = password
        this.phone = phone
        this.email = email
        this.objectId = objectId
        this.image = image
        this.imageUrl = imageUrl
        this.isAdmin = isAdmin
        this.isFranchiseAdmin = false
        this.isAgent = false

        this.isDeleted = isDeleted
        this.position = position
        this.location = location

        this.locationId = locationId
        this.file = null
        this.userType = UserType.User


    

    }


    static init() {
        let nu = new this(null, null, null, null, null, null, null, null, null, null, null, false, false)
        return nu
    }


    static copyFrom(obj) {

        let nu = this.init()

        nu.name = obj.name
        nu.username = obj.username
        nu.password = obj.password
        nu.phone = obj.phone
        nu.email = obj.email
        nu.objectId = obj.objectId
        nu.image = obj.image
        nu.imageUrl = obj.imageUrl
        nu.isAdmin = obj.isAdmin
        nu.isFranchiseAdmin = obj.isFranchiseAdmin
        nu.isAgent = obj.isAgent
        nu.isDeleted = obj.isDeleted
        nu.position = obj.position
        nu.location = obj.location
        nu.locationId = obj.locationId
        nu.file = obj.file
        nu.userType = obj.userType

        return nu
    }


    static initFromPFObject(obj, withoutPosition) {
        let name = obj.get('name')
        let username = obj.get('username')
        let password = obj.get('password') ?? '••••••••'
        let phone = obj.get('phone')
        let email = obj.get('email') ?? ""
        let isAdmin = obj.get('isAdmin')
        let objectId = obj.id


        let isFranchiseAdmin = false
        let isAgent = false

        let isFA = obj.get("isFranchiseAdmin") 
        if (isFA) {
            isFranchiseAdmin = isFA
        }

        let isAg = obj.get("isAgent")
        if (isAg) {
            isAgent = isAg
        }


        let isDeleted = obj.get('isDeleted')
        let imageUrl = obj.get('imageUrl')
        let userType = obj.get('userType') ?? UserType.user

        let location = obj.get('location')
        let locationId = null

        if (location != null) {
            locationId = location.id
        }

        let thisUser = null

        if (withoutPosition === false) {
            let positionObj = obj.get('position')
            let position = Position.initFromPFObject(positionObj)
            if (position !== null) {
                thisUser = new User(name, username, password, phone, email, position, objectId, null, locationId, null, imageUrl, isAdmin, isDeleted)
            }
        } else {
            // use sample position.. these users are just created for remarks
            let blankPosition = Position.initBasicPositionWithNoAccess("Sample")
            thisUser = new User(name, username, password, phone, email, blankPosition, objectId, null, locationId, null, imageUrl, isAdmin, isDeleted)
        }
        thisUser.userType = userType
        thisUser.isFranchiseAdmin = isFranchiseAdmin
        thisUser.isAgent = isAgent
        return thisUser
    }

    readerValue() {
        return this.name + `${this.username}`
    }


    // add(completion: @escaping(_ success: Bool, _ errorMsg: String) -> ()) {
    add(callback) {
        let newUser = new UsersClass()

        newUser.set('name', this.name)
        newUser.set('username', this.username)
        newUser.set('password', this.password)
        newUser.set('phone', this.phone)
        newUser.set('email', this.email)
        newUser.set('userType', this.userType)

        if (this.position.objectId || this.position.id) {
            let positionPointer = {
                __type: 'Pointer',
                className: 'Position',
                objectId: this.position.objectId ?? this.position.id ?? ""
            }
            newUser.set('position', positionPointer)
        }else{
            console.log('Failed to create new user, as position id was not found ');
            callback(false, 'Failed to create new user, as position id was not found ')
            return
        }



        newUser.set('isFranchiseAdmin', this.isFranchiseAdmin)
        newUser.set('isAgent', this.isAgent)



        if (this.location){
            let locationPointer = {
                __type: 'Pointer',
                className: 'Location',
                objectId: this.location.objectId
            }
            newUser.set('location', locationPointer)
        }



        newUser.set('isAdmin', this.isAdmin)
        newUser.set('isDeleted', this.isDeleted)



        if (this.imageUrl !== null) {
            newUser.set('imageUrl', this.imageUrl)
        }

        newUser.save()
            .then((newUser) => {
                this.objectId = newUser.id
                console.log('New User Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new user, with error code: ' + error.message);
                callback(false, error.message)
            });

    }



    uploadImage(file, callback) {

        // let image = this.image 
        if (file == null) {
            callback(false, 'No User image to add.')
        }

        provideCompressedFile(file, 200, 0.3 , (succ, file) => {

            if (succ) {
                console.log('UPLOADING COMPRESSED FILE')
            } else {
                cogoToast.warn('This image could not be compressed. Upload will take time.')
                console.log('UPLOADING ORGINAL FILE')
            }

            console.log('Another Comment')

            var fName = `userPhoto${this.username ?? 'NoUserName'}.png`
            var parseFile = new Parse.File(fName, file, "image/png");


            parseFile.save()
                .then((fileObj) => {

                    // The object was retrieved successfully.

                    let fileUrl = fileObj.url()
                    if (fileUrl != null) {

                        this.imageUrl = fileUrl

                        this.update((succ, errMsg) => {
                            callback(succ, fileUrl, 'Error while update ImageUrl to User after image Uploaded =' + errMsg)
                        })

                    } else {
                        console.log("Could not get User file url.")
                        callback(false, null, 'Could not get User pic file url.')
                        return
                    }


                }).catch((error) => {
                    // The file either could not be read, or could not be saved to Parse.
                    callback(false, null, "Error while uploading User Profile file or finding user while doing it." + error.message)

                })
        })


    }


    update(callback) {
        if (this.objectId == null) {
            console.log("Error : No object id to update User")
            callback(false, 'Error =' + 'Error : No object id to update User')
            return
        }

        var query = new Parse.Query(UsersClass);
        query.get(this.objectId)
            .then((user) => {
                // The object was retrieved successfully.

                user.set('name', this.name)
                user.set('username', this.username)
                user.set('password', this.password)
                user.set('phone', this.phone)
                user.set("email", this.email)
                user.set('userType', this.userType)

                if (this.position.objectId || this.position.id) {
                    let positionPointer = {
                        __type: 'Pointer',
                        className: 'Position',
                        objectId: this.position.objectId ?? this.position.id ?? ""
                    }
                    user.set('position', positionPointer)
                }else{
                    console.log('Failed to create new user, as position id was not found ');
                    callback(false, 'Failed to create new user, as position id was not found ')
                    return
                }

                user.set('isFranchiseAdmin', this.isFranchiseAdmin)
                user.set('isAgent', this.isAgent)

                if (this.location){
                    let locationPointer = {
                        __type: 'Pointer',
                        className: 'Location',
                        objectId: this.location.objectId
                    }
                    user.set('location', locationPointer)
                }
        

                user.set('isAdmin', this.isAdmin)
                user.set('isDeleted', this.isDeleted)

                if (this.imageUrl !== null) {
                    user.set('imageUrl', this.imageUrl)
                }

                user.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }

    static getUser(username, password, callback) {

        const userQuery = new Parse.Query(UsersClass);
        userQuery.equalTo("username", username);
        userQuery.equalTo("password", password);

        userQuery.find().then((objects) => {
            console.log('All Users =')
            console.log(objects)
            if (objects.length > 0) {
                let thisUser = User.initFromPFObject(objects[0], false)
                if (thisUser !== null) {
                    callback(true, thisUser, '')
                }
            } else {
                callback(false, null, "Invalid")
            }

        }, (error) => {
            console.log('Error while getting Users ', error.message)
            callback(false, null, error.message)
        })
    }


    static getAll = (callback) => {

        const userQuery = new Parse.Query(UsersClass);
        userQuery.include('position')
        userQuery.ascending('createdAt')
        // userQuery.exclude('password')

        userQuery.find().then((objects) => {
            console.log('All Users =')
            console.log(objects)
            if (objects.length > 0) {

                let allUsers = []
                for (let i = 0; i < objects.length; i++) {
                    const thisUserObj = objects[i];
                    let thisUser = User.initFromPFObject(thisUserObj, false)
                    if (thisUser !== null) {
                        allUsers.push(thisUser)
                    }
                }
                callback(true, allUsers, '')
            } else {
                callback(false, null, "No Users")
            }

        }, (error) => {
            console.log('Error while getting all Users ', error.message)
            callback(false, null, error.message)
        })
    }


    delete(callback) {
        if (this.objectId == null) {
            callback(false, 'No Id of the user to delete')
        }

        let thisObj = new UsersClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting user ', error.message)
            callback(false, error.message)
        });

    }


    toPFObject() {
        let thisObj = new UsersClass()
        thisObj.id = this.objectId
        return thisObj
    }

}








export class Position {

    constructor(name, control, objectId,  isAgentPosition, isAdminPosition, isFranchiseAdminPosition, forLocation ) {
        this.name = name
        this.control = control
        this.objectId = objectId
        this.isAgentPosition = isAgentPosition ?? false
        this.isAdminPosition = isAdminPosition ?? false
        this.isFranchiseAdminPosition = isFranchiseAdminPosition ?? false
        this.isDeletedUserPosition = false ?? false
        this.forLocation = forLocation 

        console.log("INCOMING FOR LOCATION")
        console.log(forLocation)

    }

    static initBasicPositionWithNoAccess(name) {
        let storeControl = StoreControl.init()
        let factoryControl = FactoryControl.init()
        let vendorControl = VendorControl.init()

        let ordersControl = OrdersControl.init(storeControl, factoryControl, vendorControl)

        let clientsControl = ClientsControl.init()
        let stocksControl = StocksControl.init()
        let reportsControl = ReportsControl.init()
        let transitControl = TransitControl.init()

        let control = Control.init(ordersControl, clientsControl, stocksControl, reportsControl, transitControl)

        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        let forLoc = null

        if (cu.isFranchiseAdmin === true || cl.isFranchise === true || cl.type === LocationType.franchise){
            forLoc = cl
        }

        let position = new Position("BASIC", control, null, null, null, null, forLoc)
        position.name = name
        return position
    }

    static copyFrom(obj) {
        let pos = new this('', null, null, null, null, null, null)
        pos.name = obj.name
        pos.control = Control.copyFrom(obj.control)
        pos.objectId = obj.objectId
        pos.isAgentPosition = obj.isAgentPosition
        pos.isAdminPosition = obj.isAdminPosition
        pos.isFranchiseAdminPosition = obj.isFranchiseAdminPosition
        pos.isDeletedUserPosition = obj.false
        pos.forLocation = obj.forLocation 
        return pos
    }

    static initFromPFObject(obj) {

        console.log("Object is")
        console.log(obj)

        let name = obj.get('name')
        let controlDescription = obj.get('controlDescription')
        // console.log('Control Description = ' + controlDescription)

        if (controlDescription === null) {
            console.log('No Control Description')
            return null
        }

        let control = Control.init(controlDescription)
        let id = obj.objectId ?? obj.id ?? ""

        var isAgent = false
        var isAdmin = false
        var isFranchiseAdmin = false
        var isDeletedUserPosition = false
        var forLocation = null

        let isA = obj.get('isAgentPosition') 
         if (isA){
            isAgent = isA
        }
        
        let isAd =  obj.get('isAdminPosition')  
        if (isAd) {
            isAdmin = isAd
        }
        
        let isFAd = obj.get('isFranchiseAdminPosition')
        if  (isFAd) {
            isFranchiseAdmin = isFAd
        }

        let isDUP = obj.get('isDeletedUserPosition')
        if (isDUP) {
            isDeletedUserPosition = isDUP
        }

        console.log("FOR LOCATION")
        console.log(obj.get('forLocation'))

        forLocation = obj.get('forLocation')

        // We can also directly use js Object given in forLocation. But this is how we have implemented this on otherplatforms, as they do not get full location obj with position        
        if (forLocation){
            let locations = getLocations()
            let matchLoc = locations.filter( (locUnit) => { return locUnit.objectId === forLocation.id }  )

            console.log("MATCHLOC")
            console.log(matchLoc)


            if (matchLoc !== null){
                if (matchLoc.length > 0){

                    console.log("SETTING FORLOCATION")


                    forLocation = matchLoc[0]
                }
            }
        }
    
        let pos = new Position(name, control, id, isAgent, isAdmin, isFranchiseAdmin, forLocation)

        console.log("FOR LOCATION AFTER CREATED")
        console.log(pos.forLocation)


        pos.isDeletedUserPosition = isDeletedUserPosition
        return pos
    }

    add(callback) {
        let ob = new PositionClass()
        ob.set('name', this.name)
        let controlDesc = this.control.description()
        ob.set('controlDescription', controlDesc)

        
        if (this.forLocation){
            let locationPointer = {
                __type: 'Pointer',
                className: 'Location',
                objectId: this.forLocation.objectId
            }
            ob.set('forLocation', locationPointer)
        }

        console.log("SAVING POSITION OBJ AS")
        console.log(ob.forLocation)

        ob.save()
            .then((position) => {
                this.objectId = position.id
                console.log('New Position Created')
                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new position, with error code: ' + error.message);
                callback(false, error.message)
            });
    }

    update(callback) {

        var query = new Parse.Query(PositionClass);
        query.get(this.objectId)
            .then((position) => {
                // The object was retrieved successfully.
                position.set("name", this.name)
                let desc = this.control.description()
                console.log('Sending Description : ')
                console.log(desc)

                position.set("controlDescription", desc)

                if (this.forLocation){
                    let locationPointer = {
                        __type: 'Pointer',
                        className: 'Location',
                        objectId: this.forLocation.objectId
                    }
                    position.set('forLocation', locationPointer)
                }

                position.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });
    }

    static getAll(callback) {
        var query = new Parse.Query(PositionClass);
        query.descending('createdAt')

        query.find()
            .then((positions) => {

                console.log('RAW POSITIONS ARE')
                console.log(positions)
                if (positions !== null) {
                    let convertedPositions = []
                    for (let i = 0; i < positions.length; i++) {
                        const thisPosObj = positions[i];
                        // CONVERT LOCS TO LOCAION OBJECTS
                        let thisPos = this.initFromPFObject(thisPosObj)
                        if (thisPos !== null) {
                            convertedPositions.push(thisPos)
                        } else {
                            console.log('Could not convert Object to Position')
                        }

                    }
                    callback(true, convertedPositions, '')

                }

            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, [], 'Error =' + error.message)
            });

    }



    delete(callback) {
        console.log("Delete Called ")
        if (this.objectId == null) {
            console.log('Error No object id to delete Position ')

            callback(false, "Error : No object id to delete Position ")
        }

        let thisObj = new PositionClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting position ', error.message)
            callback(false, error.message)
        });

    }

    //TODO:- Only allow deleting a position if no users exist in this position now. Ask them to shift these users to other positions first.
    //MARK:-

    // fetch all users in this position
    // if none, go ahead and delete
    // if yes, Ask to shift these users to other positions first and then try delete again.



    toPFObject() {
        let ob = new PositionClass()
        ob.id = this.objectId
        return ob
    }



    static initFromPropertiesDict(positionProperties) {
        let position = Position.initBasicPositionWithNoAccess('')

        position.name = positionProperties.name
        position.objectId = positionProperties.objectId
        let control = positionProperties.control

        // ORDERS
        position.control.ordersControl.isAllowed = control[ControlKey.OrdersControl_isAllowed]

        // Order Control subtype 1 - Store
        position.control.ordersControl.storeControl.isAllowed = control[ControlKey.OrdersControl_StoreControl_isAllowed]
        position.control.ordersControl.storeControl.newOrders = control[ControlKey.OrdersControl_StoreControl_newOrders]
        position.control.ordersControl.storeControl.sendOrders = control[ControlKey.OrdersControl_StoreControl_sendOrders]
        position.control.ordersControl.storeControl.recieveOrders = control[ControlKey.OrdersControl_StoreControl_recieveOrders]
        position.control.ordersControl.storeControl.searchOrders = control[ControlKey.OrdersControl_StoreControl_searchOrders]
        position.control.ordersControl.storeControl.approveTrial = control[ControlKey.OrdersControl_StoreControl_approveTrial]
        position.control.ordersControl.storeControl.approveFinishing = control[ControlKey.OrdersControl_StoreControl_approveFinishing]
        position.control.ordersControl.storeControl.approveDelivery = control[ControlKey.OrdersControl_StoreControl_approveDelivery]
        position.control.ordersControl.storeControl.viewStatus = control[ControlKey.OrdersControl_StoreControl_viewStatus]
        position.control.ordersControl.storeControl.clientDetailsVisibility = control[ControlKey.OrdersControl_StoreControl_clientDetailsVisibility]
        position.control.ordersControl.storeControl.addingNewProducts = control[ControlKey.OrdersControl_StoreControl_addingNewProducts]
        position.control.ordersControl.storeControl.actingOnProducts = control[ControlKey.OrdersControl_StoreControl_actingOnProducts]
        position.control.ordersControl.storeControl.addingPicsMeasurementsRemarks = control[ControlKey.OrdersControl_StoreControl_addingPicsMeasurementsRemarks]

        // Order Control subtype 2 - Factory
        position.control.ordersControl.factoryControl.isAllowed = control[ControlKey.OrdersControl_FactoryControl_isAllowed]
        position.control.ordersControl.factoryControl.confirmOrders = control[ControlKey.OrdersControl_FactoryControl_confirmOrders]
        position.control.ordersControl.factoryControl.completeOrders = control[ControlKey.OrdersControl_FactoryControl_completeOrders]
        position.control.ordersControl.factoryControl.sendOrders = control[ControlKey.OrdersControl_FactoryControl_sendOrders]
        position.control.ordersControl.factoryControl.searchOrders = control[ControlKey.OrdersControl_FactoryControl_searchOrders]
        position.control.ordersControl.factoryControl.clientDetailsVisibility = control[ControlKey.OrdersControl_FactoryControl_clientDetailsVisibility]
        position.control.ordersControl.factoryControl.actingOnProducts = control[ControlKey.OrdersControl_FactoryControl_actingOnProducts]
        position.control.ordersControl.factoryControl.addingPicsMeasurementsRemarks = control[ControlKey.OrdersControl_FactoryControl_addingPicsMeasurementsRemarks]

        // Order Control subtype 2 - Vendor
        position.control.ordersControl.vendorControl.isAllowed = control[ControlKey.OrdersControl_VendorControl_isAllowed]
        position.control.ordersControl.vendorControl.confirmOrders = control[ControlKey.OrdersControl_VendorControl_confirmOrders]
        position.control.ordersControl.vendorControl.completeOrders = control[ControlKey.OrdersControl_VendorControl_completeOrders]
        position.control.ordersControl.vendorControl.sendOrders = control[ControlKey.OrdersControl_VendorControl_sendOrders]
        position.control.ordersControl.vendorControl.searchOrders = control[ControlKey.OrdersControl_VendorControl_searchOrders]
        position.control.ordersControl.vendorControl.clientDetailsVisibility = control[ControlKey.OrdersControl_VendorControl_clientDetailsVisibility]
        position.control.ordersControl.vendorControl.actingOnProducts = control[ControlKey.OrdersControl_VendorControl_actingOnProducts]
        position.control.ordersControl.vendorControl.addingPicsMeasurementsRemarks = control[ControlKey.OrdersControl_VendorControl_addingPicsMeasurementsRemarks]

        // CLIENTS
        position.control.clientsControl.isAllowed = control[ControlKey.ClientsControl_isAllowed]
        position.control.clientsControl.recentClients = control[ControlKey.ClientsControl_recentClients]
        position.control.clientsControl.searchClients = control[ControlKey.ClientsControl_searchClients]
        position.control.clientsControl.messages = control[ControlKey.ClientsControl_messages]
        position.control.clientsControl.notifications = control[ControlKey.ClientsControl_notifications]

        // STOCKS
        position.control.stocksControl.isAllowed = control[ControlKey.StocksControl_isAllowed]
        position.control.stocksControl.stockByCategory = control[ControlKey.StocksControl_stockByCategory]
        position.control.stocksControl.searchStocks = control[ControlKey.StocksControl_searchStocks]
        position.control.stocksControl.addingNewCategories = control[ControlKey.StocksControl_addingNewCategories]
        position.control.stocksControl.addingNewStocks = control[ControlKey.StocksControl_addingNewStocks]
        position.control.stocksControl.actingOnStocks = control[ControlKey.StocksControl_actingOnStocks]

        // REPORTS
        position.control.reportsControl.isAllowed = control[ControlKey.ReportsControl_isAllowed]
        position.control.reportsControl.orderReports = control[ControlKey.ReportsControl_orderReports]
        position.control.reportsControl.productReports = control[ControlKey.ReportsControl_productReports]
        position.control.reportsControl.clientReports = control[ControlKey.ReportsControl_clientReports]
        position.control.reportsControl.stockReports = control[ControlKey.ReportsControl_stockReports]

        // TRANSIT
        position.control.transitControl.isAllowed = control[ControlKey.TransitControl_isAllowed]
        position.control.transitControl.challans = control[ControlKey.TransitControl_challans]
        position.control.transitControl.scanSendRecieve = control[ControlKey.TransitControl_scanSendRecieve]
        position.control.transitControl.scanInfo = control[ControlKey.TransitControl_scanInfo]
        return position
    }

    departmentAccessDescription() {

        console.log(' ACCESS DESCRIPTION IS CALLED')

        var description = []

        if (this.control.ordersControl.storeControl.isAllowed) {
            description.push("Orders(Store)")
        }

        if (this.control.ordersControl.factoryControl.isAllowed) {
            description.push("Orders(Factory)")
        }

        if (this.control.ordersControl.vendorControl.isAllowed) {
            description.push("Orders(Vendors)")
        }

        if (this.control.clientsControl.isAllowed) {
            description.push("Clients")
        }

        if (this.control.stocksControl.isAllowed) {
            description.push("Stocks")
        }

        if (this.control.reportsControl.isAllowed) {
            description.push("Reports")
        }

        if (this.control.transitControl.isAllowed) {
            description.push("Transit")
        }

        if (showOnlyClientFeature) {
            description = []
            if (this.control.ordersControl.storeControl.isAllowed) {
                description.push("New Client")
            }
            if (this.control.clientsControl.isAllowed) {
                description.push("Recent Clients, Client Search")
            }
            if (this.control.reportsControl.isAllowed) {
                description.push("Client Reports")
            }
        }


        let desc = description.join(", ")

        console.log('SENDING DESC = ' + desc)

        return desc
    }


    static areEqualInValue(position1, position2) {

        var isEqual = true
        if (position1.name !== position2.name) {
            isEqual = false
        }

        //        print("Position1 Name = \(position1.name) == \(position2.name)")


        // ORDERS
        if (position1.control.ordersControl.isAllowed.allowed !== position2.control.ordersControl.isAllowed.allowed) {
            isEqual = false
        }


        //Order Control subtype 1 - Store
        if (position1.control.ordersControl.storeControl.isAllowed !== position2.control.ordersControl.storeControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.newOrders !== position2.control.ordersControl.storeControl.newOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.sendOrders !== position2.control.ordersControl.storeControl.sendOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.recieveOrders !== position2.control.ordersControl.storeControl.recieveOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.searchOrders !== position2.control.ordersControl.storeControl.searchOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.approveTrial !== position2.control.ordersControl.storeControl.approveTrial) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.approveFinishing !== position2.control.ordersControl.storeControl.approveFinishing) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.approveDelivery !== position2.control.ordersControl.storeControl.approveDelivery) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.viewStatus !== position2.control.ordersControl.storeControl.viewStatus) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.clientDetailsVisibility !== position2.control.ordersControl.storeControl.clientDetailsVisibility) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.addingNewProducts !== position2.control.ordersControl.storeControl.addingNewProducts) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.actingOnProducts !== position2.control.ordersControl.storeControl.actingOnProducts) {
            isEqual = false
        }
        if (position1.control.ordersControl.storeControl.addingPicsMeasurementsRemarks !== position2.control.ordersControl.storeControl.addingPicsMeasurementsRemarks) {
            isEqual = false
        }

        // Order Control subtype 2 - Factory
        if (position1.control.ordersControl.factoryControl.isAllowed !== position2.control.ordersControl.factoryControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.confirmOrders !== position2.control.ordersControl.factoryControl.confirmOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.completeOrders !== position2.control.ordersControl.factoryControl.completeOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.sendOrders !== position2.control.ordersControl.factoryControl.sendOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.searchOrders !== position2.control.ordersControl.factoryControl.searchOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.clientDetailsVisibility !== position2.control.ordersControl.factoryControl.clientDetailsVisibility) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.actingOnProducts !== position2.control.ordersControl.factoryControl.actingOnProducts) {
            isEqual = false
        }
        if (position1.control.ordersControl.factoryControl.addingPicsMeasurementsRemarks !== position2.control.ordersControl.factoryControl.addingPicsMeasurementsRemarks) {
            isEqual = false
        }


        // Order Control subtype 2 - Vendor
        if (position1.control.ordersControl.vendorControl.isAllowed !== position2.control.ordersControl.vendorControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.confirmOrders !== position2.control.ordersControl.vendorControl.confirmOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.completeOrders !== position2.control.ordersControl.vendorControl.completeOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.sendOrders !== position2.control.ordersControl.vendorControl.sendOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.searchOrders !== position2.control.ordersControl.vendorControl.searchOrders) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.clientDetailsVisibility !== position2.control.ordersControl.vendorControl.clientDetailsVisibility) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.actingOnProducts !== position2.control.ordersControl.vendorControl.actingOnProducts) {
            isEqual = false
        }
        if (position1.control.ordersControl.vendorControl.addingPicsMeasurementsRemarks !== position2.control.ordersControl.vendorControl.addingPicsMeasurementsRemarks) {
            isEqual = false
        }

        // CLIENTS
        if (position1.control.clientsControl.isAllowed !== position2.control.clientsControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.clientsControl.recentClients !== position2.control.clientsControl.recentClients) {
            isEqual = false
        }
        if (position1.control.clientsControl.searchClients !== position2.control.clientsControl.searchClients) {
            isEqual = false
        }
        if (position1.control.clientsControl.messages !== position2.control.clientsControl.messages) {
            isEqual = false
        }
        if (position1.control.clientsControl.notifications !== position2.control.clientsControl.notifications) {
            isEqual = false
        }

        // STOCKS
        if (position1.control.stocksControl.isAllowed !== position2.control.stocksControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.stocksControl.stockByCategory !== position2.control.stocksControl.stockByCategory) {
            isEqual = false
        }
        if (position1.control.stocksControl.searchStocks !== position2.control.stocksControl.searchStocks) {
            isEqual = false
        }
        if (position1.control.stocksControl.addingNewCategories !== position2.control.stocksControl.addingNewCategories) {
            isEqual = false
        }
        if (position1.control.stocksControl.addingNewStocks !== position2.control.stocksControl.addingNewStocks) {
            isEqual = false
        }
        if (position1.control.stocksControl.actingOnStocks !== position2.control.stocksControl.actingOnStocks) {
            isEqual = false
        }

        // REPORTS
        if (position1.control.reportsControl.isAllowed !== position2.control.reportsControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.reportsControl.orderReports !== position2.control.reportsControl.orderReports) {
            isEqual = false
        }
        if (position1.control.reportsControl.productReports !== position2.control.reportsControl.productReports) {
            isEqual = false
        }
        if (position1.control.reportsControl.clientReports !== position2.control.reportsControl.clientReports) {
            isEqual = false
        }
        if (position1.control.reportsControl.stockReports !== position2.control.reportsControl.stockReports) {
            isEqual = false
        }


        // TRANSIT
        if (position1.control.transitControl.isAllowed !== position2.control.transitControl.isAllowed) {
            isEqual = false
        }
        if (position1.control.transitControl.challans !== position2.control.transitControl.challans) {
            isEqual = false
        }
        if (position1.control.transitControl.scanSendRecieve !== position2.control.transitControl.scanSendRecieve) {
            isEqual = false
        }
        if (position1.control.transitControl.scanInfo !== position2.control.transitControl.scanInfo) {
            isEqual = false
        }



        return isEqual
    }


}


class Control {

    constructor(ordersControl,
        clientsControl,
        stocksControl,
        reportsControl,
        transitControl) {
        this.ordersControl = ordersControl
        this.clientsControl = clientsControl
        this.stocksControl = stocksControl
        this.reportsControl = reportsControl
        this.transitControl = transitControl
    }

    static copyFrom(obj) {
        let c = new this(null, null, null, null, null)
        c.ordersControl = OrdersControl.copyFrom(obj.ordersControl)
        c.clientsControl = ClientsControl.copyFrom(obj.clientsControl)
        c.stocksControl = StocksControl.copyFrom(obj.stocksControl)
        c.reportsControl = ReportsControl.copyFrom(obj.reportsControl)
        c.transitControl = TransitControl.copyFrom(obj.transitControl)
        return c
    }

    description() {

        let desc =
            `OC_isAllowed:${this.ordersControl.isAllowed}:OC_isAllowed,
OC_StoreC_addingNewProducts:${this.ordersControl.storeControl.addingNewProducts}:OC_StoreC_addingNewProducts,
        OC_StoreC_clientDetailsVisibility:${this.ordersControl.storeControl.clientDetailsVisibility}:OC_StoreC_clientDetailsVisibility,
            OC_StoreC_sendOrders:${this.ordersControl.storeControl.sendOrders}:OC_StoreC_sendOrders,
                OC_StoreC_viewStatus:${this.ordersControl.storeControl.viewStatus}:OC_StoreC_viewStatus,
                    OC_StoreC_newOrders:${this.ordersControl.storeControl.newOrders}:OC_StoreC_newOrders,
                        OC_StoreC_approveTrial:${this.ordersControl.storeControl.approveTrial}:OC_StoreC_approveTrial,
                            OC_StoreC_approveFinishing:${this.ordersControl.storeControl.approveFinishing}:OC_StoreC_approveFinishing,
                                OC_StoreC_isAllowed:${this.ordersControl.isAllowed}:OC_StoreC_isAllowed,
                                    OC_StoreC_searchOrders:${this.ordersControl.storeControl.searchOrders}:OC_StoreC_searchOrders,
                                        OC_StoreC_actingOnProducts:${this.ordersControl.storeControl.actingOnProducts}:OC_StoreC_actingOnProducts,
                                            OC_StoreC_approveDelivery:${this.ordersControl.storeControl.approveDelivery}:OC_StoreC_approveDelivery,
                                                OC_StoreC_recieveOrders:${this.ordersControl.storeControl.recieveOrders}:OC_StoreC_recieveOrders,
                                                    OC_StoreC_addingPicsMeasurementsRemarks:${this.ordersControl.storeControl.addingPicsMeasurementsRemarks}:OC_StoreC_addingPicsMeasurementsRemarks,
            OC_FactoryC_isAllowed:${this.ordersControl.factoryControl.isAllowed}:OC_FactoryC_isAllowed,
                    OC_FactoryC_confirmOrders:${this.ordersControl.factoryControl.confirmOrders}:OC_FactoryC_confirmOrders,
                        OC_FactoryC_completeOrders:${this.ordersControl.factoryControl.completeOrders}:OC_FactoryC_completeOrders,
                            OC_FactoryC_searchOrders:${this.ordersControl.factoryControl.searchOrders}:OC_FactoryC_searchOrders,
                                OC_FactoryC_actingOnProducts:${this.ordersControl.factoryControl.actingOnProducts}:OC_FactoryC_actingOnProducts,
                                    OC_FactoryC_sendOrders:${this.ordersControl.factoryControl.sendOrders}:OC_FactoryC_sendOrders,
                                        OC_FactoryC_addingPicsMeasurementsRemarks:${this.ordersControl.factoryControl.addingPicsMeasurementsRemarks}:OC_FactoryC_addingPicsMeasurementsRemarks,
                                            OC_FactoryC_clientDetailsVisibility:${this.ordersControl.factoryControl.clientDetailsVisibility}:OC_FactoryC_clientDetailsVisibility,
            OC_VendorC_isAllowed:${this.ordersControl.vendorControl.isAllowed}:OC_VendorC_isAllowed,
                OC_VendorC_confirmOrders:${this.ordersControl.vendorControl.confirmOrders}:OC_VendorC_confirmOrders,
                    OC_VendorC_completeOrders:${this.ordersControl.vendorControl.completeOrders}:OC_VendorC_completeOrders,
                        OC_VendorC_searchOrders:${this.ordersControl.vendorControl.searchOrders}:OC_VendorC_searchOrders,
                            OC_VendorC_actingOnProducts:${this.ordersControl.vendorControl.actingOnProducts}:OC_VendorC_actingOnProducts,
                                OC_VendorC_sendOrders:${this.ordersControl.vendorControl.sendOrders}:OC_VendorC_sendOrders,
                                    OC_VendorC_addingPicsMeasurementsRemarks:${this.ordersControl.vendorControl.addingPicsMeasurementsRemarks}:OC_VendorC_addingPicsMeasurementsRemarks,
                                        OC_VendorC_clientDetailsVisibility:${this.ordersControl.vendorControl.clientDetailsVisibility}:OC_VendorC_clientDetailsVisibility,
            RC_clientReports:${this.reportsControl.clientReports}:RC_clientReports,
                RC_orderReports:${this.reportsControl.orderReports}:RC_orderReports,
                    RC_productReports:${this.reportsControl.productReports}:RC_productReports,
                        RC_isAllowed:${this.reportsControl.isAllowed}:RC_isAllowed,
                            RC_stockReports:${this.reportsControl.stockReports}:RC_stockReports,
            TC_isAllowed:${this.transitControl.isAllowed}:TC_isAllowed,
                TC_challans:${this.transitControl.challans}:TC_challans,
                    TC_scanSendRecieve:${this.transitControl.scanSendRecieve}:TC_scanSendRecieve,
                        TC_scanInfo:${this.transitControl.scanInfo}:TC_scanInfo,
            SC_isAllowed:${this.stocksControl.isAllowed}:SC_isAllowed,
                SC_searchStocks:${this.stocksControl.searchStocks}:SC_searchStocks,
                    SC_addingNewStocks:${this.stocksControl.addingNewStocks}:SC_addingNewStocks,
                        SC_actingOnStocks:${this.stocksControl.actingOnStocks}:SC_actingOnStocks,
                            SC_addingNewCategories:${this.stocksControl.addingNewCategories}:SC_addingNewCategories,
                                SC_stockByCategory:${this.stocksControl.stockByCategory}:SC_stockByCategory,
            CC_recentClients:${this.clientsControl.recentClients}:CC_recentClients,
                CC_notifications:${this.clientsControl.notifications}:CC_notifications,
                    CC_isAllowed:${this.clientsControl.isAllowed}:CC_isAllowed,
                        CC_searchClients:${this.clientsControl.searchClients}:CC_searchClients,
                            CC_messages:${this.clientsControl.messages}:CC_messages,
                                                                                                                                                                        
                                                                            `

        return desc
    }


    static init(description) {

        let desc = JSON.stringify(description)

        // console.log('CONTROL DESCRIPTION = ' + desc)


        // console.log('TYPE OF CONTROL DESCRIPTION')
        // console.log(typeof desc)


        // let desString = JSON.stringify(desc)

        // console.log('TYPE OF DESC DESCRIPTION')
        // console.log(typeof desString)


        let OC_isAllowed = getMiddleBoolVal(desc, "OC_isAllowed")
        let OC_StoreC_addingNewProducts = getMiddleBoolVal(desc, "OC_StoreC_addingNewProducts")
        let OC_StoreC_clientDetailsVisibility = getMiddleBoolVal(desc, "OC_StoreC_clientDetailsVisibility")
        let OC_StoreC_sendOrders = getMiddleBoolVal(desc, "OC_StoreC_sendOrders")
        let OC_StoreC_viewStatus = getMiddleBoolVal(desc, "OC_StoreC_viewStatus")
        let OC_StoreC_newOrders = getMiddleBoolVal(desc, "OC_StoreC_newOrders")
        let OC_StoreC_approveTrial = getMiddleBoolVal(desc, "OC_StoreC_approveTrial")
        let OC_StoreC_approveFinishing = getMiddleBoolVal(desc, "OC_StoreC_approveFinishing")
        let OC_StoreC_isAllowed = getMiddleBoolVal(desc, "OC_StoreC_isAllowed")
        let OC_StoreC_searchOrders = getMiddleBoolVal(desc, "OC_StoreC_searchOrders")
        let OC_StoreC_actingOnProducts = getMiddleBoolVal(desc, "OC_StoreC_actingOnProducts")
        let OC_StoreC_approveDelivery = getMiddleBoolVal(desc, "OC_StoreC_approveDelivery")
        let OC_StoreC_recieveOrders = getMiddleBoolVal(desc, "OC_StoreC_recieveOrders")
        let OC_StoreC_addingPicsMeasurementsRemarks = getMiddleBoolVal(desc, "OC_StoreC_addingPicsMeasurementsRemarks")

        let storeControl = new StoreControl(OC_StoreC_isAllowed, OC_StoreC_newOrders, OC_StoreC_sendOrders, OC_StoreC_recieveOrders, OC_StoreC_searchOrders, OC_StoreC_approveTrial, OC_StoreC_approveFinishing, OC_StoreC_approveDelivery, OC_StoreC_viewStatus, OC_StoreC_clientDetailsVisibility, OC_StoreC_addingNewProducts, OC_StoreC_actingOnProducts, OC_StoreC_addingPicsMeasurementsRemarks)


        // console.log('STORE CONTROL WITH INIT')
        // console.log(storeControl)


        let OC_FactoryC_isAllowed = getMiddleBoolVal(desc, "OC_FactoryC_isAllowed")
        let OC_FactoryC_confirmOrders = getMiddleBoolVal(desc, "OC_FactoryC_confirmOrders")
        let OC_FactoryC_completeOrders = getMiddleBoolVal(desc, "OC_FactoryC_completeOrders")
        let OC_FactoryC_sendOrders = getMiddleBoolVal(desc, "OC_FactoryC_sendOrders")
        let OC_FactoryC_searchOrders = getMiddleBoolVal(desc, "OC_FactoryC_searchOrders")
        let OC_FactoryC_actingOnProducts = getMiddleBoolVal(desc, "OC_FactoryC_actingOnProducts")
        let OC_FactoryC_addingPicsMeasurementsRemarks = getMiddleBoolVal(desc, "OC_FactoryC_addingPicsMeasurementsRemarks")
        let OC_FactoryC_clientDetailsVisibility = getMiddleBoolVal(desc, "OC_FactoryC_clientDetailsVisibility")

        let factoryControl = new FactoryControl(OC_FactoryC_isAllowed, OC_FactoryC_confirmOrders, OC_FactoryC_completeOrders, OC_FactoryC_sendOrders, OC_FactoryC_searchOrders, OC_FactoryC_clientDetailsVisibility, OC_FactoryC_actingOnProducts, OC_FactoryC_addingPicsMeasurementsRemarks)

        // console.log('FACTORY CONTROL WITH INIT')
        // console.log(factoryControl)

        // console.log('Orders CONTROL is-allowed WITH INIT')
        // console.log(OC_isAllowed)


        let OC_VendorC_isAllowed = getMiddleBoolVal(desc, "OC_VendorC_isAllowed")
        let OC_VendorC_confirmOrders = getMiddleBoolVal(desc, "OC_VendorC_confirmOrders")
        let OC_VendorC_completeOrders = getMiddleBoolVal(desc, "OC_VendorC_completeOrders")
        let OC_VendorC_sendOrders = getMiddleBoolVal(desc, "OC_VendorC_sendOrders")
        let OC_VendorC_searchOrders = getMiddleBoolVal(desc, "OC_VendorC_searchOrders")
        let OC_VendorC_actingOnProducts = getMiddleBoolVal(desc, "OC_VendorC_actingOnProducts")
        let OC_VendorC_addingPicsMeasurementsRemarks = getMiddleBoolVal(desc, "OC_VendorC_addingPicsMeasurementsRemarks")
        let OC_VendorC_clientDetailsVisibility = getMiddleBoolVal(desc, "OC_VendorC_clientDetailsVisibility")

        let vendorControl = new VendorControl(OC_VendorC_isAllowed, OC_VendorC_confirmOrders, OC_VendorC_completeOrders, OC_VendorC_sendOrders, OC_VendorC_searchOrders, OC_VendorC_clientDetailsVisibility, OC_VendorC_actingOnProducts, OC_VendorC_addingPicsMeasurementsRemarks)



        let ordersControl = new OrdersControl(OC_isAllowed, storeControl, factoryControl, vendorControl)

        // console.log('ORDERS CONTROL WITH INIT')
        // console.log(ordersControl)

        let RC_clientReports = getMiddleBoolVal(desc, "RC_clientReports")
        let RC_orderReports = getMiddleBoolVal(desc, "RC_orderReports")
        let RC_productReports = getMiddleBoolVal(desc, "RC_productReports")
        let RC_isAllowed = getMiddleBoolVal(desc, "RC_isAllowed")
        let RC_stockReports = getMiddleBoolVal(desc, "RC_stockReports")

        let reportsControl = new ReportsControl(RC_isAllowed, RC_orderReports, RC_productReports, RC_clientReports, RC_stockReports)


        let TC_isAllowed = getMiddleBoolVal(desc, "TC_isAllowed")
        let TC_challans = getMiddleBoolVal(desc, "TC_challans")
        let TC_scanSendRecieve = getMiddleBoolVal(desc, "TC_scanSendRecieve")
        let TC_scanInfo = getMiddleBoolVal(desc, "TC_scanInfo")

        let transitControl = new TransitControl(TC_isAllowed, TC_challans, TC_scanSendRecieve, TC_scanInfo)


        let SC_isAllowed = getMiddleBoolVal(desc, "SC_isAllowed")
        let SC_searchStocks = getMiddleBoolVal(desc, "SC_searchStocks")
        let SC_addingNewStocks = getMiddleBoolVal(desc, "SC_addingNewStocks")
        let SC_actingOnStocks = getMiddleBoolVal(desc, "SC_actingOnStocks")
        let SC_addingNewCategories = getMiddleBoolVal(desc, "SC_addingNewCategories")
        let SC_stockByCategory = getMiddleBoolVal(desc, "SC_stockByCategory")

        let stocksControl = new StocksControl(SC_isAllowed, SC_stockByCategory, SC_searchStocks, SC_addingNewCategories, SC_addingNewStocks, SC_actingOnStocks)

        let CC_isAllowed = getMiddleBoolVal(desc, "CC_isAllowed")
        let CC_recentClients = getMiddleBoolVal(desc, "CC_recentClients")
        let CC_notifications = getMiddleBoolVal(desc, "CC_notifications")
        let CC_searchClients = getMiddleBoolVal(desc, "CC_searchClients")
        let CC_messages = getMiddleBoolVal(desc, "CC_messages")


        let clientsControl = new ClientsControl(CC_isAllowed, CC_recentClients, CC_searchClients, CC_messages, CC_notifications)

        return new Control(ordersControl, clientsControl, stocksControl, reportsControl, transitControl)

    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        let ordersControlProperties = this.ordersControl.allPropertiesKeysWithAllowedStatus()
        let clientsControlProperties = this.clientsControl.allPropertiesKeysWithAllowedStatus()
        let stocksControlProperties = this.stocksControl.allPropertiesKeysWithAllowedStatus()
        let reportsControlProperties = this.reportsControl.allPropertiesKeysWithAllowedStatus()
        let transitControlProperties = this.transitControl.allPropertiesKeysWithAllowedStatus()


        properties = {
            ...ordersControlProperties,
            ...clientsControlProperties,
            ...stocksControlProperties,
            ...reportsControlProperties,
            ...transitControlProperties,
        }

        return properties
    }




    isAnyAllowed() {
        let properties = this.allPropertiesKeysWithAllowedStatus()
        var anyAllowed = false

        Object.entries(properties).forEach((entry) => {
            if (entry === true) {
                anyAllowed = true
            }
        })

        return anyAllowed
    }



}



//// HELPER FUNCTION /////




// ==================== By Department =================== //


// ORDERS
class OrdersControl {


    constructor(isAllowed, storeControl, factoryControl, vendorControl) {
        this.isAllowed = isAllowed
        this.storeControl = storeControl
        this.factoryControl = factoryControl
        this.vendorControl = vendorControl
    }

    static init(isAllowed, storeControl, factoryControl, vendorControl) {
        let n = new this(isAllowed, storeControl, factoryControl, vendorControl)
        return n
    }


    static copyFrom(obj) {
        let oc = new this(null, null, null, null)
        oc.isAllowed = obj.isAllowed
        oc.storeControl = StoreControl.copyFrom(obj.storeControl)
        oc.factoryControl = FactoryControl.copyFrom(obj.factoryControl)
        oc.vendorControl = VendorControl.copyFrom(obj.vendorControl)
        console.log('CALLING FROM CASE 3')
        oc.setMainAccessIfAnyIsAllowed()
        return oc
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        let storeControlProperties = this.storeControl.allPropertiesKeysWithAllowedStatus()
        let factoryControlProperties = this.factoryControl.allPropertiesKeysWithAllowedStatus()
        let vendorControlProperties = this.vendorControl.allPropertiesKeysWithAllowedStatus()


        properties = {
            ...storeControlProperties,
            ...factoryControlProperties,
            ...vendorControlProperties
        }

        properties['isAllowed'] = this.isAllowed

        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        var isAnyAllowed = false



        console.log('THIS STORE CONTROL =')
        console.log(this.storeControl)

        let allStoreControlProperties = this.storeControl.allPropertiesArrayWithoutMain()
        let allFactoryControlProperties = this.factoryControl.allPropertiesArrayWithoutMain()
        let allVendorControlProperties = this.vendorControl.allPropertiesArrayWithoutMain()
        let allProperties = [...allStoreControlProperties, ...allFactoryControlProperties, ...allVendorControlProperties]

        for (let i = 0; i < allProperties.length; i++) {
            const element = allProperties[i];
            if (element === true) {
                isAnyAllowed = true
            }
        }

        this.isAllowed = isAnyAllowed
    }


}

class StoreControl {

    constructor(isAllowed,
        newOrders,
        sendOrders,
        recieveOrders,
        searchOrders,
        approveTrial,
        approveFinishing,
        approveDelivery,
        viewStatus,
        clientDetailsVisibility,
        addingNewProducts,
        actingOnProducts,
        addingPicsMeasurementsRemarks
    ) {
        this.isAllowed = isAllowed
        this.newOrders = newOrders
        this.sendOrders = sendOrders
        this.recieveOrders = recieveOrders
        this.searchOrders = searchOrders
        this.approveTrial = approveTrial
        this.approveFinishing = approveFinishing
        this.approveDelivery = approveDelivery
        this.viewStatus = viewStatus
        this.clientDetailsVisibility = clientDetailsVisibility
        this.addingNewProducts = addingNewProducts
        this.actingOnProducts = actingOnProducts
        this.addingPicsMeasurementsRemarks = addingPicsMeasurementsRemarks
        this.setMainAccessIfAnyIsAllowed()
    }

    static init() {
        let sc = new this(false, false, false, false, false, false, false, false, false, false, false, false, false)
        return sc
    }

    static copyFrom(obj) {
        let sc = new this(false, false, false, false, false, false, false, false, false, false, false, false, false)

        sc.isAllowed = obj.isAllowed
        sc.newOrders = obj.newOrders
        sc.sendOrders = obj.sendOrders
        sc.recieveOrders = obj.recieveOrders
        sc.searchOrders = obj.searchOrders
        sc.approveTrial = obj.approveTrial
        sc.approveFinishing = obj.approveFinishing
        sc.approveDelivery = obj.approveDelivery
        sc.viewStatus = obj.viewStatus
        sc.clientDetailsVisibility = obj.clientDetailsVisibility
        sc.addingNewProducts = obj.addingNewProducts
        sc.actingOnProducts = obj.actingOnProducts
        sc.addingPicsMeasurementsRemarks = obj.addingPicsMeasurementsRemarks

        sc.setMainAccessIfAnyIsAllowed()

        return sc
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['newOrders'] = this.newOrders
        properties['sendOrders'] = this.sendOrders
        properties['recieveOrders'] = this.recieveOrders
        properties['searchOrders'] = this.searchOrders
        properties['approveTrial'] = this.approveTrial
        properties['approveFinishing'] = this.approveFinishing
        properties['approveDelivery'] = this.approveDelivery
        properties['viewStatus'] = this.viewStatus
        properties['clientDetailsVisibility'] = this.clientDetailsVisibility
        properties['addingNewProducts'] = this.addingNewProducts
        properties['actingOnProducts'] = this.actingOnProducts
        properties['addingPicsMeasurementsRemarks'] = this.addingPicsMeasurementsRemarks
        return properties
    }

    clientOnly_PropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['newOrders'] = this.newOrders
        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.newOrders)
        properties.push(this.sendOrders)
        properties.push(this.recieveOrders)
        properties.push(this.searchOrders)
        properties.push(this.approveTrial)
        properties.push(this.approveFinishing)
        properties.push(this.approveDelivery)
        properties.push(this.viewStatus)
        properties.push(this.clientDetailsVisibility)
        properties.push(this.addingNewProducts)
        properties.push(this.actingOnProducts)
        properties.push(this.addingPicsMeasurementsRemarks)
        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }


}

// Order Control subtype 2 - Factory
class FactoryControl {

    constructor(
        isAllowed,
        confirmOrders,
        completeOrders,
        sendOrders,
        searchOrders,
        clientDetailsVisibility,
        actingOnProducts,
        addingPicsMeasurementsRemarks
    ) {
        this.isAllowed = isAllowed
        this.confirmOrders = confirmOrders
        this.completeOrders = completeOrders
        this.sendOrders = sendOrders
        this.searchOrders = searchOrders
        this.clientDetailsVisibility = clientDetailsVisibility
        this.actingOnProducts = actingOnProducts
        this.addingPicsMeasurementsRemarks = addingPicsMeasurementsRemarks
        this.setMainAccessIfAnyIsAllowed()
    }

    static init() {
        let fc = new this(false, false, false, false, false, false, false, false)
        return fc
    }

    static copyFrom(obj) {
        let fc = new this(false, false, false, false, false, false, false, false)

        fc.isAllowed = obj.isAllowed
        fc.confirmOrders = obj.confirmOrders
        fc.completeOrders = obj.completeOrders
        fc.sendOrders = obj.sendOrders
        fc.searchOrders = obj.searchOrders
        fc.clientDetailsVisibility = obj.clientDetailsVisibility
        fc.actingOnProducts = obj.actingOnProducts
        fc.addingPicsMeasurementsRemarks = obj.addingPicsMeasurementsRemarks

        fc.setMainAccessIfAnyIsAllowed()

        return fc
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['confirmOrders'] = this.confirmOrders
        properties['completeOrders'] = this.completeOrders
        properties['sendOrders'] = this.sendOrders
        properties['searchOrders'] = this.searchOrders
        properties['clientDetailsVisibility'] = this.clientDetailsVisibility
        properties['actingOnProducts'] = this.actingOnProducts
        properties['addingPicsMeasurementsRemarks'] = this.addingPicsMeasurementsRemarks

        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.confirmOrders)
        properties.push(this.completeOrders)
        properties.push(this.sendOrders)
        properties.push(this.searchOrders)
        properties.push(this.clientDetailsVisibility)
        properties.push(this.actingOnProducts)
        properties.push(this.addingPicsMeasurementsRemarks)

        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }


}


class VendorControl {

    constructor(
        isAllowed,
        confirmOrders,
        completeOrders,
        sendOrders,
        searchOrders,
        clientDetailsVisibility,
        actingOnProducts,
        addingPicsMeasurementsRemarks
    ) {
        this.isAllowed = isAllowed
        this.confirmOrders = confirmOrders
        this.completeOrders = completeOrders
        this.sendOrders = sendOrders
        this.searchOrders = searchOrders
        this.clientDetailsVisibility = clientDetailsVisibility
        this.actingOnProducts = actingOnProducts
        this.addingPicsMeasurementsRemarks = addingPicsMeasurementsRemarks
        this.setMainAccessIfAnyIsAllowed()

    }

    static init() {
        let fc = new this(false, false, false, false, false, false, false, false)
        return fc
    }

    static copyFrom(obj) {
        let fc = new this(false, false, false, false, false, false, false, false)

        fc.isAllowed = obj.isAllowed
        fc.confirmOrders = obj.confirmOrders
        fc.completeOrders = obj.completeOrders
        fc.sendOrders = obj.sendOrders
        fc.searchOrders = obj.searchOrders
        fc.clientDetailsVisibility = obj.clientDetailsVisibility
        fc.actingOnProducts = obj.actingOnProducts
        fc.addingPicsMeasurementsRemarks = obj.addingPicsMeasurementsRemarks

        fc.setMainAccessIfAnyIsAllowed()

        return fc
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['confirmOrders'] = this.confirmOrders
        properties['completeOrders'] = this.completeOrders
        properties['sendOrders'] = this.sendOrders
        properties['searchOrders'] = this.searchOrders
        properties['clientDetailsVisibility'] = this.clientDetailsVisibility
        properties['actingOnProducts'] = this.actingOnProducts
        properties['addingPicsMeasurementsRemarks'] = this.addingPicsMeasurementsRemarks

        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.confirmOrders)
        properties.push(this.completeOrders)
        properties.push(this.sendOrders)
        properties.push(this.searchOrders)
        properties.push(this.clientDetailsVisibility)
        properties.push(this.actingOnProducts)
        properties.push(this.addingPicsMeasurementsRemarks)

        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }


}


// CLIENTS
class ClientsControl {

    constructor(
        isAllowed,
        recentClients,
        searchClients,
        messages,
        notifications
    ) {
        this.isAllowed = isAllowed
        this.recentClients = recentClients
        this.searchClients = searchClients
        this.messages = messages
        this.notifications = notifications
        this.setMainAccessIfAnyIsAllowed()
    }

    static init() {
        let c = new this(false, false, false, false, false)
        return c
    }

    static copyFrom(obj) {
        let c = new this(false, false, false, false, false)

        c.isAllowed = obj.isAllowed
        c.recentClients = obj.recentClients
        c.searchClients = obj.searchClients
        c.messages = obj.messages
        c.notifications = obj.notifications

        c.setMainAccessIfAnyIsAllowed()

        return c
    }


    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['recentClients'] = this.recentClients
        properties['searchClients'] = this.searchClients
        properties['messages'] = this.messages
        properties['notifications'] = this.notifications
        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.recentClients)
        properties.push(this.searchClients)
        properties.push(this.messages)
        properties.push(this.notifications)
        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }

}

// STOCKS
class StocksControl {

    constructor(
        isAllowed,
        stockByCategory,
        searchStocks,
        addingNewCategories,
        addingNewStocks,
        actingOnStocks
    ) {
        this.isAllowed = isAllowed
        this.stockByCategory = stockByCategory
        this.searchStocks = searchStocks
        this.addingNewCategories = addingNewCategories
        this.addingNewStocks = addingNewStocks
        this.actingOnStocks = actingOnStocks
        this.setMainAccessIfAnyIsAllowed()

    }

    static init() {
        let c = new StocksControl(false, false, false, false, false, false)
        return c
    }

    static copyFrom(obj) {
        let c = new StocksControl(false, false, false, false, false, false)

        c.isAllowed = obj.isAllowed
        c.stockByCategory = obj.stockByCategory
        c.searchStocks = obj.searchStocks
        c.addingNewCategories = obj.addingNewCategories
        c.addingNewStocks = obj.addingNewStocks
        c.actingOnStocks = obj.actingOnStocks

        c.setMainAccessIfAnyIsAllowed()

        return c
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['stockByCategory'] = this.stockByCategory
        properties['searchStocks'] = this.searchStocks
        properties['addingNewCategories'] = this.addingNewCategories
        properties['addingNewStocks'] = this.addingNewStocks
        properties['actingOnStocks'] = this.actingOnStocks
        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.stockByCategory)
        properties.push(this.searchStocks)
        properties.push(this.addingNewCategories)
        properties.push(this.addingNewStocks)
        properties.push(this.actingOnStocks)
        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }

}

// TRANSIT
class TransitControl {

    constructor(
        isAllowed,
        challans,
        scanSendRecieve,
        scanInfo
    ) {
        this.isAllowed = isAllowed
        this.challans = challans
        this.scanSendRecieve = scanSendRecieve
        this.scanInfo = scanInfo
        this.setMainAccessIfAnyIsAllowed()
    }

    static init() {
        let c = new TransitControl(false, false, false, false)
        return c
    }


    static copyFrom(obj) {
        let c = new TransitControl(false, false, false, false)

        c.isAllowed = obj.isAllowed
        c.challans = obj.challans
        c.scanSendRecieve = obj.scanSendRecieve
        c.scanInfo = obj.scanInfo
        c.setMainAccessIfAnyIsAllowed()

        return c
    }

    allPropertiesKeysWithAllowedStatus() {
        var properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['challans'] = this.challans
        properties['scanSendRecieve'] = this.scanSendRecieve
        properties['scanInfo'] = this.scanInfo

        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.challans)
        properties.push(this.scanSendRecieve)
        properties.push(this.scanInfo)
        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }


}


// REPORTS
class ReportsControl {

    constructor(
        isAllowed,
        orderReports,
        productReports,
        clientReports,
        stockReports
    ) {
        this.isAllowed = isAllowed
        this.orderReports = orderReports
        this.productReports = productReports
        this.clientReports = clientReports
        this.stockReports = stockReports
        this.setMainAccessIfAnyIsAllowed()
    }

    static init() {
        let c = new ReportsControl(false, false, false, false, false)
        return c
    }

    static copyFrom(obj) {
        let c = new ReportsControl(false, false, false, false, false)

        c.isAllowed = obj.isAllowed
        c.orderReports = obj.orderReports
        c.productReports = obj.productReports
        c.clientReports = obj.clientReports
        c.stockReports = obj.stockReports

        c.setMainAccessIfAnyIsAllowed()


        return c
    }

    allPropertiesKeysWithAllowedStatus() {
        let properties = {}
        properties['isAllowed'] = this.isAllowed
        properties['orderReports'] = this.orderReports
        properties['productReports'] = this.productReports
        properties['clientReports'] = this.clientReports
        properties['stockReports'] = this.stockReports
        return properties
    }

    clientOnly_PropertiesKeysWithAllowedStatus() {
        var properties = {}
        // properties['isAllowed'] = this.isAllowed
        properties['clientReports'] = this.clientReports
        return properties
    }

    allPropertiesArrayWithoutMain() {
        var properties = []
        properties.push(this.orderReports)
        properties.push(this.productReports)
        properties.push(this.clientReports)
        properties.push(this.stockReports)
        return properties
    }

    setMainAccessIfAnyIsAllowed() {
        let isAnyAllowed = false
        let allProperties = this.allPropertiesArrayWithoutMain()

        for (let i = 0; i < allProperties.length; i++) {
            const property = allProperties[i];
            if (property === true) {
                isAnyAllowed = true
            }
        }
        this.isAllowed = isAnyAllowed
    }


}

// ======================================================= //


// class FeatureControl : NSCopying, Codable {
//     var key: String
//     var description: String
//     var allowed: Bool

//     enum CodingKeys: String, CodingKey {
//         case key = "key"
//         case description = "description"
//         case allowed = "allowed"
//     }


//     init(key: String, description : String, allowed : Bool) {
//         this.key = key
//         this.description = description
//         this.allowed = allowed
//     }

//     func copy(with zone: NSZone ? = nil) -> Any {
//         let copy = FeatureControl(key: key, description: description, allowed: allowed)
//         return copy
//     }

//     func copyValue() -> FeatureControl {
//         return this.copy() as !FeatureControl
//     }

// }


const controlKeyFor = (term) => {
    // return control key
}

const controlDescription = (term) => {
    // return control description
}
const ControlKey = {

    // ORDERS
    OrdersControl_isAllowed: "OrdersControl_isAllowed",

    //Order Control subtype 1 - Store
    OrdersControl_StoreControl_isAllowed: "OrdersControl_StoreControl_isAllowed",
    OrdersControl_StoreControl_newOrders: "OrdersControl_StoreControl_newOrders",
    OrdersControl_StoreControl_sendOrders: "OrdersControl_StoreControl_sendOrders",
    OrdersControl_StoreControl_recieveOrders: "OrdersControl_StoreControl_recieveOrders",
    OrdersControl_StoreControl_searchOrders: "OrdersControl_StoreControl_searchOrders",
    OrdersControl_StoreControl_approveTrial: "OrdersControl_StoreControl_approveTrial",
    OrdersControl_StoreControl_approveFinishing: "OrdersControl_StoreControl_approveFinishing",
    OrdersControl_StoreControl_approveDelivery: "OrdersControl_StoreControl_approveDelivery",
    OrdersControl_StoreControl_viewStatus: "OrdersControl_StoreControl_viewStatus",
    OrdersControl_StoreControl_clientDetailsVisibility: "OrdersControl_StoreControl_clientDetailsVisibility",
    OrdersControl_StoreControl_addingNewProducts: "OrdersControl_StoreControl_addingNewProducts",
    OrdersControl_StoreControl_actingOnProducts: "OrdersControl_StoreControl_actingOnProducts",
    OrdersControl_StoreControl_addingPicsMeasurementsRemarks: "OrdersControl_StoreControl_addingPicsMeasurementsRemarks",



    // Order Control subtype 2 - Factory
    OrdersControl_FactoryControl_isAllowed: "OrdersControl_FactoryControl_isAllowed",
    OrdersControl_FactoryControl_confirmOrders: "OrdersControl_FactoryControl_confirmOrders",
    OrdersControl_FactoryControl_completeOrders: "OrdersControl_FactoryControl_completeOrders",
    OrdersControl_FactoryControl_sendOrders: "OrdersControl_FactoryControl_sendOrders",
    OrdersControl_FactoryControl_searchOrders: "OrdersControl_FactoryControl_searchOrders",
    OrdersControl_FactoryControl_clientDetailsVisibility: "OrdersControl_FactoryControl_clientDetailsVisibility",
    OrdersControl_FactoryControl_actingOnProducts: "OrdersControl_FactoryControl_actingOnProducts",
    OrdersControl_FactoryControl_addingPicsMeasurementsRemarks: "OrdersControl_FactoryControl_addingPicsMeasurementsRemarks",


    // Order Control subtype 2 - Vendor
    OrdersControl_VendorControl_isAllowed: "OrdersControl_VendorControl_isAllowed",
    OrdersControl_VendorControl_confirmOrders: "OrdersControl_VendorControl_confirmOrders",
    OrdersControl_VendorControl_completeOrders: "OrdersControl_VendorControl_completeOrders",
    OrdersControl_VendorControl_sendOrders: "OrdersControl_VendorControl_sendOrders",
    OrdersControl_VendorControl_searchOrders: "OrdersControl_VendorControl_searchOrders",
    OrdersControl_VendorControl_clientDetailsVisibility: "OrdersControl_VendorControl_clientDetailsVisibility",
    OrdersControl_VendorControl_actingOnProducts: "OrdersControl_VendorControl_actingOnProducts",
    OrdersControl_VendorControl_addingPicsMeasurementsRemarks: "OrdersControl_VendorControl_addingPicsMeasurementsRemarks",


    // CLIENTS
    ClientsControl_isAllowed: "ClientsControl_isAllowed",
    ClientsControl_recentClients: "ClientsControl_recentClients",
    ClientsControl_searchClients: "ClientsControl_searchClients",
    ClientsControl_messages: "ClientsControl_messages",
    ClientsControl_notifications: "ClientsControl_notifications",


    // STOCKS
    StocksControl_isAllowed: "StocksControl_isAllowed",
    StocksControl_stockByCategory: "StocksControl_stockByCategory",
    StocksControl_searchStocks: "StocksControl_searchStocks",
    StocksControl_addingNewCategories: "StocksControl_addingNewCategories",
    StocksControl_addingNewStocks: "StocksControl_addingNewStocks",
    StocksControl_actingOnStocks: "StocksControl_actingOnStocks",


    // TRANSIT
    TransitControl_isAllowed: "TransitControl_isAllowed",
    TransitControl_challans: "TransitControl_challans",
    TransitControl_scanSendRecieve: "TransitControl_scanSendRecieve",
    TransitControl_scanInfo: "TransitControl_scanInfo",

    // REPORTS
    ReportsControl_isAllowed: "ReportsControl_isAllowed",
    ReportsControl_orderReports: "ReportsControl_orderReports",
    ReportsControl_productReports: "ReportsControl_productReports",
    ReportsControl_clientReports: "ReportsControl_clientReports",
    ReportsControl_stockReports: "ReportsControl_stockReports",

}


const ControlDescription = {
    // ORDERS
    OrdersControl_isAllowed: "Orders Department",

    //Order Control subtype 1 - Store
    OrdersControl_StoreControl_isAllowed: "Orders Store End",
    OrdersControl_StoreControl_newOrders: "Store - New Orders",
    OrdersControl_StoreControl_sendOrders: "Store - Send Orders To Factory",
    OrdersControl_StoreControl_recieveOrders: "Store - Recieve Orders From Factory",
    OrdersControl_StoreControl_searchOrders: "Store - Search Orders",
    OrdersControl_StoreControl_approveTrial: "Store - Approve Trial",
    OrdersControl_StoreControl_approveFinishing: "Store - Approve Alteration",
    OrdersControl_StoreControl_approveDelivery: "Store - Approve Delivery",
    OrdersControl_StoreControl_viewStatus: "Store - View Status",
    OrdersControl_StoreControl_clientDetailsVisibility: "Store - Client Details Visible",
    OrdersControl_StoreControl_addingNewProducts: "Store - Add New Products",
    OrdersControl_StoreControl_actingOnProducts: "Store - Actions On Products",
    OrdersControl_StoreControl_addingPicsMeasurementsRemarks: "Store - Add Pics, Measurements & Remarks",



    // Order Control subtype 2 - Factory
    OrdersControl_FactoryControl_isAllowed: "Orders Factory End",
    OrdersControl_FactoryControl_confirmOrders: "Factory - Confirm Orders",
    OrdersControl_FactoryControl_completeOrders: "Factory - Complete Orders",
    OrdersControl_FactoryControl_sendOrders: "Factory - Send Orders To Store",
    OrdersControl_FactoryControl_searchOrders: "Factory - Search Orders",
    OrdersControl_FactoryControl_clientDetailsVisibility: "Factory - Client Details Visible",
    OrdersControl_FactoryControl_actingOnProducts: "Factory - Actions On Products",
    OrdersControl_FactoryControl_addingPicsMeasurementsRemarks: "Factory - Add Pics, Measurements & Remarks",


    // Order Control subtype 2 - Factory
    OrdersControl_VendorControl_isAllowed: "Orders Vendor End",
    OrdersControl_VendorControl_confirmOrders: "Vendor - Confirm Orders",
    OrdersControl_VendorControl_completeOrders: "Vendor - Complete Orders",
    OrdersControl_VendorControl_sendOrders: "Vendor - Send Orders To Store",
    OrdersControl_VendorControl_searchOrders: "Vendor - Search Orders",
    OrdersControl_VendorControl_clientDetailsVisibility: "Vendor - Client Details Visible",
    OrdersControl_VendorControl_actingOnProducts: "Vendor - Actions On Products",
    OrdersControl_VendorControl_addingPicsMeasurementsRemarks: "Vendor - Add Pics, Measurements & Remarks",


    // CLIENTS
    ClientsControl_isAllowed: "Clients Department",
    ClientsControl_recentClients: "Recent Clients",
    ClientsControl_searchClients: "Search Clients",
    ClientsControl_messages: "Messages",
    ClientsControl_notifications: "Notifications",


    // STOCKS
    StocksControl_isAllowed: "Stocks Department",
    StocksControl_stockByCategory: "Stocks By Category",
    StocksControl_searchStocks: "Search Stocks",
    StocksControl_addingNewCategories: "Add New Stock Categories",
    StocksControl_addingNewStocks: "Add New Stocks",
    StocksControl_actingOnStocks: "Actions On Stocks",

    // TRANSIT
    TransitControl_isAllowed: "Transit Department",
    TransitControl_challans: "Challans",
    TransitControl_scanSendRecieve: "Scan to Send And Recieve",
    TransitControl_scanInfo: "Scan Info",


    // REPORTS
    ReportsControl_isAllowed: "Reports Department",
    ReportsControl_orderReports: "Orders Reports",
    ReportsControl_productReports: "Products Reports",
    ReportsControl_clientReports: "Clients Reports",
    ReportsControl_stockReports: "Stocks Reports",

}




