import React, { useState, useEffect, useRef } from 'react'
import { Button, Columns, Heading, Box, Icon } from 'react-bulma-components/dist'
import OrderCard from '../components/Cards/OrderCard'
import ProductModal from '../components/Cards/ProductModal'
import OrderModal from '../components/Cards/OrderModal'
import { Order } from '../utilities/Classes'
import { OrderFilter } from '../utilities/Filters'
import ProductsOfOrder from '../containers/ProductsOfOrder'
import ColumnAux from '../containers/ColumnAux'
import { screen, departmentForscreen, departments } from '../utilities/enums'
import cogoToast from 'cogo-toast'


// props.isSearchable, props.screen

const Orders = (props) => {

    let SearchKeys = {
        OrderNo: 'Order No',
        ClientPhone: 'Client Phone',
        ClientEmail: 'Client Email',
        ClientName: 'Client Name',
        POSNo: 'POS No'
    }

    let [orders, setOrders] = useState([])
    let [orderFilter, setOrderFilter] = useState(OrderFilter.init())

    let [fetchOrders, setFetchOrders] = useState(true)
    let [selectedOrder, setSelectedOrder] = useState(null)

    let [searchKey, setSearchKey] = useState(SearchKeys.ClientPhone)
    let [searchVal, setSearchVal] = useState(null)
    let [searching, setSearching] = useState(false)

    let [filterVal, setFilterVal] = useState(null)

    let [noOrdersMessage, setNoOrdersMessage] = useState('')
    const valFieldRef = useRef('valFieldRef')

    const filterValRef = useRef('filterValRef')

    useEffect(() => {

        console.log('FETCHHING ORDERS with currentScreen = ' + props.screen)

        if (props.isSearchable ? false : true) {
            setSearching(true)


            let dept = departmentForscreen(props.screen)
            let isVendor = (dept !== departments.Factory)
            let vendor = null
            if (isVendor && props.vendor) {
                vendor = props.vendor
            }

            setOrders([])

            // cogoToast.info("Getting Orders from UseEffect")

            Order.getOrders(props.screen, props.params ?? "", vendor, null, null, filterVal, (succ, orders, msg) => {
                setSearching(false)
                if (succ) {
                    // console.log('TOTAL ORDERS = ' + orders.length)
                    if (orders.length > 0) {
                        setOrders(orders)
                    } else {
                        setNoOrdersMessage(msg)
                    }
                } else {
                    cogoToast.error('Could not fetch Orders. An error occured.')
                    // console.log(msg)
                }
            })

        }
    }, [props.isSearchable, props.screen, props.vendor, props.params, filterVal])



    const closeModals = () => {
        if (props.orderSelectUpdate) {
            props.orderSelectUpdate(false)
        }
        setSelectedOrder(null)
    }


    const filterValChanged = (val) => {
        setFilterVal(val)
    }

    const searchKeyChanged = (e) => {
        setSearchVal(null)
        setOrderFilter(OrderFilter.init())
        setOrders([])
        valFieldRef.current.value = null
        setSearchKey(e.target.value)
    }

    const searchValChanged = (e) => {
        setSearchVal(e.target.value)
    }


    const selectOrderPressed = (ord) => {
        setSelectedOrder(ord)
        if (props.orderSelectUpdate) {
            props.orderSelectUpdate(true)
        }
    }

    const orderIsUpdated = (ord) => {
        let ords = [...orders]

        const index = ords.findIndex((or) => {
            return or.objectId === ord.objectId
        })

        if (index != null) {
            ords[index] = ord
        }

        setOrders(ords)
    }


    const orderHasMoved = (ord) => {
        let ords = orders.filter((ordr) => {
            return (ordr.objectId !== ord.objectId)
        })
        setOrders(ords)
        setSelectedOrder(null)
    }


    const startSearchForOrders = (thisFilterVal) => {
        setOrders([])

        if (props.isSearchable) {

            // Via Search
            setSearching(true)


            if (props.screen === screen.Search_Factory) {
                // orders which are at factory/vendor only
                orderFilter.findInFactoryOnly = true
            }

            if (props.screen === screen.Search_Vendor) {
                // orders which are at factory/vendor only
                orderFilter.findInVendorsOnly = true
            }

            Order.getAll(orderFilter, (succ, orders, msg) => {
                setSearching(false)
                if (succ) {
                    // console.log('Searched Order Results')
                    // console.log(orders)
                    if (orders.length > 0) {
                        setOrders(orders)
                    } else {
                        setOrders([])
                        setNoOrdersMessage(msg)
                    }
                } else {
                    setOrders([])
                    cogoToast.error('Could not find Orders. An error occured.')
                    // console.log(msg)
                }
            })

        } else {
            // Via Screen

            setSearching(true)

            let dept = departmentForscreen(props.screen)
            let isVendor = (dept !== departments.Factory)
            let vendor = null
            if (isVendor && props.vendor) {
                vendor = props.vendor
            }

            // cogoToast.info("Searching Orders with Filter Val = " + thisFilterVal)

            Order.getOrders(props.screen, "", vendor, null, null, thisFilterVal, (succ, orders, msg) => {
                setSearching(false)
                if (succ) {
                    setOrders(orders)
                } else {
                    cogoToast.error('Could not fetch Orders. An error occured.')
                    // console.log(msg)
                }
            })
        }
    }




    const startSearching = () => {

        if (searching){
            return
        }


        console.log('START SEARCHING FIRED')

        if (searchVal != null) {
            // search
            if (searchKey === SearchKeys.OrderNo) {
                orderFilter.orderNo = searchVal
            }

            if (searchKey === SearchKeys.POSNo) {
                orderFilter.posNo = searchVal
            }

            if (searchKey === SearchKeys.ClientName) {
                orderFilter.customerName = searchVal
            }

            if (searchKey === SearchKeys.ClientPhone) {
                orderFilter.customerPhone = searchVal
            }

            if (searchKey === SearchKeys.ClientEmail) {
                orderFilter.customerEmail = searchVal
            }

            startSearchForOrders(filterVal)

            // valFieldRef.target.blur()


        } else {
            cogoToast.warn('Enter Value to Search')
            // alert('Enter Value to Search')
            return
        }

        

    }


    let refreshPressed = () => {

        if (searching){
            return
        }
        // filterValRef.target.blur()

        startSearchForOrders(filterVal)

    }

    let clearFilterPressed = () => {
        filterValChanged(null)
        filterValRef.current.value = null
        startSearchForOrders(null)
    }


    let screenFilterForm = () => {
        return (
            <form>

                <div className="is-centered has-text-centered marginBottom10">
                    <Columns className="is-centered">
                        <Columns.Column >
                            <div className='control '>
                                <input ref={filterValRef} name='filterVal' value={filterVal ?? ""} type='input' className={'input has-text-weight-medium type70 ' + (filterVal ? 'is-info' : 'is-light')} placeholder='Filter by OrderNo/POSNo/Client Name' onChange={(e) => { filterValChanged(e.target.value) }} />
                            </div>
                        </Columns.Column>
                        <Columns.Column className="is-narrow">
                            <div className='control is-pulled-right'>

                                <Button.Group >
                                    {
                                        filterVal ?
                                            <Button type="reset" className='type70 is-text has-text-grey' onClick={clearFilterPressed} > Clear </Button>
                                            : null
                                    }

                                    <Button loading={searching} type="submit" disabled={searching} className='type70 is-light ' onClick={refreshPressed} > Refresh </Button>

                                </Button.Group>


                            </div>
                            <br className="marginBottom20 is-hidden-tablet" />
                        </Columns.Column>
                    </Columns>
            </div>
            </form>

        )
    }

    let searchForm = () => {

        return (

            <form>
                <div>

                    <div className="is-centered has-text-centered">
                        <ColumnAux isMiddle={true} size={4}>
                            <Box className="has-background-white-ter">
                                <Heading size={5} className='is-centered  has-text-centered'> Orders where</Heading>
                                <div className="control ">
                                    <span className="select has-text-weight-semibold type70 is-fullwidth">
                                        <select as='select' defaultValue={searchKey} className='select' name='searchKey' onChange={(e) => { searchKeyChanged(e) }}>
                                            <option value={SearchKeys.OrderNo} >Order No</option>
                                            <option value={SearchKeys.POSNo} >POS No</option>
                                            <option value={SearchKeys.ClientPhone} >Client Phone</option>
                                            <option value={SearchKeys.ClientName} >Client Name</option>
                                            <option value={SearchKeys.ClientEmail} >Client Email</option>

                                            {/* <option value={SearchKeys.BookingDate} >Booking Date</option> */}

                                        </select>
                                    </span>
                                </div>
                                <div className='control'>

                                    <div className={(searchKey === SearchKeys.BookingDate) ? ' is-hidden' : ' field'}>
                                        <input ref={valFieldRef} name='searchVal' defaultValue={searchVal ?? null} type='input' className='input has-text-weight-semibold type70 marginTop20' placeholder='Equal to' onChange={(e) => { searchValChanged(e) }} />
                                    </div>
                                </div>
                                {/* <div className='control'>
                                    <div className={(searchKey === SearchKeys.BookingDate) ? ' field' : ' is-hidden'}>
                                        <DatePicker name='searchDate' value={searchDate ?? null} dateFormat="dd/MMM/yyyy" className='type70 datePicker has-text-weight-semibold   input' placeholderText='dd/MMM/yyyy'  adjustDateOnChange={true} onChange={(date) => searchDateChanged(date)} />
                                    </div>
                                </div> */}
                                <div className='control'>
                                    <Button disabled={searching} type="submit" loading={searching} className='type70 is-black is-fullwidth marginTop40' onClick={() => { startSearching() }} > Search </Button>
                                </div>
                            </Box>
                            <br />
                        </ColumnAux>
                    </div>



                    {/* <div className="field has-addons has-addons-centered">
                        <div className="control ">
                            <span className="select has-text-weight-semibold type70">
                                <select as='select' defaultValue={searchKey} className='select' name='searchKey' onChange={(e) => { searchKeyChanged(e) }}>
                                    <option value={SearchKeys.OrderNo} >Order No</option>
                                    <option value={SearchKeys.POSNo} >POS No</option>
                                    <option value={SearchKeys.ClientPhone} >Client Phone</option>
                                    <option value={SearchKeys.ClientName} >Client Name</option>
                                    <option value={SearchKeys.ClientEmail} >Client Email</option>
                                </select>
                            </span>
                        </div>
                        <div className='control'>
                            <div className={(searchKey === SearchKeys.BookingDate) ? ' is-hidden' : ' field'}>
                                <input ref={valFieldRef} name='searchVal' defaultValue={searchVal ?? null} type='input' className='input has-text-weight-semibold type70' placeholder='Equal to' onChange={(e) => { searchValChanged(e) }} />
                            </div>
                        </div>
                        <div className='control'>
                            <Button disabled={searching} loading={searching} className='type70 is-black ' onClick={() => { startSearching() }} > Search </Button>
                        </div>
                    </div> */}


                </div>
                <br />

            </form>
        )
    }


    const ordersView = () => {


        return (
            <div>
                <div>
                    {(props.isSearchable === true) ? searchForm() : null}
                    {(props.headerTitle) ? <div>  <p className='type74 has-text-weight-semibold has-text-grey-light has-text-centered'>{props.headerTitle}</p> <br /> </div> : null}
                </div>
                <ColumnAux size={props.columnAuxSize ? props.columnAuxSize : 12} isMiddle={true}>
                    {(props.isSearchable === true) ? null : screenFilterForm()}
                    <br />

                    {
                        (orders.length > 0) ?
                            <div>
                                {orders.map((ord, index) => {
                                    return <OrderCard key={index} order={ord} selectOrder={selectOrderPressed} />
                                })}
                            </div>


                            :

                            (
                                filterVal ?
                                    <p className=' marginTop60 has-text-centered has-text-grey-light has-text-weight-medium'> No Such Orders </p>
                                    :
                                    (<p className=' marginTop60 has-text-centered has-text-grey-light has-text-weight-medium'> {noOrdersMessage} </p>)
                            )

                    }
                </ColumnAux>


            </div>

        )
    }




    return (
        <div>
            {selectedOrder ? <ProductsOfOrder customer={null} order={selectedOrder} fetchProducts={true} screen={props.screen} orderUpdated={orderIsUpdated} orderHasMoved={orderHasMoved} backPressed={closeModals} /> : ordersView()}
        </div>
    )

}


export default Orders