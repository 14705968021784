import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import SendOrders from './send-orders'
import CompleteOrders from './complete-orders'
import ConfirmOrders from './confirm-orders'
import SearchOrders from './search-orders-factory'
import FactoryVendorWindow from './factoryVendorWindow'

import ColumnAux from '../../containers/ColumnAux'

const Factory = (props) => {

    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        console.log('PATHS ARE - ')
        console.log(links)

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }

    let thisPage = () => {
        switch (lastPath()) {
            case 'sendOrders': return <SendOrders />;
            case 'completeOrders': return <CompleteOrders />;
            case 'confirmOrders': return <ConfirmOrders />;
            case 'search': return <SearchOrders />;
            case 'factoryVendorWindow': return <FactoryVendorWindow />;

            default:
                return <div />;
        }
    }


    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            <ColumnAux size={12} isMiddle={true}> {thisPage()} </ColumnAux> 
        </div>

    )
}

export default Factory