import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Card, Image, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
// import ProductForm from '../../containers/ProductForm'
import { CustomerFilter, DateRange } from '../../utilities/Filters'
import { ClientAge_options_NonFormik, Profession_options_NonFormik, ClientCategory_options_NonFormik, SpendRange_options_NonFormik } from '../../utilities/enums'
import { getUsers, getCurrentLocation, getLocations, getAptLocations } from '../../utilities/Store';

import DatePicker from 'react-datepicker'


const ClientFilterModal = (props) => {

    let [custFilter, setCustFilter] = useState(props.custFilter ?? CustomerFilter.init())

    const resetFilter = () => {
        setCustFilter( CustomerFilter.init() )
    }


    const professionChanged = (e) => {
        console.log('Profession Changed = ' + e.target.value)
        let val = e.target.value
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.profession = val
        setCustFilter(pr)
    }

    const categoryChanged = (e) => {
        console.log('Category Changed = ' + e.target.value)
        let val = e.target.value
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.category = val
        setCustFilter(pr)
    }


    const spendRangeChanged = (e) => {
        console.log('Spend Range Changed = ' + e.target.value)
        let val = e.target.value
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.spendRange = val
        setCustFilter(pr)
    }


    const ageLimitChanged = (e) => {
        console.log('Age Limit Changed  = ' + e.target.value)
        let val = e.target.value
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.age = val
        setCustFilter(pr)
    }

    
    const created_from_Changed = (date) => {
        console.log('Created From Changed = ')
        let pr = CustomerFilter.copyFrom(custFilter)
        let dr = new DateRange(date, pr.createdOn ? (pr.createdOn.to ?? null) : null)
        pr.createdOn = dr
        setCustFilter(pr)
        console.log(date)
    }

    const created_to_Changed = (date) => {
        console.log('Created After Changed = ')
        let pr = CustomerFilter.copyFrom(custFilter)
        let dr = new DateRange(pr.createdOn ? (pr.createdOn.from ?? null) : null, date)
        pr.createdOn = dr
        setCustFilter(pr)
        console.log(date)
    }


    const birthdayMatchChanged = (date) => {
        console.log('Birthday Matching Changed = ')
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.birthdayMatching = date
        setCustFilter(pr)
        console.log(date)
    }


    const occasionMatchChanged = (date) => {
        console.log('Occasion Date Matching Changed = ')
        let pr = CustomerFilter.copyFrom(custFilter)
        pr.occasionMatching = date
        setCustFilter(pr)
        console.log(date)
    }


    const registeredAtChanged = (e) => {
        console.log('Registered At  Changed = ' + e.target.value)

        if (e.target.value == null || e.target.value === '') {
            let pr = CustomerFilter.copyFrom(custFilter)
            pr.registeredAt = null
            setCustFilter(pr)
            return
        }

        let allLocs = getLocations()
        let thisLoc = allLocs.filter((loc) => {
            return loc.objectId === e.target.value
        })

        if (thisLoc != null && thisLoc.length) {
            let loc = thisLoc[0]
            console.log('Registered At changed to ' + loc.name)
            let pr = CustomerFilter.copyFrom(custFilter)
            pr.registeredAt = loc
            setCustFilter(pr)
        }

    }



    const saveFilter = () => {
        custFilter.isActive()
        props.filterSaved(custFilter)
    }


    const fields = () => {

        console.log('RENDERING FIELDS')

        return (
            <div>

                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Profession:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {Profession_options_NonFormik('type', '  ', professionChanged, custFilter.profession)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>

                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Category:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {ClientCategory_options_NonFormik('type', '  ', categoryChanged, custFilter.category)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Spend Range:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {SpendRange_options_NonFormik('type', '  ', spendRangeChanged, custFilter.spendRange)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Age:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {ClientAge_options_NonFormik('type', '  ', ageLimitChanged, custFilter.age)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>


                {
                    getCurrentLocation().isMain ?
                        <Columns className='is-mobile'>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Registered At:</label>
                            </Columns.Column>
                            <Columns.Column>

                                <div className="control ">
                                    <div className='select type65 is-fullwidth'>
                                        <select name='user' className='select ' defaultValue={custFilter.registeredAt ? custFilter.registeredAt.objectId : ''} onChange={ (e) => { registeredAtChanged(e) }}>
                                            {
                                                getAptLocations().map((loc, index) => {
                                                    return <option key={index} value={(loc != null) ? loc.objectId : ''} > {(loc != null) ? loc.name : 'None'} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </Columns.Column>
                        </Columns>
                        :
                        null
                }


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Created After:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='createdAfter' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='After Date' selected={custFilter.createdOn ? (custFilter.createdOn.from ?? null) : null} onChange={date => created_from_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Created Before:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='createdBefore' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='Before Date' selected={custFilter.createdOn ? (custFilter.createdOn.to ?? null) : null} onChange={date => created_to_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>

                <hr />



                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Birthday Matching:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='birthdayMatching' placeholderText='Matching Day/Month' selected={custFilter.birthdayMatching ?? null} onChange={date => birthdayMatchChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Occasion Matching:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='occasionMatching' placeholderText='Matching Day/Month' selected={custFilter.occasionMatching ?? null} onChange={date => occasionMatchChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <br />






                <div className='is-centered has-text-centered marginBottom20'>
                    <Button className=' is-centered is-text has-text-centered' onClick={resetFilter} > Reset </Button>
                </div>
                <br />
                <Button className='is-fullwidth is-black' onClick={saveFilter} > Save </Button>

            </div>
        )

    }



    const modal = () => {
        return (
            <div>

                <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={props.closed} >
                    <Modal.Content className='is-padingless'>
                        <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                            <Heading key='3' size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Search Conditions </Heading>
                            <br />
                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {fields()}
                            </ColumnAux>

                        </Box>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }

    return modal()

}

export default ClientFilterModal