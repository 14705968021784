import Parse from 'parse'
import { dateToNumber, getStringInMiddleOf, provideCompressedFile, onlyOneWhitespaceInWords, dateToTimeString, dateToDateString } from './helper'
import { User, LocationType, Position } from './UserClasses'
import { getLocations, getUsers, getCurrentUser, getCurrentLocation } from './Store'
import * as enums from './enums'

import { OrderFilter, ProductFilter } from './Filters'
import cogoToast from 'cogo-toast'
import { clientCCEmail, clientEmail, clientLogoUrl, clientWebsite, clientEmailFromId, ProductType, usesVendorViaFactory } from '../ClientInfo/clientInfo'
import { createCustomId } from './utils'


const LocationClass = Parse.Object.extend("Location");

const CustomerClass = Parse.Object.extend("Customer");
const VendorClass = Parse.Object.extend("Vendor");
const ProductClass = Parse.Object.extend("Product");
const OrderClass = Parse.Object.extend("Order");
const MeasurementClass = Parse.Object.extend("Measurement");
const MeasurementTemplateClass = Parse.Object.extend("MeasurementTemplate");
const ProductPicClass = Parse.Object.extend("ProductPic");
const CustomerPicClass = Parse.Object.extend("CustomerPic");
const RemarkClass = Parse.Object.extend("Remark");
const StockRemarkClass = Parse.Object.extend("StockRemark");
const StockClass = Parse.Object.extend("Stock");
const StockCategoryClass = Parse.Object.extend("StockCategory");
const ChallanClass = Parse.Object.extend("Challan");
const StyleSelectionClass = Parse.Object.extend("StyleSelection");


var currentLocation = null
var currentUser = null





const isValidDate = (d) => {
    let isValid = false
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
        } else {
            // date is valid
            isValid = true
        }
    } else {
        // not a date
    }
    return isValid
}


Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}







export let sampleCustomer = () => {
    return new Customer(
        null,
        null,
        null,
        null,
        null,
        null,
        null, null,
        null, null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0,
        null,
        null,
        null,
        enums.ClientCategory.None,
        enums.Profession.None,
        enums.SpendRange.None,
        null,
        null,
        null,
        0,
        null,
        null)
}

export class Customer {

    constructor(name, description, state, postal, address, country, phone1, phone2, email1, email2, company, jobTitle, website, birthday, assistantName, assistantPhone, assistantEmail, assistantJobTitle, specialOccName, specialOccDate, profilePicUrl, bodyMeasurementsCount, selectedPostures, customMadePatterns, age, category, profession, spendRange, height, weight, shoeSize, picsCount, objectId, registeredAt, measurementNote, customId) {
        this.name = name
        this.description = description
        this.state = state
        this.postal = postal
        this.address = address
        this.country = country
        this.phone1 = phone1
        this.phone2 = phone2
        this.email1 = email1
        this.email2 = email2
        this.company = company
        this.jobTitle = jobTitle
        this.website = website
        // this.birthday = (isNaN((new Date(birthday)).getMonth())) ? null : new Date(birthday)
        this.birthday = birthday

        this.assistantName = assistantName
        this.assistantPhone = assistantPhone
        this.assistantEmail = assistantEmail
        this.assistantJobTitle = assistantJobTitle
        this.specialOccName = specialOccName
        // this.specialOccDate = (isNaN((new Date(specialOccDate)).getMonth())) ? null : new Date(specialOccDate)
        this.specialOccDate = specialOccDate

        this.profilePicUrl = profilePicUrl
        this.bodyMeasurementsCount = bodyMeasurementsCount
        this.selectedPostures = selectedPostures
        this.customMadePatterns = customMadePatterns
        this.age = age
        this.category = category
        this.profession = profession
        this.spendRange = spendRange
        this.height = height
        this.weight = weight
        this.shoeSize = shoeSize
        this.heightUnit = "Ft"
        this.weightUnit = "Kg"
        this.shoeSizeUnit = "Inch"

        this.picsCount = picsCount
        this.objectId = objectId
        this.registeredAt = registeredAt
        // this.registeredAt = (getLocations() != null ) ? [0]
        this.indexPath = null
        this.username = null
        this.password = null
        this.isOnConcierge = false
        this.conciergeMeasurements = null

        this.measurementNote = measurementNote
        this.customId = customId

        // For temp use
        this.pics = []
        this.measurements = []
    }


    static init = () => {
        let cust = new Customer(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, enums.ClientCategory.None, enums.Profession.None, enums.SpendRange.None, null, null, null, null, null, null, null, null)
        return cust
    }


    static copyFrom = (obj) => {
        let cs = Customer.init()

        cs.name = obj.name
        cs.description = obj.description
        cs.state = obj.state
        cs.postal = obj.postal
        cs.address = obj.address
        cs.country = obj.country
        cs.phone1 = obj.phone1
        cs.phone2 = obj.phone2
        cs.email1 = obj.email1
        cs.email2 = obj.email2
        cs.company = obj.company
        cs.jobTitle = obj.jobTitle
        cs.website = obj.website
        cs.birthday = obj.birthday
        cs.assistantName = obj.assistantName
        cs.assistantPhone = obj.assistantPhone
        cs.assistantEmail = obj.assistantEmail
        cs.assistantJobTitle = obj.assistantJobTitle
        cs.specialOccName = obj.specialOccName
        cs.specialOccDate = obj.specialOccDate
        cs.profilePicUrl = obj.profilePicUrl
        cs.bodyMeasurementsCount = obj.bodyMeasurementsCount
        cs.selectedPostures = obj.selectedPostures
        cs.customMadePatterns = obj.customMadePatterns
        cs.age = obj.age
        cs.category = obj.category
        cs.profession = obj.profession
        cs.spendRange = obj.spendRange
        cs.height = obj.height
        cs.weight = obj.weight
        cs.shoeSize = obj.shoeSize
        cs.heightUnit = obj.heightUnit
        cs.weightUnit = obj.weightUnit
        cs.shoeSizeUnit = obj.shoeSizeUnit
        cs.picsCount = obj.picsCount
        cs.objectId = obj.objectId
        cs.indexPath = obj.indexPath
        cs.isOrderBrief = obj.isOrderBrief
        cs.username = obj.username
        cs.password = obj.password
        cs.isOnConcierge = obj.isOnConcierge
        cs.conciergeMeasurements = obj.conciergeMeasurements
        
        cs.measurementNote = obj.measurementNote

        // For temp use
        cs.pics = obj.pics
        cs.measurements = obj.measurements
        cs.customId = obj.customId

        return cs

    }



    static csvColumns () {
        const columns = [
          {
            id: 'cid',
            displayName: 'Id'
          }, 
          {
            id: 'name',
            displayName: 'Name'
          },
          {
            id: 'country',
            displayName: 'Country'
          },
          {
            id: 'phone',
            displayName: 'Phone'
          },
          {
            id: 'email',
            displayName: 'Email'
          },
          {
            id: 'address',
            displayName: 'Address'
          },
          {
            id: 'profession',
            displayName: 'Profession'
          },
          {
            id: 'birth',
            displayName: 'Birth'
          },
          {
            id: 'specialOcc',
            displayName: 'Special Occ.'
          },
          {
            id: 'category',
            displayName: 'Category'
          },
          {
            id: 'spendRange',
            displayName: 'SpendRange'
          },
          {
            id: 'height',
            displayName: 'Height'
          },
          {
            id: 'weight',
            displayName: 'Weight'
          },
          {
            id: 'shoeSize',
            displayName: 'ShoeSize'
          },
        ];

        return columns
    }



    csvData() {
        return {
            cid: this.customId ? this.customId : "",
            name: this.name ? this.name : "" ,
            country: this.country ? this.country : "",
            phone: this.phone1 ? this.phone1 : "",
            email: this.email1 ? this.email1 : "",
            address: this.address ? this.address : "",
            profession: this.company ? (this.profession ? this.profession + `(company - ${this.company}` : `company - ${this.company}`) : " ",            
            birth: this.birthday ? (this.age ? dateToDateString(this.birthday) + `(Age ${this.age}` : dateToDateString(this.birthday)) : " ",   
            specialOcc: this.specialOccDate ? (this.specialOccName ? dateToDateString(this.specialOccDate) + `(${this.specialOccName}` : dateToDateString(this.specialOccDate)) : " ",           
            category: this.category ? this.category : "",
            spendRange: this.spendRange ? this.spendRange : "",
            height: this.height ? `${this.height} ${this.heightUnit ? this.heightUnit : ""}` : "",
            weight: this.weight ? `${this.weight} ${this.weightUnit ? this.weightUnit : ""}` : "",
            shoeSize: this.shoeSize ? `${this.shoeSize} ${this.shoeSizeUnit ? this.shoeSizeUnit : ""}` : "",
        }
    }


    static getCompleteInfo(clientObjId, callback) {
        var query = new Parse.Query(CustomerClass);
        query.get(clientObjId)
            .then((obj) => {
                if (obj !== null) {
                    let customer = Customer.initFromPFObject(obj)
                    if (customer !== null) {
                        callback(true, customer, '')
                    } else {
                        callback(false, null, "Could not convert complete info")
                    }
                } else {
                    callback(false, null, "No Complete Info for this customer found")
                }
            }, (error) => {
                callback(false, null, 'Error getting complete info of Customer with ObjectId =' + error.message)
            });
    }


    add(callback) {
        let ob = new CustomerClass()
        ob.set("username", this.username)
        ob.set("password", this.password)
        ob.set("isOnConcierge", this.isOnConcierge)
        ob.set("conciergeMeasurements", this.conciergeMeasurements)

        ob.set("name", onlyOneWhitespaceInWords(this.name).toUpperCase())
        if (this.description !== null) { ob.set("description", this.description) }
        if (this.state !== null) { ob.set("state", this.state) }
        if (this.postal !== null) { ob.set("postal", this.postal) }
        if (this.address !== null) { ob.set("address", this.address) }
        if (this.country !== null) { ob.set("country", this.country) }
        if (this.phone1 !== null) { ob.set("phone1", this.phone1) }
        if (this.phone2 !== null) { ob.set("phone2", this.phone2) }
        if (this.email1 !== null) { ob.set("email1", this.email1) }
        if (this.email2 !== null) { ob.set("email2", this.email2) }
        if (this.company !== null) { ob.set("company", this.company) }
        if (this.jobTitle !== null) { ob.set("jobTitle", this.jobTitle) }
        if (this.website !== null) { ob.set("website", this.website) }

        ob.set("selectedPostures", this.selectedPostures)
        ob.set("customMadePatterns", this.customMadePatterns)
        ob.set("age", this.age)
        ob.set("category", this.category)
        ob.set("spendRange", this.spendRange)
        ob.set("profession", this.profession)
        if (this.height !== null) { ob.set("height", this.height) }
        if (this.weight !== null) { ob.set("weight", this.weight) }
        if (this.shoeSize !== null) { ob.set("shoeSize", this.shoeSize) }

        if (this.heightUnit !== null) { ob.set("heightUnit", this.heightUnit) }
        if (this.weightUnit !== null) { ob.set("weightUnit", this.weightUnit) }
        if (this.shoeSizeUnit !== null) { ob.set("shoeSizeUnit", this.shoeSizeUnit) }


        if (isValidDate(this.birthday)) {
            console.log('BIRTHDAY VAL - ' + this.birthday)
            ob.set("birthday", this.birthday)
            ob.set("dobNumber", dateToNumber(this.birthday))
        }

        if (isValidDate(this.specialOccDate)) {
            console.log('SPCL VAL - ' + this.specialOccDate)

            ob.set("specialOccDate", this.specialOccDate)
            ob.set("ocNumber", dateToNumber(this.specialOccDate))
        }


        if (this.assistantName !== null) { ob.set("assistantName", this.assistantName) }
        if (this.assistantPhone !== null) { ob.set("assistantPhone", this.assistantPhone) }
        if (this.assistantEmail !== null) { ob.set("assistantEmail", this.assistantEmail) }
        if (this.assistantJobTitle !== null) { ob.set("assistantJobTitle", this.assistantJobTitle) }
        if (this.specialOccName !== null) { ob.set("specialOccName", this.specialOccName) }
        ob.set("bodyMeasurementsCount", this.bodyMeasurementsCount)
        ob.set("picsCount", this.picsCount)

        // let locs = getLocations()
        // let thisLoc = locs[0]

        // this.registeredAt = thisLoc





        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        let addedByPointer = {
            __type: 'Pointer',
            className: 'Users',
            objectId: cu.objectId
        }
        ob.set('addedByUser', addedByPointer)

        let registeredAtPointer = {
            __type: 'Pointer',
            className: 'Location',
            objectId: cl.objectId
        }
        ob.set("registeredAt", registeredAtPointer)




        if (this.specialOccDate !== null) { ob.set("specialOccDate", this.specialOccDate) }

        if (this.profilePicUrl !== null) { ob.set("profilePicUrl", this.profilePicUrl) }

        ob.set("measurementNote", this.measurementNote ?? "")
        // if (this.customId !== null){ ob.set("customId", this.customId) }
        ob.set("customId", createCustomId())

        ob.save()
            .then((customer) => {
                this.objectId = customer.id
                console.log('New Customer Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new customer, with error code: ' + error.message);
                callback(false, error.message)
            });

    }


    update(callback) {

        if (this.objectId == null) {
            callback(false, "Error : No object id to update Customer")
            return
        }

        var query = new Parse.Query(CustomerClass);
        query.get(this.objectId)
            .then((ob) => {
                // The object was retrieved successfully.
                ob.set("name", onlyOneWhitespaceInWords(this.name).toUpperCase())
                ob.set("username", this.username)
                ob.set("password", this.password)
                ob.set("isOnConcierge", this.isOnConcierge)
                ob.set("conciergeMeasurements", [this.conciergeMeasurements])
                if (this.description !== null) { ob.set("description", this.description) }
                if (this.state !== null) { ob.set("state", this.state) }
                if (this.postal !== null) { ob.set("postal", this.postal) }
                if (this.address !== null) { ob.set("address", this.address) }
                if (this.country !== null) { ob.set("country", this.country) }
                if (this.phone1 !== null) { ob.set("phone1", this.phone1) }
                if (this.phone2 !== null) { ob.set("phone2", this.phone2) }
                if (this.email1 !== null) { ob.set("email1", this.email1) }
                if (this.email2 !== null) { ob.set("email2", this.email2) }
                if (this.company !== null) { ob.set("company", this.company) }
                if (this.jobTitle !== null) { ob.set("jobTitle", this.jobTitle) }
                if (this.website !== null) { ob.set("website", this.website) }

                ob.set("selectedPostures", this.selectedPostures)
                ob.set("customMadePatterns", this.customMadePatterns)
                ob.set("age", this.age)
                ob.set("category", this.category)
                ob.set("spendRange", this.spendRange)
                ob.set("profession", this.profession)
                if (this.height !== null) { ob.set("height", this.height) }
                if (this.weight !== null) { ob.set("weight", this.weight) }
                if (this.shoeSize !== null) { ob.set("shoeSize", this.shoeSize) }

                if (this.heightUnit !== null) { ob.set("heightUnit", this.heightUnit) }
                if (this.weightUnit !== null) { ob.set("weightUnit", this.weightUnit) }
                if (this.shoeSizeUnit !== null) { ob.set("shoeSizeUnit", this.shoeSizeUnit) }


                if (this.birthday != null && this.birthday instanceof Date) {
                    console.log('BIRTHDAY VAL - ' + this.birthday)
                    ob.set("birthday", this.birthday)
                    ob.set("dobNumber", dateToNumber(this.birthday))
                }

                if (this.specialOccDate != null && this.specialOccDate instanceof Date) {
                    console.log('SPCL VAL - ' + this.specialOccDate)

                    ob.set("specialOccDate", this.specialOccDate)
                    ob.set("ocNumber", dateToNumber(this.specialOccDate))
                }


                if (this.assistantName !== null) { ob.set("assistantName", this.assistantName) }
                if (this.assistantPhone !== null) { ob.set("assistantPhone", this.assistantPhone) }
                if (this.assistantEmail !== null) { ob.set("assistantEmail", this.assistantEmail) }
                if (this.assistantJobTitle !== null) { ob.set("assistantJobTitle", this.assistantJobTitle) }
                if (this.specialOccName !== null) { ob.set("specialOccName", this.specialOccName) }
                ob.set("bodyMeasurementsCount", this.bodyMeasurementsCount)
                ob.set("picsCount", this.picsCount)

                // let locs = getLocations()
                // let thisLoc = locs[0]

                // this.registeredAt = thisLoc

                // ob.set("registeredAt" ,  this.registeredAt.toPFObject())


                if (this.specialOccDate !== null) { ob.set("specialOccDate", this.specialOccDate) }

                if (this.profilePicUrl !== null) { ob.set("profilePicUrl", this.profilePicUrl) }

                ob.set("measurementNote", this.measurementNote ?? "")

                // Not changed once made
                // if (this.customId !== null){ ob.set("customId", this.customId) }



                ob.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }


    updateImagesCountInOrders(callback) {

        let ob = new CustomerClass()
        ob.increment()
        ob.id = this.objectId
        var query = new Parse.Query(OrderClass);
        query.equalTo('customer', ob)

        query.find().then((objects) => {

            if (objects.length > 0) {

                for (let i = 0; i < objects.length; i++) {
                    const thisOrd = objects[i];

                    thisOrd.increment('customerPicsCount', 1)
                    thisOrd.save()

                }

                callback(true, 'Orders image count updated')


            } else {
                callback(false, 'No Orders found for user')
            }

        }, (error) => {
            console.log('Error while updating image count in orders of customer = ', error.message)
            callback(false, error.message)

        })

    }

    updateBodyMeasurementsCountInOrders(callback) {

        let ob = new CustomerClass()
        ob.increment()
        ob.id = this.objectId
        var query = new Parse.Query(OrderClass);
        query.equalTo('customer', ob)

        query.find().then((objects) => {
            console.log(objects)

            if (objects.length > 0) {

                for (let i = 0; i < objects.length; i++) {
                    const thisOrd = objects[i];

                    thisOrd.increment('bodyMeasurementsCount', 1)
                    thisOrd.save()

                }

                callback(true, 'Orders CustomerMeasurement count updated')


            } else {
                callback(false, 'No Orders found for customer')
            }

        }, (error) => {
            console.log('Error while updating CustomerMeasurement count in orders of customer = ', error.message)
            callback(false, error.message)

        })

    }

    //
    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Customer ")
        }

        let thisObj = new CustomerClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting customer ', error.message)
            callback(false, error.message)
        });

    }

    static initFromPFObject(obj) {

        console.log(`Customer Object is : ${obj} `)

        var posturesArray = []
        let pos = obj.get('selectedPostures')
        if (pos != null) {
            pos.map((ps) => {
                posturesArray.push(ps)
                return null
            })
        }

        var customPattern = []

        let cmp = obj.get('customMadePatterns')
        if (cmp != null) {
            cmp.map((cm) => {
                customPattern.push(cm)
                return null
            })
        }

        let registeredAtObj = obj.get('registeredAt')

        // TODO : CONVERT TO LOCATION OBJECT

        let registeredAt = null
        if (registeredAtObj != null) {
            let registeredAts = getLocations().filter((itm) => {
                return itm.objectId === registeredAtObj.id
            })

            registeredAt = registeredAts[0]
        }




        //        var category : ClientCategory
        //        var profession : Profession
        //        var spendRange : SpendRange
        //        var weight : Double?
        //        var shoeSize : Double?

        //        selectedPostures: [Posture], customMadePatterns: [String], category: ClientCategory, profession: Profession, spendRange: SpendRange, weight: Double?, shoeSize: Double?,


        var heightStr = obj.get('height')

        let proPicUrl = obj.get('profilePicUrl')


        let cust = new Customer(
            obj.get('name'),
            obj.get('description'),
            obj.get('state'),
            obj.get('postal'),
            obj.get('address'),
            obj.get('country'),
            obj.get('phone1'),
            obj.get('phone2'),
            obj.get('email1'),
            obj.get('email2'),
            obj.get('company'),
            obj.get('jobTitle'),
            obj.get('website'),
            obj.get('birthday'),
            obj.get('assistantName'),
            obj.get('assistantPhone'),
            obj.get('assistantEmail'),
            obj.get('assistantJobTitle'),
            obj.get('specialOccName'),
            obj.get('specialOccDate'),
            proPicUrl,
            obj.get('bodyMeasurementsCount'),
            posturesArray,
            customPattern,
            obj.get('age'),
            obj.get('category'),
            obj.get('profession'),
            obj.get('spendRange'),
            heightStr,
            obj.get('weight'),
            obj.get('shoeSize'),
            obj.get('picsCount'),
            obj.id,
            registeredAt,
            null,
            false
        )

        if (obj.get('heightUnit') != null) {
            cust.heightUnit = obj.get('heightUnit')
        }
        if (obj.get('weightUnit') != null) {
            cust.weightUnit = obj.get('weightUnit')
        }
        if (obj.get('shoeSizeUnit') != null) {
            cust.shoeSizeUnit = obj.get('shoeSizeUnit')
        }

        if (obj.get('username') != null) {
            cust.username = obj.get('username')
        }

        if (obj.get('password') != null) {
            cust.password = obj.get('password')
        }

        if (obj.get('isOnConcierge') != null) {
            cust.isOnConcierge = obj.get('isOnConcierge')
        }

        if (obj.get('measurementNote') != null) {
            cust.measurementNote = obj.get('measurementNote')
        }

        if (obj.get('customId') != null) {
            cust.customId = obj.get('customId')

        }

        if (obj.get('conciergeMeasurements') != null) {
            if (obj.get('conciergeMeasurements').length) {
                if (obj.get('conciergeMeasurements')[0] !== null) {
                    cust.conciergeMeasurements = obj.get('conciergeMeasurements')
                    console.log("+++++++++++++++  conciergeMeasurements ARE")
                    console.log(cust.conciergeMeasurements)

                    let cm1 = cust.conciergeMeasurements[0]
                    // console.log("+++++++++++++++  CM1 MEASURES ARE")
                    // console.log(cm1)
                    if (cm1.measures !== null) {
                        let cmMeasures = cm1.measures ?? []
                        let measures = []

                        cmMeasures.map((thisM) => {

                            let m = thisM
                            // console.log("THIS M IS") {
                            // }

                            if (typeof thisM === "string"){
                                m = Measure.initFromString(thisM)
                            }

                            let name = m.name ?? ""
                            let unit = m.unit ?? ""
                            let defaultValueStr = m.defaultValue
                            let defaultValue = Number(defaultValueStr).toFixed(2)

                            let valueStr = m.value
                            let value = Number(valueStr).toFixed(2)

                            let thisMeasure = new Measure(name, unit, defaultValue, value)

                            measures.push(thisMeasure)
                        })

                        let thisMM = Measurement.init(enums.MeasurementType.conciergeBody)
                        thisMM.name = "Concierge Customer Current Measurements"
                        thisMM.measures = measures
                        thisMM.isConciergeMeasurement = true

                        console.log("+++++++++++++++ CONEVRTED conciergeMeasurements ARE")
                        console.log(thisMM)

                        cust.conciergeMeasurements = thisMM
                    }
                }
            }
        }



        return cust
    }


    static getAll(filter, callback) {


        console.log('Searching For Customers ')

        var query = new Parse.Query(CustomerClass);
        query.descending('createdAt')
        query.limit(40)
        // query.includeKey('selectedPostures')
        // query.includeKey('customMadePatterns')


        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        console.log('CURRENT USER IS ')
        console.log(cu)

        console.log('CURRENT LOCATION IS')
        console.log(cl)



        // Filter for Agent
        if (cu.isAgent === true) {
            filter.addedByUser = cu
            // let addedByPointer = {
            //     __type: 'Pointer',
            //     className: 'Users',
            //     objectId: cu.objectId
            // }
            // orderQuery.equalTo('addedByUser', addedByPointer)
        }

        // Filter for location
        if (cl.isFranchise === true || cl.type === LocationType.franchise) {
            filter.registeredAt = cl
            // let orderedAtPointer = {
            //     __type: 'Pointer',
            //     className: 'Location',
            //     objectId: cl.objectId
            // }
            // orderQuery.equalTo("orderedAt", orderedAtPointer)
        }



        console.log('CHECKING FILTER')
        if (filter.isActive()) {
            console.log('FILTER IS ACTIVE')

            if (filter.registeredAt) {

                console.log("\n\n\n R E G I S T ERED AT ")
                console.log(filter.registeredAt)


                let registeredAtPointer = {
                    __type: 'Pointer',
                    className: 'Location',
                    objectId: filter.registeredAt.objectId
                }
                query.equalTo('registeredAt', registeredAtPointer)
            }

            if (filter.addedByUser) {
                let addedByPointer = {
                    __type: 'Pointer',
                    className: 'Users',
                    objectId: filter.addedByUser.objectId
                }
                query.equalTo('addedByUser', addedByPointer)
            }



            // last Order At
            if (filter.lastOrderedAfter != null) {
                //                console.log("\n\nADDING LAST ORDER ON CONDITION = \(f_lastOrdered.toString(format: dateFormat))")
                var orderQuery = new Parse.Query(OrderClass);


                orderQuery.greaterThanOrEqualTo('createdAt', filter.lastOrderedAfter)

                query.matchesKeyInQuery('phone1', 'customerPhone', orderQuery)

            }


            if (filter.name != null) {
                if (filter.name.length > 0) {
                    query.contains('name', filter.name.toUpperCase())
                }
            }

            if (filter.customId != null) {
                if (filter.customId.length > 0) {
                    query.contains('customId', filter.customId.toUpperCase())
                }
            }

            if (filter.phone != null) {
                console.log('FILTER PHONE IS NOT NULL')

                if (filter.phone.length > 0) {
                    query.contains('phone1', filter.phone)
                }
            }

            if (filter.email != null) {
                if (filter.email.length > 0) {
                    query.equalTo('email1', filter.email.toLowerCase())
                }
            }

            if (filter.country != null) {
                if (filter.country.length > 0) {
                    query.equalTo('country', filter.country.toUpperCase())
                }
            }

            if (filter.age != null) {
                query.equalTo('age', filter.age)
            }


            if (filter.category != null) {
                query.equalTo('category', filter.category)
            }

            if (filter.profession != null) {
                query.equalTo('profession', filter.profession)
            }

            if (filter.spendRange != null) {
                query.equalTo('spendRange', filter.spendRange)
            }

            // Created ON
            if (filter.createdOn != null) {
                if (filter.createdOn.from != null) {
                    query.greaterThanOrEqualTo('createdAt', filter.createdOn.from)
                }

                if (filter.createdOn.to != null) {
                    query.lessThanOrEqualTo('createdAt', filter.createdOn.to)
                }
            }


            if (filter.birthdayMatching != null) {
                let dobNumber = dateToNumber(filter.birthdayMatching)
                console.log(' SEARCHING FOR CUSTOMER WITH BDAY MATCHING = ' + dobNumber)
                if (dobNumber !== null) {
                    query.equalTo('dobNumber', dobNumber)
                }
            }

            if (filter.occasionMatching != null) {
                let ocNumber = dateToNumber(filter.occasionMatching)
                if (ocNumber !== null) {
                    query.equalTo('ocNumber', ocNumber)
                }
            }


            // BirthDay
            if (filter.birthday != null) {
                if (filter.birthday.from != null) {
                    query.greaterThanOrEqualTo('birthday', filter.birthday.from)
                }

                if (filter.birthday.to != null) {
                    query.lessThanOrEqualTo('birthday', filter.birthday.to)
                }
            }



            // Special Occasion
            if (filter.specialOccasion) {
                if (filter.specialOccasion.from != null) {
                    query.greaterThanOrEqualTo('specialOccDate', filter.specialOccasion.from)
                }

                if (filter.specialOccasion.to != null) {
                    query.lessThanOrEqualTo('specialOccDate', filter.specialOccasion.to)
                }
            }


        } else {
            console.log('FILTER IS NOT ACTIVE')
        }



        console.log("\n\n\n  F I N A L  Query is ")
        console.log(query)

        query.find().then((objects) => {
            console.log('All Customers =')
            console.log(objects)

            if (objects.length > 0) {

                let allCustomers = []
                for (let i = 0; i < objects.length; i++) {
                    const thisCustObj = objects[i];
                    let thisCust = Customer.initFromPFObject(thisCustObj)
                    if (thisCust !== null) {
                        allCustomers.push(thisCust)
                    }
                }
                callback(true, allCustomers, '')

            } else {
                callback(true, [], 'No Customers Found')
            }

        }, (error) => {
            callback(false, [], error.message)
        })


    }



    uploadProfileImage(file, callback) {


        // let image = this.image 
        if (file == null) {
            callback(false, 'No Pic File to add.')
        }


        provideCompressedFile(file, 200, 0.3, (succ, file) => {

            if (succ) {
                console.log('UPLOADING COMPRESSED FILE')
            } else {
                cogoToast.warn('This image could not be compressed. Upload will take time.')
                console.log('UPLOADING ORGINAL FILE')
            }

            var name = `customerProfileImage${this.name ?? 'NoCustName'}.png`

            var parseFile = new Parse.File(name, file, "image/png");


            parseFile.save()
                .then((file) => {
                    // The file has been saved to Parse.


                    let fileUrl = file.url()
                    if (fileUrl != null) {
                        console.log("Uploaded file url = " + file)

                        this.profilePicUrl = fileUrl

                        this.update((succ, errMsg) => {
                            callback(succ, this.profilePicUrl, 'Error after uploading ProfilePic file : ' + errMsg)
                        })


                    } else {
                        console.log("Could not get customer profile pic file url.")
                        callback(false, null, 'Could not get customer profile pic file url.')

                    }

                }, (error) => {
                    // The file either could not be read, or could not be saved to Parse.
                    callback(false, null, "Error while uploading CustomerPic file" + error.message)

                });

        })





        // if this.objectId == null {
        //     completion(false, "Customer image cannot be added as the customer is not saved yet.")
        //     return
        // }

        // let ob = this.toPFObject()

        //  guard let image = this.image else{
        //      completion(false, "No Customer image to add.")
        //      return
        //  }

        //  if let data = image.jpegData(compressionQuality: 0.5) {
        //     //TODO:- Remove spaces from customer name in file-name
        //      let file = PFFileObject.init(name: "customerProfileImage-\(name)-\(randomString(length: 4)).jpg", data:data)

        //      file?.saveInBackground(block: { (succ1, err1) in
        //          if succ1 {
        //              if let url = file?.url {
        //                 this.profilePicUrl = url

        //                 ob["profilePicUrl"] = url

        //                 ob.saveInBackground { (succeeded, errMsg2)  in
        //                     if (succeeded) {
        //                         // The object has been saved.
        //                         completion(true,"")
        //                     } else {
        //                         // There was a problem, check error.message
        //                         let err = "Error While updating Customer with its ProfilePicUrl : " + errMsg2!.localizedDescription
        //                         console.log(err)
        //                         completion(false, err)
        //                     }


        //                 }

        //              }else{
        //                 let err = "Error : Could not get Customers Profile Pic file url. - " + err1!.localizedDescription
        //                 console.log(err)
        //                 completion(false, err)
        //              }
        //          }else{
        //              completion(false,"Error while updating Customer Profile Pic image file" + err1!.localizedDescription)
        //          }
        //      })
        //  }
    }



    toPFObject() {
        let thisObj = new CustomerClass()
        thisObj.id = this.objectId
        return thisObj
    }



}


export const canAddOrderAndProducts = (screen) => {

    var canAdd = false
    switch (screen) {
        case enums.screen.NewOrder: canAdd = true; break;
        case enums.screen.RecentOrders: canAdd = true; break;
        case enums.screen.Search_Store: canAdd = true; break;
        case enums.screen.AllClients: canAdd = true; break;
        case enums.screen.Search_Clients: canAdd = true; break;
        default: break;
    }
    return canAdd
}



export const productListTitleForScreen = (screen, isVendor = false) => {
    var stage = 'All Products'
    switch (screen) {
        case enums.screen.NewOrder: break
        case enums.screen.ProcessOrder: break                                              // its a parent view
        case enums.screen.PendingOrders_Store: break                                       // pending orders is just to view orders/products

        case enums.screen.SendOrders_Store: stage = isVendor ? 'Products that need to be sent to Vendor' : ' Products that need to be sent to Factory'; break;
        case enums.screen.SendOrders_Store_Factory: stage = 'Products that need to be sent to Factory'; break;
        case enums.screen.SendOrders_Store_Vendor: stage = 'Products that need to be sent to Vendor'; break;

        case enums.screen.OrdersRecieved_Factory: stage = 'Products sent from store that need to be marked Accepted'; break;   // Should Rename to Accept Orders
        case enums.screen.CompleteOrders_Factory: stage = 'Accepted Products which need to be marked as complete'; break;                                   // to decide
        case enums.screen.SendOrders_Factory: stage = 'Complete Products which need to be sent to Store'; break;

        case enums.screen.Vendors: stage = 'All products with vendor'; break;
        case enums.screen.OrdersRecieved_Vendor: stage = 'Products sent from store that need to be marked Accepted'; break;
        case enums.screen.CompleteOrders_Vendor: stage = 'Accepted Products which need to be marked as complete'; break;                                   // to decide
        case enums.screen.SendOrders_Vendor: stage = 'Complete Products which need to be sent to Store'; break;


        case enums.screen.RecieveOrders_Store: stage = isVendor ? 'Products that need to be marked Recieved From Vendor' : 'Products that need to be marked Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Factory: stage = 'Products that need to be marked Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Vendor: stage = 'Products that need to be marked Recieved From Vendor'; break;


        case enums.screen.ScanSendToFactory: stage = 'Send Scanned Products To Factory'; break;
        case enums.screen.ScanRecieveFromFactory: stage = 'Scan Products and mark Recieved From Factory'; break;
        case enums.screen.ScanSendToStore: stage = 'Mark Scanned Products as Sent To Factory'; break;
        case enums.screen.ScanRecieveFromStore: stage = 'Scan and mark Products As Recieved'; break;
        case enums.screen.CustomerDealing: break                                           // its a parent view
        case enums.screen.ApproveTrial: stage = 'Products that need to be approved as Tried'; break;
        case enums.screen.ApproveFinishing: stage = 'Products that are in finishing'; break;
        case enums.screen.ApproveDelivery: stage = 'Products that can be delivered'; break;
        case enums.screen.ViewStatus: break
        case enums.screen.Search_Store: break
        case enums.screen.Search_Factory: break
        default: break
    }
    return stage
}



export const nextStageForScreen_Title = (screen, isVendor = false, explicitStage) => {
    var stage = null
    switch (screen) {
        case enums.screen.NewOrder: break
        case enums.screen.ProcessOrder: break                                              // its a parent view
        case enums.screen.PendingOrders_Store: break                                       // pending orders is just to view orders/products

        case enums.screen.SendOrders_Store: stage = isVendor ? 'Send To Vendor' : 'Send To Factory'; break;
        case enums.screen.SendOrders_Store_Factory: stage = 'Send To Factory'; break;
        case enums.screen.SendOrders_Store_Vendor: stage = 'Send To Vendor'; break;
        case enums.screen.Factory: stage = 'Send To Vendor'; break;


        case enums.screen.OrdersRecieved_Factory: 
        if (explicitStage) {
            // When client usesVendorViaFactory. For Accepting orders to be sent to vendors
             if (explicitStage === enums.Stage.FactoryRecievedForVendor) { stage = "Accept for Vendor" } 
        }
        else{
            // Normal case
            stage = 'Accept' 
            if (usesVendorViaFactory){
                stage = 'Accept for Factory' 
            }
        }; break;   // Should Rename to Accept Orders
        

        case enums.screen.CompleteOrders_Factory: stage = 'Mark As Complete'; break;                                   // to decide
        case enums.screen.SendOrders_Factory: stage = 'Send to Store'; break;
        case enums.screen.SendOrders_FactoryVendorWindow: stage = "Send To Vendor "; break;
        case enums.screen.RecieveOrders_FactoryVendorWindow: stage = "Confirm Recieved From Vendor"; break; // EXPECTIONAL CASE - when usesVendorViaFactory -- when accepted from vendor, product is assued factory complete, can be changed to factoryRecieved also, if the product might need more changes at factory -- added on 2 Aug 2021 for 

        case enums.screen.OrdersRecieved_Vendor: stage = 'Accept'; break;
        case enums.screen.CompleteOrders_Vendor: stage = 'Mark As Complete'; break;                                   // to decide
        case enums.screen.SendOrders_Vendor:  stage = 'Send to Store'; if (usesVendorViaFactory) { stage = 'Send to Factory' } ; break;


        case enums.screen.RecieveOrders_Store: stage = isVendor ? 'Confirm Recieved From Vendor' : 'Confirm Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Factory: stage = 'Confirm Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Vendor: stage = 'Confirm Recieved From Vendor'; break;


        case enums.screen.ScanSendToFactory: stage = 'Send Scanned To Factory'; break;
        case enums.screen.ScanRecieveFromFactory: stage = 'Confirm Recieved From Factory'; break;
        case enums.screen.ScanSendToStore: stage = 'Send Scanned To Factory'; break;
        case enums.screen.ScanRecieveFromStore: stage = 'Confirm As Recieved'; break;
        case enums.screen.CustomerDealing: break                                           // its a parent view
        case enums.screen.ApproveTrial: stage = 'Approve Trial'; break;
        case enums.screen.ApproveFinishing: stage = 'Approve as Finished'; break;
        case enums.screen.ApproveDelivery: stage = 'Mark Deliverd'; break;
        case enums.screen.ViewStatus: break
        case enums.screen.Search_Store: break
        case enums.screen.Search_Factory: break
        default: break
    }
    return stage
}


export const nextStageCompletion_Title = (screen, isVendor = false, explicitStage) => {
    var title = null
    switch (screen) {
        case enums.screen.NewOrder: break
        case enums.screen.ProcessOrder: break                                              // its a parent view
        case enums.screen.PendingOrders_Store: break                                       // pending orders is just to view orders/products

        case enums.screen.SendOrders_Store: title = isVendor ? 'Sent To Vendor' : 'Sent To Factory'; break;
        case enums.screen.SendOrders_Store_Factory: title = 'Sent To Factory'; break;
        case enums.screen.SendOrders_Store_Vendor: title = 'Sent To Vendor'; break;

        case enums.screen.OrdersRecieved_Factory: 
        if (explicitStage) {
            // When client usesVendorViaFactory. For Accepting orders to be sent to vendors
             if (explicitStage === enums.Stage.FactoryRecievedForVendor) { title = "Accepted for Vendor" } 
        }
        else{
            // Normal case
            title = 'Accepted' 
        }; break;   // Should Rename to Accept Orders
        
        case enums.screen.CompleteOrders_Factory: title = 'Marked As Complete'; break;                                   // to decide
        case enums.screen.SendOrders_Factory: title = 'Sent to Store'; break;
        case enums.screen.SendOrders_FactoryVendorWindow: title = "Sent To Vendor "; break;
        case enums.screen.RecieveOrders_FactoryVendorWindow: title = "Recieved From Vendor"; break; // EXPECTIONAL CASE - when usesVendorViaFactory -- when accepted from vendor, product is assued factory complete, can be changed to factoryRecieved also, if the product might need more changes at factory -- added on 2 Aug 2021 for 

        case enums.screen.OrdersRecieved_Vendor: title = 'Accepted'; break;
        case enums.screen.CompleteOrders_Vendor: title = 'Marked As Complete'; break;                                   // to decide
        case enums.screen.SendOrders_Vendor: title = 'Sent to Store'; break;


        case enums.screen.RecieveOrders_Store: title = isVendor ? 'Recieved From Vendor' : 'Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Factory: title = 'Recieved From Factory'; break;
        case enums.screen.RecieveOrders_Store_Vendor: title = 'Recieved From Vendor'; break;


        case enums.screen.ScanSendToFactory: title = 'Sent To Factory'; break;
        case enums.screen.ScanRecieveFromFactory: title = 'Recieved From Factory'; break;
        case enums.screen.ScanSendToStore: title = 'Sent To Factory'; break;
        case enums.screen.ScanRecieveFromStore: title = 'Recieved'; break;
        case enums.screen.CustomerDealing: break                                           // its a parent view
        case enums.screen.ApproveTrial: title = 'Approved Trial'; break;
        case enums.screen.ApproveFinishing: title = 'Approved as Finished'; break;
        case enums.screen.ApproveDelivery: title = 'Marked Deliverd'; break;
        case enums.screen.ViewStatus: break
        case enums.screen.Search_Store: break
        case enums.screen.Search_Factory: break
        default: break
    }
    return title
}


export const nextStageForScreen = (screen, isVendor = false) => {
    var stage = null
    switch (screen) {
        case enums.screen.NewOrder: break
        case enums.screen.ProcessOrder: break                                              // its a parent view
        case enums.screen.PendingOrders_Store: break                                       // pending orders is just to view orders/products

        case enums.screen.SendOrders_Store: stage = isVendor ? enums.Stage.SentToVendor : enums.Stage.SentToFactory; break;
        case enums.screen.SendOrders_Store_Factory: stage = enums.Stage.SentToFactory; break;
        case enums.screen.SendOrders_Store_Vendor: stage = enums.Stage.SentToVendor; break;

        case enums.screen.OrdersRecieved_Factory: stage = enums.Stage.FactoryRecieved; break; // is optional with 2 buttons if brand usesVendorViaFactory .. regular.. or SentToVendor   
        case enums.screen.CompleteOrders_Factory: stage = enums.Stage.FactoryComplete; break;                                   // to decide
        case enums.screen.SendOrders_Factory: stage = enums.Stage.FactorySent; break;

        case enums.screen.SendOrders_FactoryVendorWindow: stage = enums.Stage.SentToVendor; break;
        case enums.screen.RecieveOrders_FactoryVendorWindow: stage = enums.Stage.FactoryComplete; break; // EXPECTIONAL CASE - when usesVendorViaFactory -- when accepted from vendor, product is assued factory complete, can be changed to factoryRecieved also, if the product might need more changes at factory -- added on 2 Aug 2021 for 

        case enums.screen.OrdersRecieved_Vendor: stage = enums.Stage.VendorRecieved; break;
        case enums.screen.CompleteOrders_Vendor: stage = enums.Stage.VendorComplete; break;                                   // to decide
        case enums.screen.SendOrders_Vendor: stage = enums.Stage.VendorSent; break;

        case enums.screen.RecieveOrders_Store: stage = isVendor ? enums.Stage.RecievedFromVendor : enums.Stage.RecievedFromFactory; break;
        case enums.screen.RecieveOrders_Store_Factory: stage = enums.Stage.RecievedFromFactory; break;
        case enums.screen.RecieveOrders_Store_Vendor: stage = enums.Stage.RecievedFromVendor; break;

        case enums.screen.ScanSendToFactory: stage = enums.Stage.SentToFactory; break;
        case enums.screen.ScanRecieveFromFactory: stage = enums.Stage.RecievedFromFactory; break;
        case enums.screen.ScanSendToStore: stage = enums.Stage.FactorySent; break;
        case enums.screen.ScanRecieveFromStore: stage = enums.Stage.FactoryRecieved; break;
        case enums.screen.CustomerDealing: break                                           // its a parent view
        case enums.screen.ApproveTrial: stage = enums.Stage.Tried; break;
        case enums.screen.ApproveFinishing: stage = enums.Stage.Finished; break;
        case enums.screen.ApproveDelivery: stage = enums.Stage.Delivered; break;
        case enums.screen.ViewStatus: break
        case enums.screen.Search_Store: break
        case enums.screen.Search_Factory: break
        default: break
    }
    return stage
}


export const stageValueFor = (screen, isVendor = false) => {
    switch (screen) {
        case enums.screen.NewOrder: break
        case enums.screen.ProcessOrder: break // its a parent view
        case enums.screen.PendingOrders_Store: return enums.Stage.Requested;
        case enums.screen.SendOrders_Store: return enums.Stage.Requested

        case enums.screen.SendOrders_Store_Factory: return enums.Stage.Requested
        case enums.screen.SendOrders_Store_Vendor: return enums.Stage.Requested

        case enums.screen.RecieveOrders_Store_Factory: return enums.Stage.FactorySent
        case enums.screen.RecieveOrders_Store_Vendor: return enums.Stage.SentToVendor

        case enums.screen.RecieveOrders_Store: return isVendor ? enums.Stage.VendorSent : enums.Stage.FactorySent

        case enums.screen.OrdersRecieved_Factory: return enums.Stage.SentToFactory
        case enums.screen.CompleteOrders_Factory: return enums.Stage.FactoryRecieved
        case enums.screen.SendOrders_Factory: return enums.Stage.FactoryComplete

        case enums.screen.SendOrders_FactoryVendorWindow: return enums.Stage.FactoryRecievedForVendor // Plus-- product must have attribute usedVendorViaFactory = true Updated on 2Aug2021 when brand usesVendorViaFactory
        case enums.screen.RecieveOrders_FactoryVendorWindow: return enums.Stage.SentToVendor // Updated on 2Aug2021 when brand usesVendorViaFactory

        case enums.screen.Vendors: return enums.Stage.SentToVendor
        case enums.screen.OrdersRecieved_Vendor: return enums.Stage.SentToVendor
        case enums.screen.CompleteOrders_Vendor: return enums.Stage.VendorRecieved
        case enums.screen.SendOrders_Vendor: return enums.Stage.VendorComplete

        case enums.screen.CustomerDealing: break // its a parent view
        case enums.screen.ApproveTrial: return enums.Stage.RecievedFromFactory
        case enums.screen.ApproveFinishing: return enums.Stage.Tried
        case enums.screen.ApproveDelivery: return enums.Stage.Finished
        case enums.screen.ViewStatus: break
        case enums.screen.Search_Store: break
        case enums.screen.Search_Factory: break
        default: break
    }
    return null
}



export class Vendor {

    constructor(name, description, contact, email, phone, address, forProductTypes, totalPendingProducts, logoPicUrl, objectId) {
        this.name = name
        this.description = description
        this.contact = contact
        this.email = email
        this.phone = phone
        this.address = address
        this.forProductTypes = forProductTypes
        this.totalPendingProducts = totalPendingProducts
        this.logoPicUrl = logoPicUrl
        this.objectId = objectId
    }

    static init = () => {
        let vn = new Vendor(null, null, null, null, null, null, null, null)
        return vn
    }


    add(callback) {
        let ob = new VendorClass()
        ob.set('name', this.name)
        ob.set('contact', this.contact)
        ob.set('email', this.email)
        ob.set('phone', this.phone)
        ob.set('address', this.address)
        ob.set('description', this.description)
        ob.set('forProductTypes', this.forProductTypes)
        ob.set('totalProductsCount', this.totalProductsCount)

        if (this.logoPicUrl != null) { ob.set('logoPicUrl', this.logoPicUrl) }


        ob.save()
            .then((vendor) => {
                this.objectId = vendor.id
                console.log('New Vendor Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new Vendor, with error code: ' + error.message);
                callback(false, error.message)
            });
    }


    update(callback) {

        if (this.objectId == null) {
            console.log("Error : No object id to update Vendor")
            callback(false, 'Error : No object id to update Vendor')
            return
        }

        var query = new Parse.Query(VendorClass);
        query.get(this.objectId)
            .then((ob) => {

                ob.set('name', this.name)
                ob.set('contact', this.contact)
                ob.set('email', this.email)
                ob.set('phone', this.phone)
                ob.set('address', this.address)
                ob.set('description', this.description)
                ob.set('forProductTypes', this.forProductTypes)
                ob.set('totalProductsCount', this.totalProductsCount)

                if (this.logoPicUrl != null) { ob.set('logoPicUrl', this.logoPicUrl) }

                console.log('SAVING VENDOR')
                console.log(ob)

                ob.save()

                callback(true, '')
            }, (error) => {
                callback(false, 'Error =' + error.message)
            })
    }


    static initFromPFObject(obj) {
        let name = obj.get('name')
        let contact = obj.get('contact')
        let email = obj.get('contact') ?? ""
        let phone = obj.get('contact') ?? ""
        let address = obj.get('address')
        let description = obj.get('description')
        let forProductTypes = obj.get('forProductTypes')
        let totalProductsCount = obj.get('totalProductsCount')

        let logoPicUrl = obj.get('logoPicUrl')

        let objectId = obj.id


        let vendor = new Vendor(name, description, contact, email, phone, address, forProductTypes, logoPicUrl, totalProductsCount)
        vendor.objectId = objectId
        return vendor
    }


    static getAll(callback) {
        var query = new Parse.Query(VendorClass);
        query.descending('createdAt')

        query.find().then((objects) => {
            console.log('Vendors =')
            console.log(objects)
            if (objects.length > 0) {

                let allVendors = []
                for (let i = 0; i < objects.length; i++) {
                    const thisOrdObj = objects[i];
                    let thisVendor = Vendor.initFromPFObject(thisOrdObj)
                    if (thisVendor !== null) {
                        allVendors.push(thisVendor)
                    }
                }
                callback(true, allVendors, '')
            } else {
                callback(true, [], "No Vendors")
            }

        }, (error) => {
            console.log('Error while getting all Vendors ', error.message)
            callback(false, null, error.message)
        })
    }


    toPFObject() {
        let thisObj = new VendorClass()
        thisObj.id = this.objectId
        return thisObj
    }


    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Vendor ")
        }

        let thisObj = new VendorClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((obj) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting Vendor ', error.message)
            callback(false, error.message)
        });
    }


}


export class Order {



    constructor(customer, customerName, customerPhone, orderNo, trialDate, deliveryDate, bookingDate, status, products, objectId, orderedAt, addedByUser, profilePicUrl) {
        this.customer = customer
        this.customerName = customerName
        this.customerPhone = customerPhone
        this.customerEmail = null
        this.customerObjId = ""
        this.orderNo = orderNo
        this.trialDate = trialDate
        this.deliveryDate = deliveryDate
        this.bookingDate = bookingDate
        this.status = status
        this.products = products
        this.objectId = objectId
        this.orderedAt = orderedAt
        this.addedByUser = addedByUser
        this.totalProductsCount = products ? products.count : 0
        this.customerEmail = customer ? customer.email1 : ""
        this.customerPicsCount = customer ? customer.picsCount : 0
        this.customerMeasurementsCount = customer ? customer.bodyMeasurementsCount : 0
        this.cancelledBy = null
        this.isCancelled = null
        this.cancellationInfo = ''
        if (profilePicUrl) {
            this.customerProfilePicUrl = profilePicUrl
        }


        this.deliveryAddress = null
        this.price = null
        this.currency = ""
        this.taxes = null
        this.shipping = null
        this.measurements = []

        this.isPaid = false
        this.paymentInfo = ""
        this.isRefunded = false
        this.refundedAmount = null
        this.refundDescription = ""

        this.infoCompleteByAgent = false
        this.infoCompDescription = ""
        this.infoCompDate = null
        this.posNo = ""
        this.isConciergeOrder = false
        this.conciergeDisplayNote = ""
        this.coniergeOrderInfoDump = ""
        this.conciergeMeasurements = null // added seperately

        this.internalInfo = ""

        this.customerObjId = ""

    }



    static init = () => {
        let or = new Order(null, null, null, null, null, null, null, enums.Status.Pending, null, null, null, null, null)
        return or
    }

    static copyFrom = (obj) => {
        let or = new Order(null, null, null, null, null, null, null, enums.Status.Pending, null, null, null, null, null)

        or.customer = obj.customer
        or.customerName = obj.customerName
        or.customerPhone = obj.customerPhone
        or.customerEmail = obj.customerEmail
        or.orderNo = obj.orderNo
        or.trialDate = obj.trialDate
        or.deliveryDate = obj.deliveryDate
        or.bookingDate = obj.bookingDate
        or.status = obj.status
        or.products = obj.products
        or.objectId = obj.objectId ?? obj.id
        or.orderedAt = obj.orderedAt
        or.addedByUser = obj.addedByUser
        or.totalProductsCount = obj.totalProductsCount
        or.customerEmail = obj.customerEmail
        or.customerPicsCount = obj.customerPicsCount
        or.customerMeasurementsCount = obj.customerMeasurementsCount
        or.cancelledBy = obj.cancelledBy
        or.isCancelled = obj.isCancelled
        or.cancellationInfo = obj.cancellationInfo
        or.customerProfilePicUrl = obj.customerProfilePicUrl

        or.deliveryAddress = obj.deliveryAddress
        or.price = obj.price
        or.currency = obj.currency
        or.taxes = obj.taxes
        or.shipping = obj.shipping
        or.measurements = obj.measurements

        or.isPaid = obj.isPaid
        or.paymentInfo = obj.paymentInfo
        or.isRefunded = obj.isRefunded
        or.refundedAmount = obj.refundedAmount
        or.refundDescription = obj.refundDescription


        or.infoCompleteByAgent = obj.infoCompleteByAgent
        or.infoCompDescription = obj.infoCompDescription
        or.infoCompDate = obj.infoCompDate

        or.posNo = obj.posNo
        or.isConciergeOrder = obj.isConciergeOrder
        or.conciergeDisplayNote = obj.conciergeDisplayNote
        or.coniergeOrderInfoDump = obj.coniergeOrderInfoDump
        or.conciergeMeasurements = obj.conciergeMeasurements

        or.internalInfo = obj.internalInfo
        or.customerObjId = obj.customerObjId

        return or

    }


    add(callback) {

        let ob = new OrderClass()

        let customerPointer = {
            __type: 'Pointer',
            className: 'Customer',
            objectId: this.customer.objectId
        }
        ob.set('customer', customerPointer)

        ob.set('customerObjId', this.customer.objectId)

        ob.set('customerName', onlyOneWhitespaceInWords(this.customer.name).toUpperCase())

        ob.set('customerPhone', this.customer.phone1 ?? this.customerPhone)

        ob.set('customerEmail', this.customer.email1 ?? this.customerEmail)

        ob.set('customerPicsCount', this.customer.picsCount)

        ob.set('customerMeasurementsCount', this.customer.bodyMeasurementsCount)


        // let currentUser = getCurrentUser()

        // if (currentUser != null) {
        //     let addedByPointer = {
        //         __type: 'Pointer',
        //         className: 'Users',
        //         objectId: currentUser.objectId
        //     }
        //     ob.set('addedByUser', addedByPointer)
        // }


        ob.set('orderNo', this.orderNo)

        if (this.trialDate !== null) {
            ob.set('trialDate', this.trialDate)
        }
        if (this.deliveryDate !== null) {
            ob.set('deliveryDate', this.deliveryDate)
        }

        if (this.isCancelled !== null) { ob.set("isCancelled", this.isCancelled) }

        if (this.cancelledBy !== null) {
            let cancelledByPointer = {
                __type: 'Pointer',
                className: 'Users',
                objectId: this.cancelledBy.objectId
            }
            ob.set('cancelledBy', cancelledByPointer)
        }

        ob.set('cancellationInfo', this.cancellationInfo)


        ob.set('bookingDate', this.bookingDate)

        ob.set('status', this.status)


        if (this.deliveryAddress !== null) { ob.set('deliveryAddress', this.deliveryAddress) }
        if (this.price !== null) { ob.set('price', this.price) }
        if (this.currency !== null) { ob.set('currency', this.currency) }
        if (this.taxes !== null) { ob.set('taxes', this.taxes) }
        if (this.shipping !== null) { ob.set('shipping', this.shipping) }
        if (this.measurements !== null) { ob.set('measurements', this.measurements) }
        ob.set('isConciergeOrder', this.isConciergeOrder)
        ob.set('conciergeDisplayNote', this.conciergeDisplayNote)
        ob.set('coniergeOrderInfoDump', this.coniergeOrderInfoDump)

        if (this.conciergeMeasurements) {
            let measureStrings = []
            this.conciergeMeasurements.measures.map((thisM) => {
                let thisMs = Measure.measure_toString(thisM)
                measureStrings.push(thisMs)
            })

            let obj = {
                "description": "Customer Measurement Attached to Order",
                "key": "jbbkjbhvyuycuyycc",
                "isConciergeMeasurement": true,
                "measures": measureStrings
            }

            ob.set('conciergeMeasurements', [obj])
        }




        ob.set('isPaid', this.isPaid)
        ob.set('paymentInfo', this.paymentInfo)

        ob.set('isRefunded', this.isRefunded)

        if (this.refundedAmount !== null) {
            let ra = Number(this.refundedAmount)
            console.log("RA = ", ra)

            if (ra) {
                ob.set('refundedAmount', ra)
            }
        }

        ob.set('refundDescription', this.refundDescription)


        // ob.set('infoCompleteByAgent', this.infoCompleteByAgent)
        // ob.set('infoCompDescription', this.infoCompDescription)
        // if (this.infoCompDate) { ob.set('infoCompDate', this.infoCompDate) }
        



        ob.set('posNo', this.posNo)


        let locs = getLocations()
        if (locs.length > 0) {
            let thisLoc = locs[0]
            if (thisLoc != null) {
                let orderedAtLoc = {
                    __type: 'Pointer',
                    className: 'Location',
                    objectId: thisLoc.objectId
                }
                ob.set('orderedAt', orderedAtLoc)
            }
        }


        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        let addedByPointer = {
            __type: 'Pointer',
            className: 'Users',
            objectId: cu.objectId
        }
        ob.set('addedByUser', addedByPointer)

        let orderedAtPointer = {
            __type: 'Pointer',
            className: 'Location',
            objectId: cl.objectId
        }
        ob.set("orderedAt", orderedAtPointer)


        if (this.totalProductsCount !== null) {
            ob.set('totalProductsCount', this.totalProductsCount)

            if (this.products != null) {
                if (this.products.count > this.totalProductsCount) {
                    this.totalProductsCount = this.products.count
                    ob.set('totalProductsCount', this.products.count)
                }
            }

        }


        if (this.customerProfilePicUrl !== null) {
            ob.set('customerProfilePicUrl', this.customerProfilePicUrl)
        }

        // let productRelation = ob.relation("products")

        if (this.products != null) {
            for (let i = 0; i < this.products.length; i++) {
                const thisProd = this.products[i];

                let productRelation = ob.relation("products")
                productRelation.add(thisProd.toPFObject())
            }
        }


        ob.set('internalInfo', this.internalInfo)


        ob.save()
            .then((order) => {
                this.objectId = order.id
                console.log('New Order Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new order, with error code: ' + error.message);
                callback(false, error.message)
            });

    }



    update(callback) {

        if (this.objectId == null) {
            console.log("Error : No object id to Order Order")
            callback(false, 'Error : No object id to update Order')
            return
        }

        var query = new Parse.Query(OrderClass);
        query.get(this.objectId)
            .then((ob) => {
                // The object was retrieved successfully.

                // user.set('name', this.name)

                // let customerPointer = {
                //     __type: 'Pointer',
                //     className: 'Customer',
                //     objectId: this.customer.objectId
                // }
                // ob.set('customer', customerPointer)

                // ob.set('customerName', this.customer.name)

                // ob.set('customerPhone', this.customer.phone1 ?? this.customerPhone)

                // ob.set('customerEmail', this.customer.email1 ?? this.customerEmail)

                // ob.set('customerPicsCount', this.customer.picsCount)

                // ob.set('customerMeasurementsCount', this.customer.bodyMeasurementsCount)


                // let addedByPointer = {
                //     __type: 'Pointer',
                //     className: 'Users',
                //     objectId: this.addedByUser.objectId
                // }
                // ob.set('addedByUser', addedByPointer)



                // ob.set('orderNo', this.orderNo)

                if (this.trialDate !== null) {
                    ob.set('trialDate', this.trialDate)
                }
                if (this.deliveryDate !== null) {
                    ob.set('deliveryDate', this.deliveryDate)
                }

                if (this.isCancelled) {
                    // alert(`SAVING IS_CANCELLED = ${this.isCancelled}`)
                }


                if (this.isCancelled !== null) { ob.set("isCancelled", this.isCancelled) }

                if (this.cancelledBy !== null) {
                    let cancelledByPointer = {
                        __type: 'Pointer',
                        className: 'Users',
                        objectId: this.cancelledBy.objectId
                    }
                    ob.set('cancelledBy', cancelledByPointer)
                }

                ob.set('cancellationInfo', this.cancellationInfo)


                ob.set('bookingDate', this.bookingDate)

                ob.set('status', this.status)

                if (this.deliveryAddress !== null) { ob.set('deliveryAddress', this.deliveryAddress) }
                if (this.price !== null) { ob.set('price', this.price) }
                if (this.currency !== null) { ob.set('currency', this.currency) }
                if (this.taxes !== null) { ob.set('taxes', this.taxes) }
                if (this.shipping !== null) { ob.set('shipping', this.shipping) }
                if (this.measurements !== null) { ob.set('measurements', this.measurements) }
                ob.set('isConciergeOrder', this.isConciergeOrder)
                ob.set('conciergeDisplayNote', this.conciergeDisplayNote)
                ob.set('coniergeOrderInfoDump', this.coniergeOrderInfoDump)

                if (this.conciergeMeasurements) {
                    let measureStrings = []
                    this.conciergeMeasurements.measures.map((thisM) => {
                        let thisMs = Measure.measure_toString(thisM)
                        measureStrings.push(thisMs)
                    })

                    let obj = {
                        "description": "Customer Measurement Attached to Order",
                        "key": "bkbkjkbkjbjjbcftcft",
                        "isConciergeMeasurement": true,
                        "measures": measureStrings
                    }

                    ob.set('conciergeMeasurements', [obj])
                }



                ob.set('isPaid', this.isPaid)
                ob.set('paymentInfo', this.paymentInfo)

                ob.set('isRefunded', this.isRefunded)



                ob.set('currency', this.currency)


                if (this.refundedAmount !== null) {
                    let ra = Number(this.refundedAmount)
                    console.log("RA = ", ra)

                    if (ra) {
                        ob.set('refundedAmount', ra)
                    }
                }

                ob.set('refundDescription', this.refundDescription)
                ob.set('posNo', this.posNo)


                ob.set('infoCompleteByAgent', this.infoCompleteByAgent)
                ob.set('infoCompDescription', this.infoCompDescription)
                if (this.infoCompDate) { ob.set('infoCompDate', this.infoCompDate) }


                // ob["orderedAt"] = this.orderedAt.toPFObject()


                // if (this.totalProductsCount !== null) {
                //     ob.set('totalProductsCount', this.totalProductsCount)

                //     if (this.products != null){
                //         if (this.products.count > this.totalProductsCount) {
                //             this.totalProductsCount = this.products.count
                //             ob.set('totalProductsCount', this.products.count)
                //         }
                //     }

                // }


                // if (this.customerProfilePicUrl !== null) {
                //     ob.set('customerProfilePicUrl', this.customerProfilePicUrl)
                // }

                // let productRelation = ob.relation("products")

                // if (this.products != null) {
                //     for (let i = 0; i < this.products.length; i++) {
                //         const thisProd = this.products[i];
                //         productRelation.add(thisProd.toPFObject())
                //     }
                // }


                ob.set('internalInfo', this.internalInfo)


                ob.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }



    static initFromPFObject(obj, existingCust) {

        // We need to have customer in the order
        //        console.log("Cust Object for Order is :\(obj.value(forKeyPath: "customer") as? PFObject)")

        var cust = sampleCustomer
        var thisCustomerObjId = ""

        //        console.log("Order Object is :\(obj.description)")



        let orderedAtLocObj = obj.get('orderedAt')

        // console.log("ORDERED AT LOC IN INIT IS")
        // console.log(orderedAtLoc)

        let orderedAtLocArr = getLocations().filter((thisLoc) => { return thisLoc.objectId === orderedAtLocObj.id })
        let orderedAtLoc = orderedAtLocArr[0] ?? null
        // if (orderedAtObj != null) {
        //     let orderedAtLocs = getLocations().filter((itm) => {

        //         if (orderedAtObj.id){
        //             return itm.objectId === orderedAtObj.id
        //         }
        //         if (orderedAtObj.objectId)

        //     })
        //     if (orderedAtLocs == null) {
        //         console.log("Order Object does not have OrderedAtLocationObject, or it cannot be converted to a location")
        //         return null
        //     }
        // }


        let addedByUser = null

        let addedBy = obj.get('addedByUser')

        if (addedBy != null) {
            let allUsers = getUsers()
            let matchingUser = allUsers.filter((itm) => {
                return (itm.objectId === addedBy.id)
            })
            if (matchingUser.length > 0) {
                addedByUser = matchingUser[0]
            } else {
                if (addedBy.isDataAvailable === true) {
                    let thisUser = new User.initFromPFObject(addedBy, true)
                    if (thisUser !== null) {
                        addedByUser = thisUser
                    }
                }
            }
        }

        let trialDate = obj.get('trialDate')

        let deliveryDate = obj.get('deliveryDate')
        let bookingDate = obj.get('bookingDate')

        let customerName = obj.get('customerName')
        let customerPhone = obj.get('customerPhone')
        let customerEmail = obj.get('customerEmail')
        let customerPicsCount = obj.get('customerPicsCount')
        let customerMeasurementsCount = obj.get('customerMeasurementsCount')

        let customerProfilePicUrl = obj.get('customerProfilePicUrl')



        let custObj = obj.get('customer')


        if (obj.get('customerObjId')){
            thisCustomerObjId = obj.get('customerObjId')
        }

        if (existingCust !== null && existingCust !== false) {
            // console.log("CASE 1")
            cust = existingCust
            // cogoToast.warn("Existing Customer Object")

            console.log("====Existing Customer Object is")
            console.log(existingCust)


        } else if (custObj.isDataAvailable) {
            // console.log("CASE 2")

            // cogoToast.warn("Customer Data is available")
            // console.log("====Customer Data Object is")
            // console.log(custObj)

            // console.log("====Customer Data ObjectId is")
            // console.log(custObj.id)

            thisCustomerObjId = custObj.id


            // cust = Customer.initFromPFObject(custObj)

            // console.log("==>>==Customer Data Object After conversion is")
            // console.log(cust)

        } else {
            // cogoToast.warn("Customer Data is not available")
        }




        // else {
        //     console.log("CASE 3")
        //     // cust = new Customer(obj.get('customerName'), '', '', '', '', '', obj.get('customerPhone'), null, customerEmail, null, '', '', '', null, null, null, null, null, null, null, customerProfilePicUrl, null, [], [], null, null, null, null, null, null, null, null, null, null)
        //     cust = new Customer(obj.get('customerName'), '', '', '', '', '', obj.get('customerPhone'), null, customerEmail, null, '', '', '', null, null, null, null, null, null, null, customerProfilePicUrl, null, [], [], null, null, null, null, null, null, null, null, null, null)

        //     // cust.picsCount = order.customerPicsCount
        //     // cust.bodyMeasurementsCount = order.customerMeasurementsCount
        //     // cust.profilePicUrl = order.customerProfilePicUrl
        //     cust.isOrderBrief = true
        //     if (custObj !== null) {
        //         cust.objectId = custObj.id
        //     }
        // }



        // let order = Order.init(
        //     customer: cust,
        //     customerName: obj["customerName"] as! String,
        //     customerPhone: obj["customerPhone"] as! String,
        //     orderNo: obj["orderNo"] as! String,
        //     trialDate: null,
        //     deliveryDate: null,
        //     bookingDate: obj["bookingDate"] as! Date,
        //     status: Status(rawValue: obj["status"] as! String)!,
        //     products: [],
        //     objectId: obj.objectId,
        //     orderedAt: orderedAtLoc,
        //     addedByUser: addedByUser)

        let order = new Order(cust, customerName, customerPhone, obj.get('orderNo'), trialDate, deliveryDate, bookingDate, obj.get('status'), [], obj.id, orderedAtLoc, addedByUser, customerProfilePicUrl)

        order.customerObjId = thisCustomerObjId

        order.totalProductsCount = obj.get('totalProductsCount')
        order.isCancelled = obj.get('isCancelled')
        order.cancellationInfo = obj.get('cancellationInfo')

        order.deliveryAddress = obj.get('deliveryAddress')
        order.price = obj.get('price')
        order.currency = obj.get('currency')
        order.taxes = obj.get('taxes')
        order.shipping = obj.get('shipping')
        order.measurements = obj.get('measurements')
        order.products = obj.get('products')

        order.isConciergeOrder = obj.get('isConciergeOrder')
        order.conciergeDisplayNote = obj.get('conciergeDisplayNote')
        order.coniergeOrderInfoDump = obj.get('coniergeOrderInfoDump')


        if (obj.get('conciergeMeasurements')) {
            let measureArr = obj.get('conciergeMeasurements')
            if (measureArr.length) {
                let firstObj = measureArr[0]
                let objMeasures = firstObj.measures ?? []
                var measures = []

                objMeasures.map((m) => {
                    let thisM = Measure.initFromString(m)
                    if (thisM) {
                        measures.push(thisM)
                    }
                })

                let mm = Measurement.init(enums.MeasurementType.conciergeOrder)
                mm.name = "Concierge Order Measurement"
                mm.measures = measures
                mm.isConciergeMeasurement = true
                order.conciergeMeasurements = mm
            }
        }

        order.isPaid = obj.get('isPaid')
        order.paymentInfo = obj.get('paymentInfo')

        order.isRefunded = obj.get('isRefunded') ?? false
        order.refundedAmount = obj.get('refundedAmount') ? Number(obj.get('refundedAmount')).toFixed(2) : null
        order.refundDescription = obj.get('refundDescription') ?? ""

        order.posNo = obj.get('posNo') ?? ""

        order.internalInfo = obj.get('internalInfo') ?? ""

        order.infoCompleteByAgent = obj.get('infoCompleteByAgent') ?? ""
        order.infoCompDescription = obj.get('infoCompDescription') ?? ""
        order.infoCompDate = obj.get('infoCompDate') ?? null

    
        // let cancelledByObj = obj.get('cancelledBy')

        // if (cancelledByObj !== null) {
        //     let allUsers = getUsers()
        //     let matchingUser = allUsers.filter((itm) => {
        //         return (itm.objectId === cancelledByObj.id)
        //     })

        //     if (matchingUser !== null) {
        //         order.cancelledBy = matchingUser
        //     } else {
        //         if (cancelledByObj.isDataAvailable === true) {
        //             let thisUser = User.initFromPFObject(cancelledByObj, true)
        //             if (thisUser !== null) {
        //                 order.cancelledBy = thisUser
        //             }
        //         }
        //     }
        // }


        return order
    }






    static getOrders(forScreen, param, vendor, location, infoCompleteByAgent, filterText, callback) {

        console.log('FINDING STAGE FOR SCREEN = ' + forScreen)
        console.log('WITH PARAMS = ' + param)


        let cl = getCurrentLocation()
        let cu = getCurrentUser()

        if (forScreen === enums.screen.ApprochingSched || forScreen === enums.screen.BehindSched) {
            let filter = OrderFilter.init()

            // Filter for factory
            if (cl.isMain !== true && cl.type !== LocationType.factory) {
                filter.orderedAt = cl
            }

            // Filter for Agent
            if (cu.isAgent === true) {
                filter.addedByUser = cu
            }

            //Filter for Franchise
            if (cl.isFranchise === true) {
                filter.orderedAt = cl
            }


            if (param === "trial") {
                if (forScreen === enums.screen.ApprochingSched) {
                    console.log("APPROACHING TRIAL")
                    let date = new Date();
                    let seventhDay = date.addDays(7)

                    if (seventhDay) {
                        filter.productTrialDate.from = new Date()
                        filter.productTrialDate.to = seventhDay
                        console.log("ADDING APPROACHING TRIAL")

                    } else {
                        console.log("COULD NOT GET 7TH DAY")
                    }
                }
                if (forScreen === enums.screen.BehindSched) {
                    console.log("Behind Schedule")
                    filter.productTrialDate.to = new Date()
                    filter.productNotStages = [enums.Stage.Delivered, enums.Stage.Tried, enums.Stage.Finished]
                }
            }

            if (param === "delivery") {
                if (forScreen === enums.screen.ApprochingSched) {
                    // console.log("APPROACHING DELIVERY")
                    let date = new Date();
                    let seventhDay = date.addDays(7)

                    if (seventhDay) {
                        filter.productDeliveryDate.from = new Date()
                        filter.productDeliveryDate.to = seventhDay
                        // console.log("ADDING APPROACHING DELIVERY")

                    } else {
                        console.log("COULD NOT GET 7TH DAY")
                    }
                }
                if (forScreen === enums.screen.BehindSched) {
                    console.log("Behind Schedule Delivery")
                    filter.productDeliveryDate.to = new Date()
                    filter.productNotStages = [enums.Stage.Delivered]
                }
            }



            // Get these orders
            this.getAll(filter, callback)
            return
        }


        var isVendor = (vendor !== null)
        if ([enums.screen.OrdersRecieved_Vendor, enums.screen.CompleteOrders_Vendor, enums.screen.SendOrders_Vendor, enums.screen.Search_Vendor].includes(forScreen)) {
            isVendor = true
        }


        var stageValForScreen = stageValueFor(forScreen, vendor ? true : false)

        if (param === "Unprocessed"){
            stageValForScreen = stageValueFor(enums.screen.PendingOrders_Store, false)
        }

        console.log(`PARAM - ${param}`)

        console.log(`SCREEN - ${forScreen}`)
        console.log(`STAGE - ${stageValForScreen}`)


        if (stageValForScreen != null) {

            console.log(`TRYING TO GET ORDERS`)
            console.log(`SCREEN - ${forScreen}`)
            console.log(`STAGE - ${stageValForScreen}`)
            // console.log(`LOCATION - ${location.name}`)
            // console.log(`LOCATION OBJID- ${location.objectId}`)


            // IN CASE OF RECIEVE ORDERS AT STORE, THERE WILL BE 2 STAGES, BOTH FROM STORE AND VENDOR
            let stages = null
            if (forScreen === enums.screen.ApproveTrial) {
                stages = [enums.Stage.RecievedFromFactory, enums.Stage.RecievedFromVendor]
            }

            if (forScreen === enums.screen.RecieveOrders_Store_Vendor || forScreen === enums.screen.RecieveOrders_FactoryVendorWindow ) {
                stages = [enums.Stage.VendorSent, enums.Stage.SentToVendor, enums.Stage.VendorRecieved, enums.Stage.VendorComplete]
            }

            // let orderFilter = new OrderFilter()
            // orderFilter.productStage = stageValForScreen

            // if (location.type === LocationType.store) {
            //     orderFilter.orderedAt = location
            // }

            // if location.type == .factory {
            //     orderFilter.productMakeAt = location
            // }

            // this.getAll(orderFilter, (succ, orders, errMsg) => {
            //     // console.log("ORDERS - \(succ) \(orders.count)")
            //     callback(succ, orders, errMsg)
            // })


            // console.log(`StageValForScreen - ${stageValForScreen}. Stages = ${[...stages ?? []]}. Vendor = ${vendor ?? "none"}. FilterText = ${filterText ?? "none"}`)

            this.getOrdersForScreen(stageValForScreen, stages, vendor, filterText, (succ, orders, errMsg) => {
                // console.log("ORDERS - \(succ) \(orders.count)")
                callback(succ, orders, errMsg)
            })
            // var params = {
            //     "stage": stageValForScreen,
            // }

            // Parse.Cloud.run('ordersWithProductStage', params)
            //     .then((results) => {
            //         console.log(`Response is`)
            //         console.log(results)
            //         callback(true, results , "")
            //     }).catch((error) => {
            //         console.log(`Error response is : ${error.message}`)
            //         callback(false, null, error.message)
            //     })

        } else {


            // Recent And Pending Info

            if (forScreen === enums.screen.RecentOrders) {
                this.getRecentOrders(param, filterText, callback)

            } else if (forScreen === enums.screen.PendingInfo) {
                this.getRecentOrders(param, filterText, callback)

            }

            else {
                // completion(false, [], "No stage for this screen")

                console.log(`No ProductStageValue for screen - ${forScreen} to get orders`)
                callback(false, [], `No ProductStageValue for screen - ${forScreen} to get orders`)
            }


        }
    }

    

    static getRecentOrders(param, filterText, callback) {

        var query = new Parse.Query(OrderClass);
        query.descending('createdAt')
        query.limit(100)

        let cl = getCurrentLocation()
        let cu = getCurrentUser()
        let today = new Date()

        

        if (param) {
            if (param === "Today") {
                let lessDay = today.addDays(-1)
                let plusDay = today.addDays(1)

                query.greaterThanOrEqualTo("createdAt", lessDay)
                query.lessThanOrEqualTo("createdAt", plusDay)

            }

            if (param === "Info Pending") {
                query.notEqualTo("infoCompleteByAgent", true)
            }

            if (param === "Recent Info Complete") {
                query.equalTo("infoCompleteByAgent", true)
                let last10Days = today.addDays(-10)
                query.greaterThanOrEqualTo("infoCompDate", last10Days)
                query.descending('infoCompDate')
            }

            if (param === "Unprocessed") {
                // HANDLED IN PARENT FUNCTION == getOrders
                // query.equalTo("stage", stageValueFor(enums.screen.PendingOrders_Store))
                // query.descending('infoCompDate')
            }
        }


        // Filter for Agent
        if (cu.isAgent === true) {
            // orderFilter.addedByUser = cu
            let addedByPointer = {
                __type: 'Pointer',
                className: 'Users',
                objectId: cu.objectId
            }
            query.equalTo('addedByUser', addedByPointer)
        }

        // Filter for location
        if (cl.type !== LocationType.factory && cl.isMain !== true) {
            // orderFilter.orderedAt = cl
            let orderedAtPointer = {
                __type: 'Pointer',
                className: 'Location',
                objectId: cl.objectId
            }
            query.equalTo("orderedAt", orderedAtPointer)
        }


        if (filterText) {
            if (filterText != null && filterText !== "") {

                let orderNoQuery = new Parse.Query(OrderClass)
                orderNoQuery.contains("orderNo", filterText)

                let posNoQuery = new Parse.Query(OrderClass)
                posNoQuery.contains("posNo", filterText)

                let customerNameQuery = new Parse.Query(OrderClass)
                customerNameQuery.contains('customerName', filterText.toUpperCase())

                let filterORQuery = Parse.Query.or(orderNoQuery, customerNameQuery, posNoQuery)


                console.log("***** Filter Text Query Applied *****")
                let orderQueryCopy = query
                query = Parse.Query.and(
                    orderQueryCopy,
                    filterORQuery
                )

                console.log("Final Query is")
                console.log(query)


            }
        }



        query.find().then((objects) => {
            // console.log('Orders =')
            // console.log(objects)
            if (objects.length > 0) {

                let allOrders = []
                for (let i = 0; i < objects.length; i++) {
                    const thisOrdObj = objects[i];
                    let thisOrder = Order.initFromPFObject(thisOrdObj, false)
                    if (thisOrder !== null) {
                        allOrders.push(thisOrder)
                    }
                }

                // console.log('ALL CONVERTED ORDERS ARE ')
                // console.log(allOrders)

                callback(true, allOrders, '')
            } else {
                callback(true, [], "No Orders Here")
            }

        }, (error) => {
            console.log('Error while getting all Orders ', error.message)
            callback(false, null, error.message)
        })



    }




    static getOrdersForScreen(stage, stages, vendor, filterText, callback) {

        let prodQuery = new Parse.Query(ProductClass)

        if (stages && stages.length) {
            prodQuery.containedIn('stage', stages)

            // for (let i = 0; i < stages.length; i++) {
            //     const thisStage = stages[i];
            //     prodQuery.equalTo('stage', thisStage)
            // }

        } else {

            console.log("Checking Product equalToStage = " + stage);
            prodQuery.equalTo('stage', stage)

        }

        if (vendor) {
            if (vendor.objectId == null) {
                callback(false, null, 'No Vendor Id Found. Report this problem.')
                return
            }

            let vendorPointer = {
                __type: 'Pointer',
                className: 'Vendor',
                objectId: vendor.objectId
            }
            prodQuery.equalTo('vendor', vendorPointer)
        }


        let orderQuery = new Parse.Query(OrderClass)
        orderQuery.descending('createdAt')
        orderQuery.limit(100)
        orderQuery.matchesKeyInQuery('objectId', 'order.objectId', prodQuery)

        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        // Filter for Agent
        if (cu.isAgent === true) {
            // orderFilter.addedByUser = cu
            let addedByPointer = {
                __type: 'Pointer',
                className: 'Users',
                objectId: cu.objectId
            }
            orderQuery.equalTo('addedByUser', addedByPointer)
        }

        // Filter for location
        if (cl.type !== LocationType.factory && cl.isMain !== true) {
            // orderFilter.orderedAt = cl
            let orderedAtPointer = {
                __type: 'Pointer',
                className: 'Location',
                objectId: cl.objectId
            }
            orderQuery.equalTo("orderedAt", orderedAtPointer)
        }

        // prodQuery.select("order");
        // prodQuery.include("order")
        // prodQuery.distinct("order")

        if (filterText) {
            if (filterText != null && filterText !== "") {

                let orderNoQuery = new Parse.Query(OrderClass)
                orderNoQuery.contains("orderNo", filterText)

                let posNoQuery = new Parse.Query(OrderClass)
                posNoQuery.contains("posNo", filterText)

                let customerNameQuery = new Parse.Query(OrderClass)
                customerNameQuery.contains('customerName', filterText.toUpperCase())

                let filterORQuery = Parse.Query.or(orderNoQuery, customerNameQuery, posNoQuery)

                console.log("***** Filter Text Query Applied *****")
                let orderQueryCopy = orderQuery
                orderQuery = Parse.Query.and(
                    orderQueryCopy,
                    filterORQuery
                )

                console.log("Final Query is")
                console.log(orderQuery)
            }
        }


        console.log("ORDER QUERY IS");
        console.log(orderQuery);

        orderQuery.find().then((objects) => {
            // console.log('Orders =')
            // console.log(objects)
            if (objects.length > 0) {

                let allOrders = []
                for (let i = 0; i < objects.length; i++) {
                    const thisOrdObj = objects[i];
                    let thisOrder = Order.initFromPFObject(thisOrdObj, false)
                    if (thisOrder !== null) {
                        allOrders.push(thisOrder)
                    }
                }

                // console.log('ALL CONVERTED ORDERS ARE ')
                // console.log(allOrders)

                callback(true, allOrders, '')
            } else {
                callback(true, [], "No Orders Here")
            }

        }, (error) => {
            console.log('Error while getting all Orders ', error.message)
            callback(false, null, error.message)
        })


    }



    static getAll(orderFilter, callback) {

        var query = new Parse.Query(OrderClass);
        query.descending('createdAt')
        // query.includeKey('selectedPostures')


        // console.log(`ORDER FILTER description : ${orderFilter.description}`)

        console.log('CHECHING ORDER FILTER')

        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        // Filter for Agent
        if (cu.isAgent === true) {
            orderFilter.addedByUser = cu
            // let addedByPointer = {
            //     __type: 'Pointer',
            //     className: 'Users',
            //     objectId: cu.objectId
            // }
            // orderQuery.equalTo('addedByUser', addedByPointer)
        }

        // Filter for location
        if (cl.type !== LocationType.factory && cl.isMain !== true) {
            orderFilter.orderedAt = cl
            // let orderedAtPointer = {
            //     __type: 'Pointer',
            //     className: 'Location',
            //     objectId: cl.objectId
            // }
            // orderQuery.equalTo("orderedAt", orderedAtPointer)
        }



        if (orderFilter.isActive()) {

            console.log('ORDER FILTER IS ACTIVE')

            // Added By
            // if (orderFilter.addedByUser !== null) {

            //     let addedByPointer = {
            //         __type: 'Pointer',
            //         className: 'Users',
            //         objectId: orderFilter.addedByUser.objectId
            //     }
            //     query.equalTo('addedByUser', addedByPointer)
            // }

            if (orderFilter.addedByUser) {
                let addedByPointer = {
                    __type: 'Pointer',
                    className: 'Users',
                    objectId: orderFilter.addedByUser.objectId
                }
                query.equalTo('addedByUser', addedByPointer)
            }


            // Cancelled
            if (orderFilter.isCancelled != null) {
                if (orderFilter.isCancelled === true) {
                    query.equalTo("isCancelled", true)

                } else {
                    query.notEqualTo("isCancelled", true)
                }
            }

            // Cancelled By
            // if (orderFilter.cancelledBy !== null) {
            //     query.equalTo("cancelledBy", orderFilter.cancelledBy.toPFObject())
            // }


            if (orderFilter.customer != null) {
                let customerPointer = {
                    __type: 'Pointer',
                    className: 'Customer',
                    objectId: orderFilter.customer.objectId
                }
                query.equalTo('customer', customerPointer)
            }

            if (orderFilter.customerName != null) {
                // query.equalTo("customerName", orderFilter.customerName)
                // query.contains("customerName", orderFilter.customerName)

                if (orderFilter.customerName.length > 0) {
                    query.contains('customerName', orderFilter.customerName.toUpperCase())
                }
            }

            if (orderFilter.customerPhone != null) {
                query.equalTo("customerPhone", orderFilter.customerPhone)
            }

            if (orderFilter.customerEmail != null) {
                query.equalTo("customerEmail", orderFilter.customerEmail)
                // query.contains("customerEmail", orderFilter.customerEmail)

            }


            if (orderFilter.orderedAt) {
                let orderedAtPointer = {
                    __type: 'Pointer',
                    className: 'Location',
                    objectId: orderFilter.orderedAt.objectId
                }

                query.equalTo("orderedAt", orderedAtPointer)
            }


            if (orderFilter.orderNo != null) {
                query.contains("orderNo", orderFilter.orderNo)
            }

            if (orderFilter.posNo != null) {
                query.contains("posNo", orderFilter.posNo)
            }

            if (orderFilter.status != null) {
                query.equalTo("status", orderFilter.status)
            }

            // Trial Date
            if (orderFilter.trialDate != null) {
                if (orderFilter.trialDate.from !== null) {
                    query.greaterThanOrEqualTo("trialDate", orderFilter.trialDate.from)
                }

                if (orderFilter.trialDate.to !== null) {
                    query.lessThanOrEqualTo("trialDate", orderFilter.trialDate.to)
                }
            }


            // Delivery Date
            if (orderFilter.deliveryDate != null) {
                if (orderFilter.deliveryDate.from !== null) {
                    query.greaterThanOrEqualTo("deliveryDate", orderFilter.deliveryDate.from)
                }

                if (orderFilter.deliveryDate.to !== null) {
                    query.lessThanOrEqualTo("deliveryDate", orderFilter.deliveryDate.to)
                }
            }



            // Booking Date
            if (orderFilter.bookingDate != null) {
                if (orderFilter.bookingDate.from !== null) {
                    query.greaterThanOrEqualTo("bookingDate", orderFilter.bookingDate.from)
                }

                if (orderFilter.bookingDate.to !== null) {
                    query.lessThanOrEqualTo("bookingDate", orderFilter.bookingDate.to)
                }
            }



            // Number Of Products
            if (orderFilter.numberOfProducts) {
                if (orderFilter.numberOfProducts.from !== null) {
                    query.greaterThanOrEqualTo("totalProductsCount", orderFilter.numberOfProducts.from)
                }

                if (orderFilter.numberOfProducts.to !== null) {
                    query.lessThanOrEqualTo("totalProductsCount", orderFilter.numberOfProducts.to)
                }
            }


            var productQuery = new Parse.Query(ProductClass);
            var isPoActive = false

            // console.log('PRODUCT STAGE OF FILTER = ' + orderFilter.productStage)
            if (orderFilter.productStage != null) {
                // console.log('DIDSET   PRODUCT STAGE OF FILTER = ' + orderFilter.productStage)

                productQuery.equalTo("stage", orderFilter.productStage)
                isPoActive = true
            }



            console.log('findInFactoryOnly FILTER = ' + orderFilter.findInFactoryOnly)
            console.log(orderFilter)

            if (orderFilter.findInFactoryOnly === true) {
                let factoryVisibleStages = [enums.Stage.SentToFactory, enums.Stage.FactoryRecieved, enums.Stage.FactoryComplete, enums.Stage.FactorySent]
                productQuery.containedIn('stage', factoryVisibleStages)
                console.log('DIDSET   PRODUCT STAGE OF FILTER = ')
                console.log(productQuery)


            }

            if (orderFilter.findInVendorsOnly === true) {
                let factoryVisibleStages = [enums.Stage.SentToFactory, enums.Stage.FactoryRecieved, enums.Stage.FactoryComplete, enums.Stage.FactorySent]
                //TODO
                let vendorVisibleStages = 'PENDING'
                productQuery.containedIn('stage', factoryVisibleStages)
            }



            if (orderFilter.productType !== null) {
                productQuery.equalTo("type", orderFilter.productType)
                isPoActive = true
            }

            if (orderFilter.productPurpose !== null) {
                productQuery.equalTo("purpose", orderFilter.productPurpose)
                isPoActive = true
            }

            if (orderFilter.productOrderedAt !== null) {
                productQuery.equalTo("orderedAt", orderFilter.productOrderedAt)
                isPoActive = true
            }

            if (orderFilter.productMakeAt !== null) {
                productQuery.equalTo("makeAt", orderFilter.productMakeAt)
                isPoActive = true
            }


            // Product Trial Date
            if (orderFilter.productTrialDate) {
                if (orderFilter.productTrialDate.from !== null) {
                    productQuery.greaterThanOrEqualTo("trialDate", orderFilter.productTrialDate.from)
                    isPoActive = true
                }

                if (orderFilter.productTrialDate.to !== null) {
                    productQuery.lessThanOrEqualTo("trialDate", orderFilter.productTrialDate.to)
                    isPoActive = true
                }
            }


            // Product Delivery Date
            if (orderFilter.productDeliveryDate) {
                if (orderFilter.productDeliveryDate.from !== null) {
                    productQuery.greaterThanOrEqualTo("deliveryDate", orderFilter.productDeliveryDate.from)
                    isPoActive = true
                }

                if (orderFilter.productDeliveryDate.to !== null) {
                    productQuery.lessThanOrEqualTo("deliveryDate", orderFilter.productDeliveryDate.to)
                    isPoActive = true
                }
            }


            if (isPoActive === true) {
                console.log('DID SET MATCHING PRODUCT QUERY')
                console.log(productQuery)



                query.matchesKeyInQuery('objectId', 'order.objectId', productQuery)


                // query.matchesQuery("products", productQuery)
                // query.doesNotMatchQuery("products", productQuery)
                // query.matchesKeyInQuery('id','order.objectId',productQuery)
                console.log(query)

            }


        }




        query.find().then((objects) => {
            console.log('Orders =')
            console.log(objects)
            if (objects.length > 0) {

                let allOrders = []
                for (let i = 0; i < objects.length; i++) {
                    const thisOrdObj = objects[i];
                    let thisOrder = Order.initFromPFObject(thisOrdObj, false)
                    if (thisOrder !== null) {
                        allOrders.push(thisOrder)
                    }
                }
                callback(true, allOrders, '')
            } else {
                callback(true, [], "No Orders")
            }

        }, (error) => {
            console.log('Error while getting all Orders ', error.message)
            callback(false, null, error.message)
        })


    }


    toPFObject() {
        let thisObj = new OrderClass()
        thisObj.id = this.objectId
        return thisObj
    }



    static getOrderNo(callback) {
        var query = new Parse.Query(LocationClass);
        query.select('currentOrderNo')
        let currentLoc = getLocations()[0]

        query.get(currentLoc.objectId)
            .then((loc) => {

                let currentOrderNo = loc.get('currentOrderNo')

                callback(true, currentOrderNo, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                console.log('Error while getting location for Order No ')
                console.log(error)

                callback(false, null, 'Error =' + error.message)
            });
    }



    // NOT USED CURRENTLY
    static sendOrderHTMLMail(orderObjId, orderNo, footer, toEmail, subject, mailTitle) {

        var params = {
            "orderObjId": orderObjId ?? "",
            "orderNo": orderNo ?? "",
            "footer": footer ?? "",
            "fromEmail": clientEmailFromId,
            "toEmail": toEmail,
            "subject": subject,
            "mailTitle": mailTitle ?? ""
        }

        Parse.Cloud.run('sendTWOrderHtmlSheet', params)
            .then((result) => {
                console.log(`Email Send Response ${result ?? ""}`)
            }).catch((error) => {
                console.log(`Email Send Response Func Error is : ${error.message}`)
            })
    }






    // static downloadOrderSheet(body, callback) {

    //     let postData = JSON.stringify(
    //         {
    //             url: "",
    //             string: body,
    //             output: "download",
    //             orderNo: "",
    //             sendEmail: false,
    //             sendSMS: false,
    //             fromEmail: "",
    //             toEmail: "",
    //             toCCEmail: "",
    //             subject: "",
    //             mailTitle: "",
    //             fromPhone: "",
    //             toPhone: "",
    //             emailConfig: ""
    //         }
    //     )



    //     let headerConfig = {
    //         headers: {
    //             'Content-Type': 'application/json;charset=UTF-8',
    //         }
    //     };


    //     // var requestOptions = {
    //     //     method: 'POST',
    //     //     headers: myHeaders,
    //     //     body: postData,
    //     //     redirect: 'follow'
    //     // };

    //     let pdfUrl = "https://byqenkyxw9.execute-api.eu-west-2.amazonaws.com/Default/pdf"

    //     axios.post(pdfUrl, postData, headerConfig).
    //         then(response => {
    //             //Create a Blob from the PDF Stream
    //             const file = new Blob(
    //                 [response.data],
    //                 { type: 'application/pdf' });
    //             //Build a URL from the file
    //             const fileURL = URL.createObjectURL(file);
    //             //Open the URL on new Window
    //             window.open(fileURL);

    //             callback(true, "DOWNLOADED", null)

    //         })
    //         .catch(error => {
    //             console.log(error);
    //             callback(false, null, "Error in download: ", error.message)

    //         });
    // }


    static downloadOrderSheet(body, callback) {

        let postData = JSON.stringify(
            {
                url: "",
                string: body,
                output: "",
                orderNo: "",
                sendEmail: false,
                sendSMS: false,
                fromEmail: "",
                toEmail: "",
                toCCEmail: "",
                subject: "",
                mailTitle: "",
                fromPhone: "",
                toPhone: "",
                emailConfig: ""
            }
        )

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: postData,
            redirect: 'follow'
        };




        fetch("https://byqenkyxw9.execute-api.eu-west-2.amazonaws.com/Default/pdf", requestOptions)
            // .then(response => response.blob())
            // .then(async res => ({
            //     filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('Content-Disposition', res)),
            //     blob: await res.blob()
            // }))
            // .then(response => {

            //     console.log("Reponse is")
            //     console.log(response)

            //     const headers = response.headers;

            //     console.log("Headers are")
            //     console.log(headers)

            //     // const contentDisposition = headers.get('Content-Disposition');
            //     // const thisFileName = fnGetFileNameFromContentDispostionHeader(contentDisposition, "");
            //     let thisBlob = response.blob()

            //     return {
            //         fileName: "tw-ordersheet.pdf",
            //         blob: thisBlob
            //     }

            // })
            // .then(resObj => {
            //     // .then(function (myBlob) {
            //     // var objectURL = URL.createObjectURL(myBlob);
            //     // myImage.src = objectURL;

            //     const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });


            //     console.log("Result from Download")
            //     console.log(resObj)


            //     // let thisBlob = new Blob([myBlob], { type: 'application/pdf' })
            //     const url = window.URL.createObjectURL(newBlob);
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.download = resObj.fileName;
            //     // link.setAttribute('download', "myordersheet");
            //     document.body.appendChild(link);
            //     link.click();
            //     link.parentNode.removeChild(link);

            //     callback(true, "DOWNLOADED", null)

            // })
            .then(response => response.text())
            .then((result) => {
                console.log(result)
                let resultJSON = JSON.parse(result)
                console.log(resultJSON)

                if (resultJSON) {
                    if (resultJSON.body) {
                        console.log("Result = ", resultJSON.body.result)
                    }
                }

                callback(true, resultJSON, null)

            })
            .catch((error) => {

                callback(false, null, "Error in download: ", error.message)
                console.log('error', error)
            });



        // fetch("https://byqenkyxw9.execute-api.eu-west-2.amazonaws.com/Default/pdf", requestOptions)
        //     .then(response => response.text())
        //     .then((result) => {
        //         console.log(result)
        //         let resultJSON = JSON.parse(result)
        //         console.log("Result from Download")
        //         console.log(resultJSON)

        //         // if (resultJSON) {
        //         //     if (resultJSON.body) {
        //         //         console.log("Result = ", resultJSON.body.result)
        //         //     }
        //         // }

        //         callback(true, resultJSON, null)

        //     })
        //     .catch((error) => {

        //         callback(false, null, "Error in download: ", error.message)
        //         console.log('error', error)
        //     });

    }

    static sendOrderSheet(body, thisOrderNo, prodNo, prodType, sendEmail, sendSMS, toEmail, subject, mailTitle, fromPhone, toPhone, sheetType, products, callback) {
        // 1. SET CALLBACK OF THIS METHOD
        // 2. GIVE STATUS BACK ... EMAIL INITIATED or PHONE INITIATED.

        // cogoToast.info("SENDING SEND-ORDER-SHEET")

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        let mailConfig = null

        let cl = getCurrentLocation()
        let dtString = dateToTimeString(new Date())
        let dateTimeLocString =  cl.name + " • " +  dtString


        if (sendEmail) {
            if (sheetType === enums.PrintSheetType.orderSheet) {
                let prodNameIdArr = ""
                if (products) {
                    if (products.length) {
                        let nameIdArr = products.map((thisProd) => {
                            return `<div >• ${thisProd.type} (${thisProd.productId})</div>`
                        })
                        prodNameIdArr = nameIdArr.join(' ')
                    }
                }

                let orderSheetConfig = {
                    sheetType: "order",
                    orderNo: thisOrderNo,
                    productNo: prodNo ?? "",
                    productType: prodType ?? "",
                    brandLogoUrl: clientLogoUrl ?? " ",
                    dateTimeString: dateTimeLocString,
                    orderMeasureDescription: "This sheet contains the following along with order details",
                    prodNameIdStringArray: prodNameIdArr,
                    brandWebsite: clientWebsite ?? "https://info@tailorwise.com",
                    brandEmail: clientEmail ?? "info@tailorwise.com"
                }

                mailConfig = orderSheetConfig
            } else {

                let prodSheetConfig = {
                    sheetType: "product",
                    orderNo: thisOrderNo ?? "",
                    productNo: prodNo ?? "",
                    productType: prodType ?? "",
                    brandLogoUrl: clientLogoUrl ?? " ",
                    dateTimeString: dateTimeLocString,
                    orderMeasureDescription: "",
                    prodNameIdStringArray: "",
                    brandWebsite: clientWebsite ?? "https://info@tailorwise.com",
                    brandEmail: clientEmail ?? "info@tailorwise.com"
                }

                mailConfig = prodSheetConfig

            }

        }




        let postData = JSON.stringify(
            {
                url: "",
                string: body,
                output: "s3url",
                orderNo: thisOrderNo,
                sendEmail: sendEmail,
                sendSMS: sendSMS,
                fromEmail: clientEmailFromId,
                toEmail: toEmail,
                toCCEmail: clientCCEmail,
                subject: subject,
                mailTitle: mailTitle,
                fromPhone: fromPhone,
                toPhone: toPhone,
                emailConfig: mailConfig
            }
        )


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: postData,
            redirect: 'follow'
        };

        fetch("https://byqenkyxw9.execute-api.eu-west-2.amazonaws.com/Default/pdf", requestOptions)
            .then(response => response.text())
            .then((result) => {
                // console.log(result)
                let resultJSON = JSON.parse(result)
                // console.log(resultJSON)

                if (resultJSON) {
                    if (resultJSON.body) {
                        console.log("Result = ", resultJSON.body.result)
                    }
                }

                callback(true, resultJSON, null)

            })
            .catch((error) => {

                callback(false, null, "Error : ", error.message)
                console.log('error', error)
            });

    }


    static sendCustomHTMLMail(body, orderObjId, thisOrderNo, footer, toEmail, subject, mailTitle) {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        cogoToast.info("SENDING SEND-CUSTOM-HTML-MAIL")

        // const orderSheetEmail = (orderNo, brandLogoUrl, dateTimeString, orderMeasureDescription, prodNameIdStringArray, fileURL, brandWebsite, brandEmail ) => {
        // sheetType,
        // orderNo, 
        // brandLogoUrl, 
        // dateTimeString, 
        // orderMeasureDescription, 
        // prodNameIdStringArray, 
        // brandWebsite, 
        // brandEmail

        let orderSheetConfig = {
            sheetType: "order",
            orderNo: thisOrderNo ?? "filler",
            brandLogoUrl: clientLogoUrl ?? "filler",
            dateTimeString: dateToTimeString(new Date()),
            orderMeasureDescription: "This sheet contains the following details",
            prodNameIdStringArray: "2 Products",
            brandWebsite: clientWebsite ?? "filler",
            brandEmail: clientEmail ?? "filler"
        }



        let postData = JSON.stringify(
            {
                url: "",
                string: body,
                output: "s3url",
                sendEmail: true,
                sendSMS: true,
                emailConfig: orderSheetConfig
            }
        )




        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: postData,
            redirect: 'follow'
        };

        fetch("https://byqenkyxw9.execute-api.eu-west-2.amazonaws.com/Default/pdf", requestOptions)
            .then(response => response.text())
            .then((result) => {
                console.log(result)
                let resultJSON = JSON.parse(result)
                console.log(resultJSON)

                if (resultJSON) {
                    if (resultJSON.body) {
                        console.log("Result = ", resultJSON.body.result)
                    }
                }

            })
            .catch(error => console.log('error', error));


        // ORIGINAL

        // // Fetch API

        // // let postParams = {
        // //     url: "",
        // //     string: body,
        // //     output: "s3url"
        // // }

        // // const requestOptions = {
        // //     method: 'POST',
        // //     mode: 'cors',
        // //     headers: { 'Content-Type': 'application/json' },
        // //     body: JSON.stringify(postParams)
        // // };


        // // cogoToast.info("MAKING FETCH REQUEST NOW.....")


        // // let endpoint = "https://kxqgumgdb5.execute-api.eu-west-2.amazonaws.com/PilotProduction/ChromePuppeteerSendgrid"



        // // fetch("https://kxqgumgdb5.execute-api.eu-west-2.amazonaws.com/PilotProduction/ChromePuppeteerSendgrid", requestOptions)
        // //     .then(response => response.text())
        // //     .then(result => console.log(result))
        // //     .catch(error => console.log('error', error));

        // // fetch(endpoint, requestOptions)
        // //     .then((response) => {


        // //         return response.text()

        // //     })
        // //     .then((res) => {
        // //         console.log("RESONSE FORM PDF URL MAKING FUCNTION")
        // //         console.log(res)

        // //         if (res.result) {
        //             var params = {
        //                 "orderObjId": orderObjId ?? "",
        //                 "orderNo": orderNo ?? "",
        //                 "footer": footer ?? "",
        //                 "fromEmail": fromEmail,
        //                 "toEmail": toEmail,
        //                 "subject": subject,
        //                 "mailTitle": mailTitle ?? "",
        //                 "htmlBody": body
        //             }


        //             console.log("Sending these params to sendCustomHTMLMail")
        //             console.log(" ")
        //             console.log(params)



        //             Parse.Cloud.run('sendTWCustomHtmlSheet', params)
        //                 .then((result) => {
        //                     console.log(`Email Send Response ${result ?? ""}`)
        //                 }).catch((error) => {
        //                     console.log(`Email Send Response Func Error is : ${error.message}`)
        //                 })
        //         // }

        // //     })
        // //     .catch(error => {
        // //         console.log("ERROR WHILE FETCHING PDF URL")
        // //         console.log(error.message)
        // //     });



        // // sendTWCustomHtmlSheet

    }


}




export class Product {

    constructor(customer, order, productId, type, stage, status, purpose, picsCount, measurementsCount, remarksCount, trialDate, deliveryDate, retrial, finishing, stocks, addedByUser, fromStoreSection, saleType, styleNo, challan, flags, assignTo, objectId, orderedAt, makeAt, isGrouped, usedVendorViaFactory ) {
        this.customer = customer
        this.order = order
        this.productId = productId
        this.type = type
        this.stage = stage
        this.status = status
        this.purpose = purpose
        this.picsCount = picsCount
        this.measurementsCount = measurementsCount
        this.remarksCount = remarksCount
        this.trialDate = trialDate
        this.deliveryDate = deliveryDate
        this.retrial = retrial
        this.finishing = finishing
        this.stocks = stocks
        this.addedByUser = addedByUser
        this.fromStoreSection = fromStoreSection
        this.saleType = saleType
        this.styleNo = styleNo
        this.description = ''
        this.challan = challan
        this.flags = flags
        this.assignTo = assignTo
        this.objectId = objectId

        this.orderedAt = orderedAt
        this.makeAt = makeAt

        this.vendor = null
        this.vendorName = null

        this.isGrouped = isGrouped
        this.groupId = null
        this.groupType = null

        this.isCancelled = false
        this.cancelledBy = null
        this.cancellationInfo = ""

        this.sentBy = null
        this.recievedBy = null

        this.assignTo = null

        this.indexPath = null
        this.isSelected = false

        this.code = null
        this.imageUrl = null
        this.imagesUrls = []
        this.isViaConcierge = false
        this.conciergeDisplayNote = null
        this.conciergeStyleDetails = null
        this.isBespoke = true
        this.isMTO = false
        this.conciergeSelections = []
        this.styleSelections = []
        this.materialToSource = []
        this.isSourcingComplete = false
        this.neededMeasures = []
        this.measures = []

        this.basePrice = null
        this.addOnPrice = null
        this.taxOnPrice = null
        this.taxDescription = ""
        this.shippingPrice = null
        this.shippingDescription = ""
        this.price = null
        this.currency = ""
        this.addedPriceObjs = []

        // For temp use
        this.pics = []
        this.remarks = []
        this.measurements = []

        // New Stage Factory_Recieved_For_Vendor added on 2-Aug-2021 - Also putting this as a flag in product as- usedVendorViaFactory & in business check usesVendorViaFactory
        this.usedVendorViaFactory =  usedVendorViaFactory ?? false

    }


    static init = () => {
        let pr = new Product(null, null, null, ProductType.Suit2piece, enums.Stage.Requested, enums.Status.Pending, enums.Purpose.Create, 0, 0, 0, null, null, 0, 0, null, null, enums.FromStoreSection.Western, enums.SaleType.Regular, null, null, null, null, null, null, null, false, false)
        return pr
    }


    static copyFrom = (obj) => {
        let pr = Product.init()

        pr.customer = obj.customer
        pr.order = obj.order
        pr.productId = obj.productId
        pr.type = obj.type
        pr.stage = obj.stage
        pr.status = obj.status
        pr.purpose = obj.purpose
        pr.picsCount = obj.picsCount
        pr.measurementsCount = obj.measurementsCount
        pr.remarksCount = obj.remarksCount
        pr.trialDate = obj.trialDate
        pr.deliveryDate = obj.deliveryDate
        pr.retrial = obj.retrial
        pr.finishing = obj.finishing
        pr.stocks = obj.stocks
        pr.addedByUser = obj.addedByUser
        pr.fromStoreSection = obj.fromStoreSection
        pr.saleType = obj.saleType
        pr.styleNo = obj.styleNo
        pr.description = obj.description
        pr.challan = obj.challan
        pr.flags = obj.flags
        pr.assignTo = obj.assignTo
        pr.objectId = obj.objectId
        pr.orderedAt = obj.orderedAt
        pr.makeAt = obj.makeAt
        pr.vendor = obj.vendor
        pr.vendorName = obj.vendorName
        pr.isGrouped = obj.isGrouped
        pr.groupId = obj.groupId
        pr.groupType = obj.groupType
        pr.isCancelled = obj.isCancelled
        pr.cancelledBy = obj.cancelledBy
        pr.cancellationInfo = obj.cancellationInfo
        pr.sentBy = obj.sentBy
        pr.recievedBy = obj.recievedBy
        pr.assignTo = obj.assignTo
        pr.indexPath = obj.indexPath
        pr.isSelected = obj.isSelected
        pr.objectId = obj.objectId ?? obj.id


        pr.imageUrl = obj.imageUrl
        pr.imagesUrls = obj.imagesUrls
        pr.isViaConcierge = obj.isViaConcierge
        pr.conciergeDisplayNote = obj.conciergeDisplayNote
        pr.conciergeStyleDetails = obj.conciergeStyleDetails
        pr.isBespoke = obj.isBespoke
        pr.isMTO = obj.isMTO
        pr.conciergeSelections = obj.conciergeSelections
        pr.styleSelections = obj.styleSelections
        pr.materialToSource = obj.materialToSource
        pr.isSourcingComplete = obj.isSourcingComplete
        pr.neededMeasures = obj.neededMeasures
        pr.measures = obj.measures
        pr.basePrice = obj.basePrice
        pr.addOnPrice = obj.addOnPrice
        pr.taxOnPrice = obj.taxOnPrice
        pr.taxDescription = obj.taxDescription
        pr.shippingPrice = obj.shippingPrice
        pr.shippingDescription = obj.shippingDescription
        pr.price = obj.price
        pr.currency = obj.currency
        pr.addedPriceObjs = obj.addedPriceObjs

        // For temp use
        pr.pics = obj.pics
        pr.remarks = obj.pics
        pr.measurements = obj.pics

        pr.usedVendorViaFactory = obj.usedVendorViaFactory 

        return pr

    }


    add = (callback) => {


        console.log('P! 1')
        let ob = new ProductClass()

        console.log("CUSTOMER OBJECT  IS")
        console.log(this.customer)


        console.log("CUSTOMER OBJECT ID IS")
        console.log(this.customer.objectId)



        console.log("ORDER OBJECT ID IS")
        console.log(this.order.objectId)

        console.log("ORDER OBJECT  IS")
        console.log(this.order)


        let custId = ""

        if (this.order.customerObjId){
            if (this.order.customerObjId !== "" || this.order.customerObjId !== " "){
                custId = this.order.customerObjId
            }
        }
 
        if (custId === "") {
            if (this.customer) {
                if (this.customer.objectId) {
                    custId = this.customer.objectId
                }
            }
        }



        // if (!custId) {
        //     if (custId === "" || custId === " ") {

        //         console.log("ORDER CUSTOMER OBJECT ID IS")
        //         console.log(this.order.customerObjId)
    
        //         custId = this.order.customerObjId
        //     }
        // }



        let customerPointer = {
            __type: 'Pointer',
            className: 'Customer',
            objectId: custId
        }
        ob.set('customer', customerPointer)


        let orderPointer = {
            __type: 'Pointer',
            className: 'Order',
            objectId: this.order.objectId
        }
        ob.set('order', orderPointer)

        console.log('P! 2')

        ob.set('productId', this.productId)

        ob.set('type', this.type)

        ob.set('stage', this.stage)

        ob.set('status', this.status)

        ob.set('purpose', this.purpose)

        ob.set('picsCount', this.picsCount)

        ob.set('measurementsCount', this.measurementsCount)

        ob.set('remarksCount', this.remarksCount)

        if (this.trialDate !== null) {
            ob.set('trialDate', this.trialDate)
        }
        if (this.deliveryDate !== null) {
            ob.set('deliveryDate', this.deliveryDate)
        }

        ob.set('retrial', this.retrial)

        ob.set('finishing', this.finishing)

        ob.set('fromStoreSection', this.fromStoreSection)

        ob.set('saleType', this.saleType)

        console.log('P! 3')


        if (this.vendor != null) {
            let vendorPointer = {
                __type: 'Pointer',
                className: 'Vendor',
                objectId: this.vendor.objectId
            }
            ob.set('vendor', vendorPointer)
            ob.set('vendorName', this.vendor.name)
        }



        console.log('P! 4')


        ob.set('isGrouped', this.isGrouped)

        if (this.groupId !== null) {
            ob.set('groupId', this.groupId)
        }
        if (this.groupType !== null) {
            ob.set('groupType', this.groupType)
        }


        if (this.styleNo !== null) {
            ob.set('styleNo', this.styleNo)
        }

        if (this.description !== null) {
            ob.set('description', this.description)
        }

        // if (this.challan !== null) { ob["challan"] = this.challan.toPFObject() }

        // if (this.assignTo !== null) { ob["assignTo"] = this.assignTo.toPFObject() }


        // let currentUser = getCurrentUser()

        // if (currentUser != null) {
        //     let addedByPointer = {
        //         __type: 'Pointer',
        //         className: 'Users',
        //         objectId: currentUser.objectId
        //     }
        //     ob.set('addedByUser', addedByPointer)
        // }


        let cu = getCurrentUser()
        let cl = getCurrentLocation()

        let addedByPointer = {
            __type: 'Pointer',
            className: 'Users',
            objectId: cu.objectId
        }
        ob.set('addedByUser', addedByPointer)

        let orderedAtPointer = {
            __type: 'Pointer',
            className: 'Location',
            objectId: cl.objectId
        }
        ob.set("orderedAt", orderedAtPointer)


        // ob["orderedAt"] = this.orderedAt.toPFObject()
        if (this.makeAt !== null) {
            // ob["makeAt"] = this.makeAtLoc.toPFObject()
        }





        if (this.isCancelled !== null) {
            ob.set('isCancelled', this.isCancelled)
        }
        // if (this.cancelledBy !== null) { ob["cancelledBy"] = this.cancelledBy.toPFObject() }
        if (this.cancellationInfo !== null) {
            ob.set('cancellationInfo', this.cancellationInfo)
        }

        if (this.flags != null) {
            if (this.flags.length > 0) {
                ob.set('flags', this.flags.map((itm) => { return itm }))
            }
        }


        //        var fromStoreSection : FromStoreSection
        //        var saleType : SaleType
        //        var styleNo : String?
        //
        //        var challan : Challan?
        //        var flags : Flag?


        //TODO : Make Array of Stocks rather than a relation
        var selectedStocks = []
        let stocksRelation = ob.relation("stocksObj")
        // if (this.stocks){
        //     if (this.stocks.length > 0) {
        //         for (let i = 0; i < this.stocks.length; i++) {
        //             const st = this.stocks[i];
        //             stocksRelation.add(st.toPFObject())
        //             selectedStocks.append(st.toString())
        //         }
        //     }
        // }



        console.log('P! 6')


        // ob.set('stocks', selectedStocks)

        ob.set('code', this.code)

        if (this.description !== null) {
            ob.set('description', this.description)
        }
        ob.set('imageUrl', this.imageUrl)
        ob.set('imageUrls', this.imageUrls)

        // ob.set('isViaConcierge', this.isViaConcierge)
        // ob.set('conciergeDisplayNote', this.conciergeDisplayNote)
        // ob.set('conciergeStyleDetails', this.conciergeStyleDetails)

        // ob.set('isBespoke', this.isBespoke)
        // ob.set('isMTO', this.isMTO)
        // ob.set('conciergeSelections', this.conciergeSelections)
        // ob.set('styleSelections', this.styleSelections)
        // ob.set('materialToSource', this.materialToSource)
        // ob.set('neededMeasures', this.neededMeasures)
        // ob.set('measures', this.measures)

        // ob.set('basePrice', this.basePrice)
        // ob.set('addOnPrice', this.addOnPrice)
        // ob.set('taxOnPrice', this.taxOnPrice)
        // ob.set('taxDescription', this.taxDescription)
        // ob.set('shippingPrice', this.shippingPrice)
        // ob.set('shippingDescription', this.shippingDescription)

        // ob.set('price', this.price)
        // ob.set('currency', this.currency)
        // ob.set('addedPriceObjs', this.addedPriceObjs)

        // ob.set('usedVendorViaFactory', this.usedVendorViaFactory)



        console.log("SAVING PRODUCT OBJECT AS ")
        console.log(ob.description)
        ob.save()
            .then((prod) => {
                this.objectId = prod.id
                console.log('New Product Created')
                callback(true, '')
            }, (error) => {
                console.log('Failed to create new product, with error code: ' + error.message);
                callback(false, error.message)
            });


    }



    update(callback) {

        if (this.objectId == null) {
            console.log("Error : No object id to update Product")
            callback(false, 'Error : No object id to update Product')
            return
        }


        var query = new Parse.Query(ProductClass);
        query.get(this.objectId)
            .then((product) => {
                // user.set('name', this.name)

                // let customerPointer = {
                //     __type: 'Pointer',
                //     className: 'Customer',
                //     objectId: this.customer.objectId
                // }
                // product.set('customer', customerPointer)


                // let orderPointer = {
                //     __type: 'Pointer',
                //     className: 'Order',
                //     objectId: this.order.objectId
                // }
                // product.set('order', orderPointer)


                product.set('productId', this.productId)

                product.set('type', this.type)

                product.set('stage', this.stage)

                product.set('status', this.status)

                product.set('purpose', this.purpose)

                product.set('picsCount', this.picsCount)

                product.set('measurementsCount', this.measurementsCount)

                product.set('remarksCount', this.remarksCount)

                if (this.trialDate !== null) {
                    product.set('trialDate', this.trialDate)
                }
                if (this.deliveryDate !== null) {
                    product.set('deliveryDate', this.deliveryDate)
                }

                product.set('retrial', this.retrial)

                product.set('finishing', this.finishing)

                product.set('fromStoreSection', this.fromStoreSection)

                product.set('saleType', this.saleType)


                // ob["orderedAt"] = this.orderedAt.toPFObject()
                if (this.makeAt != null) {
                    // ob["makeAt"] = this.makeAtLoc.toPFObject()
                }

                if (this.vendor != null) {
                    let vendorPointer = {
                        __type: 'Pointer',
                        className: 'Vendor',
                        objectId: this.vendor.objectId
                    }
                    product.set('vendor', vendorPointer)
                    product.set('vendorName', this.vendor.name)
                } else {

                    if (this.vendorName != null) {
                        product.set('vendorName', this.vendorName)

                    }

                }



                product.set('isGrouped', this.isGrouped)

                if (this.groupId !== null) {
                    product.set('groupId', this.groupId)
                }
                if (this.groupType !== null) {
                    product.set('groupType', this.groupType)
                }


                if (this.styleNo !== null) {
                    product.set('styleNo', this.styleNo)
                }

                if (this.description !== null) {
                    product.set('description', this.description)
                }

                // if (this.challan !== null) { ob["challan"] = this.challan.toPFObject() }

                // if (this.assignTo !== null) { ob["assignTo"] = this.assignTo.toPFObject() }


                // let addedByPointer = {
                //     __type: 'Pointer',
                //     className: 'Users',
                //     objectId: this.addedByUser.objectId
                // }
                // product.set('addedByUser', addedByPointer)

                if (this.isCancelled !== null) {
                    product.set('isCancelled', this.isCancelled)
                }
                // if (this.cancelledBy !== null) { ob["cancelledBy"] = this.cancelledBy.toPFObject() }
                if (this.cancellationInfo !== null) {
                    product.set('cancellationInfo', this.cancellationInfo)
                }


                if (this.flags != null) {
                    if (this.flags.length > 0) {
                        product.set('flags', this.flags.map((itm) => { return itm }))
                    }
                }

                //        var fromStoreSection : FromStoreSection
                //        var saleType : SaleType
                //        var styleNo : String?
                //
                //        var challan : Challan?
                //        var flags : Flag?

                //TODO : Make Array of Stocks rather than a relation
                var selectedStocks = []
                // let stocksRelation = product.relation("stocksObj")
                // if (this.stocks.length > 0) {
                //     for (let i = 0; i < this.stocks.length; i++) {
                //         const st = this.stocks[i];
                //         stocksRelation.add(st.toPFObject())
                //         selectedStocks.append(st.toString())
                //     }
                // }

                product.set('stocks', selectedStocks)


                product.set('code', this.code)

                if (this.description !== null) {
                    product.set('description', this.description)
                }
                product.set('imageUrl', this.imageUrl)
                product.set('imageUrls', this.imageUrls)
                product.set('isViaConcierge', this.isViaConcierge)
                product.set('conciergeDisplayNote', this.conciergeDisplayNote)
                product.set('conciergeStyleDetails', this.conciergeStyleDetails)
                product.set('isBespoke', this.isBespoke)
                product.set('isMTO', this.isMTO)
                product.set('conciergeSelections', this.conciergeSelections)
                product.set('styleSelections', this.styleSelections)
                product.set('materialToSource', this.materialToSource)
                product.set('neededMeasures', this.neededMeasures)
                product.set('measures', this.measures)
                product.set('basePrice', this.basePrice)
                product.set('addOnPrice', this.addOnPrice)
                product.set('taxOnPrice', this.taxOnPrice)
                product.set('taxDescription', this.taxDescription)
                product.set('shippingPrice', this.shippingPrice)
                product.set('shippingDescription', this.shippingDescription)
                product.set('price', this.price)
                product.set('currency', this.currency)
                product.set('addedPriceObjs', this.addedPriceObjs)

                if (this.usedVendorViaFactory === true){ // we never make this false
                    product.set('usedVendorViaFactory', this.usedVendorViaFactory)
                }



                product.save()
                console.log("UPDATING PRODUCT OBJECT AS ")
                console.log(product.description)

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                console.log('Error while updatinh product ')
                console.log(error)

                callback(false, 'Error =' + error.message)
            });

    }



    sendForRetrial(remark, callback) {
        // change stage and update retrial count

        let previousStage = this.stage
        let previousStatus = this.status


        //TODO:- Change Order Status back to pending if needed. if any one products is not delivered
        //MARK:-


        if (this.makeAt !== null) {
            callback(false, "No Connected Factorylink set, before sending for retrial")
            return
        }


        if (this.status === enums.Status.Delivered) {
            this.status = enums.Status.Pending
        }

        this.stage = enums.Stage.Requested
        this.retrial += 1


        this.update((succ, errMsg) => {
            if (succ) {


                // DONE THE TODO : TO ADD REMARK
                // let remark = Remark.init(byUser: currentUser!, byName: currentUser!.name , product: self, type: .product, order: self.order, isIssue: false, isIssueResolved: false, isUrgent: false, forPosition: null, forUser: null, isTimeLineNode: true, remark: "For Retrial. Product sent to \(Stage.Requested). At \(makeAt).", objectId: null, locationName: currentLocation!)
                // remark.add(completion: { (succ2, err2) in
                //     if !succ2{
                //         console.log("Error while adding timeline remark after updating the product stage : " + err2)
                //     }
                // })

                let remarkWithTitle = 'SENT FOR RETRIAL: ' + remark
                let cu = getCurrentUser()
                let loc = getLocations()[0]
                let rm = new Remark(cu, cu.name, this, enums.RemarkType.product, this.order, false, null, null, false, remarkWithTitle, null, loc.name)
                rm.add((succ, msg) => {
                    if (succ) {
                        console.log('Retrial Remark is added')
                    } else {
                        console.log('Error while adding remark on retrial of product: ' + msg)
                    }
                    callback(true, "")
                })

            } else {
                // change
                this.stage = previousStage
                this.status = previousStatus
                this.retrial -= 1
                callback(false, "Error while sending product to Retrial : " + errMsg)
            }

        })


    }

    static sendMultipleProductsToNextStage(products, isVendor, vendor, ids, toStage, overrideChallan, overridePurpose, callback) {

        console.log('ATTAINED MULTIPLE PRODUCTS')
        console.log(products)


        let productWithoutObjId = products.filter((itm) => {
            return itm.objectId == null
        })

        if (isVendor !== true) {
            let prodsWithoutMakeAt = products.filter((itm) => {
                return itm.makeAt == null
            })

            if (!(prodsWithoutMakeAt.length > 0)) {
                callback(false, "Some products do not have Factory/Vendor set, where they need to be made at.")
                return
            }
        }


        console.log('PRODUCTS WITHOUT OBJECT ID')
        console.log(productWithoutObjId)

        if (productWithoutObjId && productWithoutObjId.length) {
            console.log('SENDING Missing Ids CALLBACK')

            callback(false, "Some products are new and not saved yet. Missing Ids. Please save these products first.")
            return
        }

        console.log('TST 1')

        var prIds = products.map((itm) => {
            return itm.objectId
        })
        console.log('TST 2')

        // if ids exist, they oveerride product
        if (ids && ids.length) {
            if (ids.length > 0) {
                prIds = ids
            }
        }
        console.log('TST 3')


        let stageValue = ""

        if (toStage != null){
            stageValue = toStage 
        } 

        var params = {
            "stage": stageValue,
            "productIds": prIds.join(","),
            "overrideChallanId": "",
            "overridePurpose": "",
            "vendorId": vendor ? vendor.objectId : "",
            "usedVendorViaFactory": false
        }
        console.log('TST 4')


        if (isVendor && vendor) {
            params["updateVendor"] = true
            params["vendorName"] = vendor.name
        }
        console.log('TST 5')


        if (overrideChallan !== null) {
            params["overrideChallanId"] = overrideChallan.objectId
        }

        if (overridePurpose !== null) {
            params["overridePurpose"] = overridePurpose
        }

        if (stageValue === enums.Stage.FactoryRecievedForVendor ) {
            params["usedVendorViaFactory"] = true
        }
        console.log('TST 6')

        console.log("PARAMS ARE");
        console.log(params);


        Parse.Cloud.run('transitProducts', params)
            .then((result) => {
                console.log("7");
                console.log(`Update Products Cloud Func Res is ${result ?? ""}`)
                callback(true, "")
            }).catch((error) => {
                console.log("8");

                console.log(`Send Multiple Products Func Error is : ${error.message}`)
                callback(false, error.message)
            })

    }

    sendToNextStage(fromScreen, isVendor, vendorObj, explicitNextStage, remark, callback) {

        let nextStageValForScreen = nextStageForScreen(fromScreen, isVendor)



        console.log('SENDING PRODUCT TO NEXT STAGE From Screen =' + fromScreen + ' . IS Vendor = ' + isVendor)
        console.log("Next Stage for screen")
        console.log("Next Stage for screen = " + nextStageValForScreen);


        if (isVendor) {
            if (vendorObj == null || vendorObj.objectId == null) {
                callback(false, 'Please select a vendor to send product.')
                return
            }
        }


        if (nextStageValForScreen != null) {
            let previousStage = this.stage
            let previousStatus = this.status

            if (isVendor && vendorObj) {
                // Add Vendor Object & Name
                this.vendor = vendorObj
            }


            //            console.log("Current Factory connection = \(currentLocationPrimaryLink? ?? "No Primary Link" )")
            //            console.log(self)

            // if (this.makeAt == null) {
            //     callback(false, "No Connected Factorylink set, before moving product to next stage")
            //     return
            // }

            //            console.log("Make At is set to = \(makeAt)")

            if (explicitNextStage != null) {
                // we explictly set the next stage if needed
                nextStageValForScreen = explicitNextStage
            }

            console.log("explicitNextStage = ");
            console.log(explicitNextStage);

            console.log("Upading product stage = ");
            console.log(nextStageValForScreen);


            this.stage = nextStageValForScreen

            if (nextStageValForScreen === enums.Stage.FactoryRecievedForVendor ) {
                this.usedVendorViaFactory = true
            }

            if (nextStageValForScreen === enums.Stage.Delivered) {
                this.status = enums.Status.Delivered
            }

            //TODO:- Change Order Status if all products are delivered
            //MARK:-


            this.update((succ, errMsg) => {
                if (succ) {

                    // DONE THE TODO : TO ADD REMARK
                    // let remark = Remark.init(byUser: currentUser!, byName: currentUser!.name , product: self, type: .product, order: self.order, isIssue: false, isIssueResolved: false, isUrgent: false, forPosition: null, forUser: null, isTimeLineNode: true, remark: "Product sent to \(nextStageValForScreen)", objectId: null, locationName: currentLocation!)
                    //     remark.add(completion: { (succ2, err2) in
                    //         if !succ2{
                    //             console.log("Error while adding timeline remark after updating the product stage : " + err2)
                    //         }
                    //     })

                    if (remark) {
                        let remarkWithTitle = remark
                        let cu = getCurrentUser()
                        let loc = getLocations()[0]
                        let rm = new Remark(cu, cu.name, this, enums.RemarkType.product, this.order, false, null, null, false, remarkWithTitle, null, loc.name)
                        rm.add((succ, msg) => {
                            if (succ) {
                                console.log('Stage change explicit Remark is added')
                            } else {
                                console.log('Error while adding explicit remark on product stage change: ' + msg)
                            }
                            callback(true, "")
                        })
                    } else {
                        callback(true, "")

                    }


                } else {
                    // change
                    this.stage = previousStage
                    this.status = previousStatus
                    callback(false, "Error while sending product to next stage : " + errMsg)
                }

            })


        } else {
            console.log(`No ProductStageValue for screen - ${fromScreen} to get orders`)
            callback(false, `No ProductStageValue for screen - ${fromScreen} to get orders`)

        }


    }

    addStock(stock, byUser, byEnd, callback) {
        // add stock to this Product and reduce similar quantity from the stock
        // also change that in stocks timeline

        if (stock.selectedQuantity == null) {
            callback(false, "No Selected Quantity set in stock while trying to add Stock to product")
            return
        }

        this.stocks.append(stock)
        // We need current User here

        // reduce quantity at stock
        // add stock remark
        stock.reduceStock(stock.selectedQuantity, this, byUser, byEnd, (succ, errMsg) => {
            if (succ) {
                // reduce self
                this.update((succ, errMsg) => {
                    callback(succ, errMsg)
                })

            } else {
                callback(false, errMsg)
            }
        })

    }



    removeStock(stock, byUser, byEnd, callback) {
        // remove stock from this Product and add similar quantity from the stock
        // also change that in stocks timeline

        if (stock.selectedQuantity == null) {
            callback(false, "No Selected Quantity set in stock while trying to remove Stock from product")
            return
        }


        // We need current User here

        // add back quantity at stock
        // add stock remark
        stock.addStock(stock.selectedQuantity, true, this, byUser, byEnd, (succ, errMsg) => {
            if (succ) {
                // reduce self

                //NOTE: If this does not work. REMOVAL SHOULD BE DONE BY INDEX in viewController
                let indexToRemove = this.stocks.filter((itm, index) => {
                    if (itm.objectId === stock.objectId) {
                        return index
                    }
                    return null
                })
                if (indexToRemove !== null) {
                    let thisIndex = indexToRemove[0]
                    this.stocks.splice(thisIndex, 1)
                }

                this.update((succ, errMsg) => {
                    callback(succ, "Error while updating product after removing stock from it : " + errMsg)
                })


            } else {
                callback(false, errMsg)
            }

        })

    }


    delete(callback) {

        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Product ")
        }

        let thisObj = new ProductClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting Product ', error.message)
            callback(false, error.message)
        });

    }


    static initFromPFObject(obj, forOrder, forCustomer) {

        // console.log("Incoming Product Object")
        // console.log(obj)

        //TODO:- Sample Order



        var makeAtLoc = null


        // let makeAtObj = obj.get('makeAt')

        // if (makeAtObj !== null) {
        //     let makeAts = getLocations().filter((itm) => {
        //         return itm.objectId === makeAtObj.id
        //     })

        //     if (makeAts.length > 0) {
        //         makeAtLoc = makeAts[0]
        //     }
        // }

        var custObj = obj.get('customer')
        let thisCustomer = null

        if (custObj) {
            if (custObj !== null) {
                // console.log("CUST OBJ IN PRODUCT")
                // console.log(custObj)

                let convertedCust = Customer.initFromPFObject(custObj)

                // console.log("CONVERETED CUST OBJECT INTO CUSTOMER IS")
                // console.log(convertedCust)
                thisCustomer = convertedCust
            }

        } else {
            if (forCustomer !== null) {
                thisCustomer = forCustomer
            }
        }


        let thisOrder = sampleOrder
        if (forOrder !== null) {
            thisOrder = forOrder
            if (thisCustomer === null) {
                thisCustomer = forOrder.customer
            }
        }
        if (thisCustomer === null) {
            thisCustomer = sampleCustomer
        }

        var selectedStocks = []

        // let stockS = obj.get('stocks')

        // if (stockS !== null) {

        //     for (let i = 0; i < stockS.length; i++) {
        //         const thisStock = stockS[i];
        //         let stock = Stock.initFromString(thisStock)
        //         if (stock !== null) {
        //             selectedStocks.push(stock)
        //         }
        //     }
        // }


        var challan = null
        // if (obj.get('challan') !== null) {
        //     let thisChallan = Challan.initFromPFObject(obj.get('challan'))
        //     if (thisChallan !== null) {
        //         challan = thisChallan
        //     }
        // }

        var flags = null
        // let flagObj = obj.get('flags')
        // if (flagObj !== null) {
        //     if (flagObj.length > 0) {
        //         flags = flagObj.map((itm) => {
        //             return itm
        //         })
        //     }
        // }


        let allUsers = getUsers()

        var assignToUsr = null
        // let existingAssignUser = obj.get('assignTo')
        // if (existingAssignUser !== null) {
        //     let matchingUser = allUsers.filter((itm) => {
        //         return itm.objectId === existingAssignUser.id
        //     })

        //     if (matchingUser.length > 0) {
        //         assignToUsr = matchingUser[0]
        //     } else {
        //         if (existingAssignUser.isDataAvailable) {
        //             let thisUser = User.initFromPFObject(existingAssignUser, true)
        //             if (thisUser !== null) {
        //                 assignToUsr = thisUser
        //             }
        //         }
        //     }
        // }


        var isCancelled = null
        let icObj = obj.get('isCancelled')
        if (icObj !== null) {
            isCancelled = icObj
        }

        var byUsr = null
        let addedByObj = obj.get('addedByUser')
        if (addedByObj !== null) {
            let matchingUsers = allUsers.filter((itm) => {
                return itm.objectId === addedByObj.id
            })

            if (matchingUsers.length > 0) {
                byUsr = matchingUsers[0]
            } else if (addedByObj.isDataAvailable === true) {
                let thisUser = User.initFromPFObject(addedByObj, true)
                if (thisUser !== null) {
                    byUsr = thisUser
                }
            }
        }



        let orderedAtLocObj = obj.get('orderedAt')

        // console.log("ORDERED AT LOC IN INIT IS")
        // console.log(orderedAtLoc)

        let orderedAtLocArr = getLocations().filter((thisLoc) => { return thisLoc.objectId === orderedAtLocObj.id })
        let orderedAtLoc = orderedAtLocArr[0] ?? null

        var cancelledBy = null
        // let cancelledByObj = obj.get('cancelledBy')

        // if (cancelledByObj !== null) {
        //     let matchingUsers = allUsers.filter((itm) => {
        //         return itm.objectId === cancelledByObj.id
        //     })
        //     if (matchingUsers.length > 0) {
        //         cancelledBy = matchingUsers[0]
        //     } else if (cancelledByObj.isDataAvailable === true) {
        //         let thisUser = User.initFromPFObject(cancelledByObj, true)
        //         if (thisUser !== null) {
        //             cancelledBy = thisUser
        //         }
        //     }
        // }

        let cancellationInfo = obj.get('cancellationInfo')
        var groupId = obj.get('groupId')
        var groupType = obj.get('groupType')

        //        console.log("\n\n\n\n H:Type of Product is - \(obj["type"] as! String) ")

        let product = new Product(thisCustomer,
            thisOrder,
            obj.get('productId'),
            obj.get('type'),
            obj.get('stage'),
            obj.get('status'),
            obj.get('purpose'),
            obj.get('picsCount'),
            obj.get('measurementsCount'),
            obj.get('remarksCount'),
            obj.get('trialDate'),
            obj.get('deliveryDate'),
            obj.get('retrial'),
            obj.get('finishing'),
            selectedStocks,
            byUsr,
            obj.get('fromStoreSection'),
            obj.get('saleType'),
            obj.get('styleNo'),
            challan,
            flags,
            assignToUsr,
            obj.id,
            orderedAtLoc,
            makeAtLoc,
            obj.get('isGrouped')
        )

        if (obj.get('code') !== null) {
            product.code = obj.get('code')
        }

        if (obj.get('description') !== null) {
            product.description = obj.get('description')
        }
        if (obj.get('imageUrl') !== null) {
            product.imageUrl = obj.get('imageUrl')
        }
        if (obj.get('imagesUrls') !== null) {
            product.imagesUrls = obj.get('imagesUrls')
        }

        if (obj.get('isViaConcierge') !== null) {
            product.isViaConcierge = obj.get('isViaConcierge')
        }
        if (obj.get('conciergeDisplayNote') !== null) {
            product.conciergeDisplayNote = obj.get('conciergeDisplayNote')
        }
        if (obj.get('conciergeStyleDetails') !== null) {
            product.conciergeStyleDetails = obj.get('conciergeStyleDetails')
        }
        if (obj.get('isBespoke') !== null) {
            product.isBespoke = obj.get('isBespoke')
        }
        if (obj.get('isMTO') !== null) {
            product.isMTO = obj.get('isMTO')
        }

        if (obj.get('conciergeSelections') !== null) {
            product.conciergeSelections = obj.get('conciergeSelections')
        }
        if (obj.get('styleSelections') !== null) {
            product.styleSelections = obj.get('styleSelections')
        }
        if (obj.get('materialToSource') !== null) {
            product.materialToSource = obj.get('materialToSource')
        }
        if (obj.get('neededMeasures') !== null) {
            product.neededMeasures = obj.get('neededMeasures')
        }
        if (obj.get('measures') !== null) {
            product.measures = obj.get('measures')
        }

        if (obj.get('basePrice') !== null) {
            product.basePrice = obj.get('basePrice')
        }
        if (obj.get('addOnPrice') !== null) {
            product.addOnPrice = obj.get('addOnPrice')
        }
        if (obj.get('taxOnPrice') !== null) {
            product.taxOnPrice = obj.get('taxOnPrice')
        }
        if (obj.get('taxDescription') !== null) {
            product.taxDescription = obj.get('taxDescription')
        }
        if (obj.get('shippingPrice') !== null) {
            product.shippingPrice = obj.get('shippingPrice')
        }
        if (obj.get('shippingDescription') !== null) {
            product.shippingDescription = obj.get('shippingDescription')
        }
        if (obj.get('price') !== null) {
            product.price = obj.get('price')
        }
        if (obj.get('currency') !== null) {
            product.currency = obj.get('currency')
        }
        if (obj.get('addedPriceObjs') !== null) {
            product.addedPriceObjs = obj.get('addedPriceObjs')
        }



        let vendor = null
        if (obj.get('vendor') != null) {
            let thisVendor = Vendor.initFromPFObject(obj.get('vendor'))
            if (thisVendor !== null) {
                vendor = thisVendor
            }
        }

        if (obj.get('vendorName') != null) {
            product.vendorName = obj.get('vendorName')
        } else {
            if (vendor != null && vendor.name != null) {
                product.vendorName = vendor.name
            }
        }

        product.vendor = vendor

        product.groupId = groupId
        product.groupType = groupType

        product.isCancelled = isCancelled
        // product.cancelledBy = cancelledBy
        product.cancellationInfo = cancellationInfo

        return product
    }

    static getSelected(forOrder, isVendor, screen, callback) {

        console.log(' SCREEN =' + screen)
        

        let stageValForScreen = stageValueFor(screen, isVendor)

        console.log('STAGA VAL FOR SCREEN =' + stageValForScreen)

        if (stageValForScreen !== null) {
            let productFilter = ProductFilter.init()


            if (screen === enums.screen.ApproveTrial) {
                productFilter.stages = [enums.Stage.RecievedFromFactory, enums.Stage.RecievedFromVendor]
            } else if (screen === enums.screen.RecieveOrders_Store_Vendor || screen === enums.screen.RecieveOrders_FactoryVendorWindow) {
                productFilter.stages = [enums.Stage.VendorSent, enums.Stage.SentToVendor, enums.Stage.VendorRecieved, enums.Stage.VendorComplete]
            } else {
                productFilter.stage = stageValForScreen
            }

            // if (screen === enums.screen.RecieveOrders_FactoryVendorWindow ){
            //     productFilter.usedVendorViaFactory = true
            // }



            console.log('PRODUCT FILTER =')
            console.log(productFilter)


            // let currentloc = currentLocation

            // if (currentloc == null) {
            //     callback(false, [], "No Current Location while trying to find Orderd for selected screen")
            // }

            // if (currentloc.type === LocationType.store) {
            //     productFilter.orderedAt = currentloc
            // }

            // if (currentloc.type === LocationType.factory) {
            //     productFilter.makeAt = currentloc
            // }

            Product.getAll(forOrder, productFilter, null, (succ, products, errMsg) => {
                callback(succ, products, errMsg)
            })


        } else {

            Product.getAll(forOrder, null, null, (succ, products, errMsg) => {
                callback(succ, products, errMsg)
            })
            //            completion(true,[], "")
            console.log(`No ProductStageValue for screen - ${screen} to get orders`)
        }

    }


    static getAll(forOrder, productFilter, withChallan, callback) {


        var query = new Parse.Query(ProductClass);
        query.ascending('createdAt')
        // query.include('challan')
        // query.include('addedByUser')
        // query.include('assignTo')


        console.log("FINDING THIS ROUTE")

        let currentUser = getCurrentUser()
        let currentLocation = getCurrentLocation()

        if (productFilter === null) {
            productFilter = ProductFilter.init()
        }

        if (currentUser.isAgent === true) {
            productFilter.addedByUser = currentUser
            console.log("ADDED BY AGENT IS SET")
        }

        if (currentLocation.isMain !== true && currentLocation.type !== LocationType.factory) {
            productFilter.orderedAt = currentLocation
            console.log("ORDERED AT THIS LOCATION IS SET")
        }


        if (productFilter !== null) {

            console.log("PF NOT NULL")
            console.log(productFilter)


            if (productFilter.stage != null) {
                query.equalTo("stage", productFilter.stage)
            }

            // BOTH ORDRS, Recieved from Vendors & Factory
            if (productFilter.stages != null) {
                query.containedIn('stage', productFilter.stages)
            }

            if (productFilter.objectId) {
                query.equalTo("objectId", productFilter.objectId)
            }


            /// HERE

            if (productFilter.orderedAt) {
                let orderedAtPointer = {
                    __type: 'Pointer',
                    className: 'Location',
                    objectId: productFilter.orderedAt.objectId
                }

                query.equalTo("orderedAt", orderedAtPointer)
                console.log("Ordered At set in query")
            }


            if (productFilter.vendor) {
                let vendorPointer = {
                    __type: 'Pointer',
                    className: 'Vendor',
                    objectId: productFilter.vendor.objectId
                }

                query.equalTo("vendor", vendorPointer)
            }


            if (productFilter.makeAt) {
                // query.equalTo("makeAt", productFilter.makeAt.toPFObject())
            }

            if (productFilter.productId) {
                query.equalTo("productId", productFilter.productId)
            }



            // Added By
            if (productFilter.addedByUser) {
                let addedByPointer = {
                    __type: 'Pointer',
                    className: 'Users',
                    objectId: productFilter.addedByUser.objectId
                }
                query.equalTo('addedByUser', addedByPointer)
            }


            // Cancelled
            if (productFilter.isCancelled !== null) {
                if (productFilter.isCancelled === true) {
                    query.equalTo("isCancelled", true)

                } else {
                    query.notEqualTo("isCancelled", true)
                }
            }

            // Cancelled By
            if (productFilter.cancelledBy) {
                // query.equalTo("cancelledBy", productFilter.cancelledBy.toPFObject())
            }



            if (productFilter.type != null) {
                query.equalTo("type", productFilter.type)
            }

            if (productFilter.status != null) {
                query.equalTo("status", productFilter.status)
            }

            if (productFilter.purpose != null) {
                query.equalTo("purpose", productFilter.purpose)
            }

            if (productFilter.saleType != null) {
                query.equalTo("saleType", productFilter.saleType)
            }

            if (productFilter.styleNo != null) {
                query.equalTo("styleNo", productFilter.styleNo)
            }

            if (productFilter.fromStoreSection != null) {
                query.equalTo("fromStoreSection", productFilter.fromStoreSection)
            }

            if (productFilter.challanNo != null) {
                var challanQuery = new Parse.Query(ChallanClass);
                challanQuery.equalTo("challanNo", productFilter.challanNo)
                query.matchesQuery("challan", challanQuery)
            }


            // Trial Date
            if (productFilter.trialDate != null) {
                if (productFilter.trialDate.from !== null) {
                    query.greaterThanOrEqualTo("trialDate", productFilter.trialDate.from)
                }

                if (productFilter.trialDate.to != null) {
                    query.lessThanOrEqualTo("trialDate", productFilter.trialDate.to)
                }
            }



            // Delivery Date
            if (productFilter.deliveryDate != null) {
                if (productFilter.deliveryDate.from !== null) {
                    query.greaterThanOrEqualTo("deliveryDate", productFilter.deliveryDate.from)
                }

                if (productFilter.deliveryDate.to != null) {
                    query.lessThanOrEqualTo("deliveryDate", productFilter.deliveryDate.to)
                }
            }



            // retrial Date
            if (productFilter.retrial != null) {
                if (productFilter.retrial.from != null) {
                    query.greaterThanOrEqualTo("retrial", productFilter.retrial.from)
                }

                if (productFilter.retrial.to != null) {
                    query.lessThanOrEqualTo("retrial", productFilter.retrial.to)
                }
            }


            // finishing Date
            if (productFilter.finishing != null) {
                if (productFilter.finishing.from !== null) {
                    query.greaterThanOrEqualTo("finishing", productFilter.finishing.from)
                }

                if (productFilter.finishing.to !== null) {
                    query.lessThanOrEqualTo("finishing", productFilter.finishing.to)
                }
            }




            if (productFilter.addedByUser != null) {
                let addedByPointer = {
                    __type: 'Pointer',
                    className: 'Users',
                    objectId: productFilter.addedByUser.objectId
                }
                query.equalTo('addedByUser', addedByPointer)
            }



            if (productFilter.usedVendorViaFactory != null) {
                query.equalTo("usedVendorViaFactory", true)
            }


            if (productFilter.assignTo != null) {
                // query.equalTo("assignTo", productFilter.assignTo.toPFObject())
            }

        }

        if (forOrder !== null) {
            let orderPointer = {
                __type: 'Pointer',
                className: 'Order',
                objectId: forOrder.objectId
            }
            query.equalTo('order', orderPointer)
        }

        if (withChallan !== null) {
            // query.equalTo("challan", withChallan.toPFObject())
        }


        console.log('PRODUCT QUERY =')
        console.log(query)

        query.find().then((objects) => {
            console.log('Products =')
            console.log(objects)
            if (objects.length > 0) {

                let allProducts = []
                for (let i = 0; i < objects.length; i++) {
                    const thisProdObj = objects[i];
                    let thisProduct = Product.initFromPFObject(thisProdObj, forOrder, null)

                    console.log('CONVERTED PRODUCT = ')
                    console.log(thisProduct)

                    if (thisProduct != null) {
                        allProducts.push(thisProduct)
                    }
                }
                callback(true, allProducts, '')
            } else {
                callback(true, [], "No Products")
            }

        }, (error) => {
            console.log('Error while getting all Products ', error.message)
            callback(false, null, error.message)
        })


    }

    toPFObject() {
        let thisObj = new ProductClass()
        thisObj.id = this.objectId
        return thisObj
    }

    static updateAll(products, ids, toStage, trialDate, deliveryDate, callback) {

        let productWithoutObjId = products.filter((itm) => {
            return itm.objectId == null
        })


        if (productWithoutObjId.length > 0) {
            callback(false, "Some products are new and not saved yet. Missing devIds. Please save these products first.")
            return
        }


        var prIds = products.map((itm) => { return itm.objectId })

        // if ids exist, they oveerride product
        if (prIds.length > 0) {
            prIds = ids
        }


        var params = { "productIds": prIds.join(",") }

        if (trialDate !== null) {
            params["updateAttribute"] = "trialDate"
            params["updateVal"] = trialDate
        }

        if (deliveryDate !== null) {
            params["updateAttribute"] = "deliveryDate"
            params["updateVal"] = deliveryDate
        }

        Parse.Cloud.run('updateProducts', params)
            .then((result) => {
                console.log(`Update Products Cloud Func Res is ${result ?? ""}`)
                callback(true, "")
            }).catch((error) => {
                console.log(`Update Products Cloud Func Error is : ${error.localizedDescription}`)
                callback(false, error.localizedDescription)
            })

    }


}



//Product Pics
export class ProductPic {

    constructor(product, image, url, file, objectId) {
        this.product = product
        this.image = image
        this.url = url
        this.file = file
        this.objectId = objectId
    }

    static copyFrom(obj) {
        let nPP = new ProductPic(null, null, null, null, null)
        nPP.product = obj.product
        nPP.image = obj.image
        nPP.url = obj.url
        nPP.file = obj.file
        nPP.objectId = obj.objectId
        return nPP
    }


    static initFromPFObject(obj, product) {
        var randomProduct = product ?? sampleProduct
        let existingPro = obj.get('product')
        if (existingPro != null) {
            randomProduct.objectId = existingPro.id
            if (existingPro.isDataAvailable === true) {
                // init User from PFObject and show here
                let thisPro = Product.initFromPFObject(existingPro, null, null)
                if (thisPro != null) {
                    randomProduct = thisPro
                }
            }
        }
        let url = obj.get('url')

        if (url == null) {
            console.log("No Url in ProductPic object.")
            return null
        }

        let objId = obj.id
        let pp = new ProductPic(randomProduct, null, url, null, objId)
        return pp

    }


    add(callback) {

        // let image = this.image 
        if (this.file == null) {
            callback(false, 'No ProductPic File to add.')
        }


        provideCompressedFile(this.file, 600, 0.5, (succ, file) => {

            if (succ) {
                console.log('UPLOADING COMPRESSED FILE')
            } else {
                cogoToast.warn('This image could not be compressed. Upload will take time.')
                console.log('UPLOADING ORGINAL FILE')
            }


            var name = `productPhoto${this.productId ?? 'NoProdId'}.jpg`

            var parseFile = new Parse.File(name, file);

            parseFile.save()
                .then((file) => {
                    // The file has been saved to Parse.

                    let ob = new ProductPicClass()
                    let productPointer = {
                        __type: 'Pointer',
                        className: 'Product',
                        objectId: this.product.objectId
                    }
                    ob.set('product', productPointer)
                    ob.set('image', file)

                    let fileUrl = file.url()
                    if (fileUrl != null) {
                        console.log("Uploaded file url = " + file)
                        ob.set('url', fileUrl)
                        this.url = fileUrl
                    } else {
                        console.log("Could not get product file url.")
                    }

                    ob.save()
                        .then((pp) => {
                            console.log(" PRODUCT PIC OBJECT CREATED WITH ID = " + pp.id)

                            this.objectId = pp.id
                            callback(true, this, '')
                        }, (error) => {
                            console.log('Failed to create new ProducPic, after file was uploaded with error code: ' + error.message);
                            callback(false, null, error.message)
                        })


                }, (error) => {
                    // The file either could not be read, or could not be saved to Parse.
                    callback(false, "Error while uploading ProductPic file" + error.message)

                });

        })

    }



    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Product Pic ")
        }

        let thisObj = new ProductPicClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting Product Pic ', error.message)
            callback(false, error.message)
        });

    }


    static getAll(forProduct, callback) {

        var query = new Parse.Query(ProductPicClass);
        query.ascending('createdAt')

        let productPointer = {
            __type: 'Pointer',
            className: 'Product',
            objectId: forProduct.objectId
        }
        query.equalTo('product', productPointer)


        query.find().then((objects) => {
            console.log('Products Pics =')
            console.log(objects)
            if (objects.length > 0) {

                let allProductPics = []
                for (let i = 0; i < objects.length; i++) {
                    const thisProdPicObj = objects[i];
                    let thisProductPic = ProductPic.initFromPFObject(thisProdPicObj, forProduct)
                    if (thisProductPic !== null) {
                        allProductPics.push(thisProductPic)
                    }
                }
                callback(true, allProductPics, '')
            } else {
                callback(true, [], "No Products Pics")
            }

        }, (error) => {
            console.log('Error while getting all Product Pics ', error.message)
            callback(false, null, error.message)
        })

    }


}


//Customer Pics
export class CustomerPic {

    constructor(customer, image, url, file, objectId) {
        this.customer = customer
        this.image = image
        this.url = url
        this.file = file
        this.objectId = objectId
    }

    static copyFrom(obj) {
        let nPP = new CustomerPic(null, null, null, null, null)
        nPP.customer = obj.customer
        nPP.image = obj.image
        nPP.url = obj.url
        nPP.file = obj.file
        nPP.objectId = obj.objectId
        return nPP
    }



    static initFromPFObject(obj, customer) {

        var randomCustomer = customer ?? sampleCustomer

        // if (existingCust != null) {
        //     randomCustomer.objectId = existingCust.id

        //     if (existingCust.isDataAvailable != null) {
        //         // init User from PFObject and show here
        //         // let thisCust = Customer.initFromPFObject(existingCust)
        //         if (thisCust != null) {
        //             randomCustomer = thisCust
        //         }
        //     }
        // }

        let url = obj.get('url')
        if (url == null) {
            console.log("No Url in CustomerPic object.")
            return null
        }

        let objId = obj.id
        let pp = new CustomerPic(randomCustomer, null, url, null, objId)

        return pp
    }


    add(callback) {
        // let image = this.image 
        if (this.file == null) {
            callback(false, 'No CustomerPic File to add.')
        }

        provideCompressedFile(this.file, 600, 0.5, (succ, file) => {

            if (succ) {
                console.log('UPLOADING COMPRESSED FILE')
            } else {
                cogoToast.warn('This image could not be compressed. Upload will take time.')
                console.log('UPLOADING ORGINAL FILE')
            }


            var name = `customerPhoto${this.customer.name ?? 'NoCustName'}.png`

            var parseFile = new Parse.File(name, file, "image/png");

            parseFile.save()
                .then((file) => {
                    // The file has been saved to Parse.

                    let ob = new CustomerPicClass()

                    let customerPointer = {
                        __type: 'Pointer',
                        className: 'Customer',
                        objectId: this.customer.objectId
                    }

                    ob.set('customer', customerPointer)
                    ob.set('image', file)

                    let fileUrl = file.url()
                    if (fileUrl != null) {
                        console.log("Uploaded file url = " + file)
                        ob.set('url', fileUrl)
                        this.url = fileUrl
                    } else {
                        console.log("Could not get customer file url.")
                    }


                    console.log("SAVING CUSTOMER PIC OBJECT")

                    ob.save()
                        .then((cp) => {
                            console.log(" CUSTOMER PIC OBJECT CREATED WITH ID = " + cp.id)

                            this.objectId = cp.id
                            callback(true, this, '')
                        }, (error) => {
                            console.log('Failed to create new CustomerPic, after file was uploaded with error code: ' + error.message);
                            callback(false, null, error.message)
                        })

                }, (error) => {
                    // The file either could not be read, or could not be saved to Parse.
                    callback(false, "Error while uploading CustomerPic file" + error.message)

                });


        })



    }



    delete(callback) {

        if (this.objectId == null) {
            callback(false, "Error : No object id to delete CustomerPic ")
        }

        let thisObj = new CustomerPicClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting CustomerPic ', error.message)
            callback(false, error.message)
        });
    }





    //     static func findCount(forCustomer: Customer, completion: @escaping(_ success: Bool, _ result: Int ? , _ errorMsg: String) -> ()) {
    //     let query = PFQuery(className: "CustomerPic")
    //     query.order(byAscending: "createdAt")

    //     query.whereKey("customer", equalTo: forCustomer.toPFObject())

    //     query.countObjectsInBackground {
    //         (count: Int32, error: Error?) in
    //             if error == null {
    //             completion(true, Int(count), "")

    //         } else {
    //             completion(false, null, error?.localizedDescription ?? "Error")
    //         }

    //     }
    // }



    static getAll(forCustomer, callback) {

        console.log('SEARCHING CUSTOMER PICS FOR CUSTOMER')
        console.log(forCustomer.objectId)

        let customerPointer = {
            __type: 'Pointer',
            className: 'Customer',
            objectId: forCustomer.objectId
        }



        var query = new Parse.Query(CustomerPicClass);
        query.ascending('createdAt')
        query.include('url')
        query.equalTo("customer", customerPointer)



        query.find().then((objects) => {
            console.log('Customer Pics =')
            console.log(objects)
            if (objects.length > 0) {

                let allCustPics = []
                for (let i = 0; i < objects.length; i++) {
                    const thisCustPicObj = objects[i];
                    let thisCustPic = CustomerPic.initFromPFObject(thisCustPicObj, forCustomer)
                    if (thisCustPic !== null) {
                        allCustPics.push(thisCustPic)
                    }

                }

                console.log('SENDING THESE CUSTOMER PICS ')
                console.log(allCustPics)

                callback(true, allCustPics, '')
            } else {
                callback(true, [], "No Customer Pics")
            }

        }, (error) => {
            console.log('Error while getting all Customer Pics ', error.message)
            callback(false, null, error.message)
        })
    }

}


export class StyleSelection {

    constructor(name, key, options, applicableOn, iconImageUrl, imageUrl, objectId) {
        this.name = name  // string
        this.key = key  // key has to be unique
        this.options = options // [string]
        this.applicableOn = applicableOn  // [product]   ... General Product type .. suit, shirt pant.. not king of suit/shirt/pant
        this.iconImageUrl = iconImageUrl // url
        this.imageUrl = imageUrl  // url
        this.objectId = objectId
    }

    static init() {
        let ss = new this(null, [], [], null, null, null, null)
        return ss
    }

    static copyFrom(obj) {
        let ss = StyleSelection.init()
        ss.name = obj.name  // string
        ss.key = obj.key  // key has to be unique
        ss.options = obj.options // [string]
        ss.applicableOn = obj.applicableOn  // [product]   ... General Product type .. suit, shirt pant.. not king of suit/shirt/pant
        ss.iconImageUrl = obj.iconImageUrl // url
        ss.imageUrl = obj.imageUrl  // url
        ss.objectId = obj.objectId
        return ss
    }


    static initFromPFObject(obj) {
        let name = obj.get('name')
        let key = obj.get('key')
        let options = obj.get('options')
        let applicableOn = obj.get('applicableOn')

        let iconImageUrl = obj.get('iconImageUrl')
        let imageUrl = obj.get('imageUrl')
        let sl = new this(name, key, options, applicableOn, iconImageUrl, imageUrl, obj.id)

        return sl

    }


    // Add
    add(callback) {
        let ob = new StyleSelectionClass()
        ob.set('name', this.name)
        ob.set('key', this.key)
        ob.set('iconImageUrl', this.iconImageUrl)
        ob.set('imageUrl', this.imageUrl)
        ob.set('applicableOn', this.applicableOn)
        ob.set('options', this.options)

        ob.save()
            .then((ss) => {
                this.objectId = ss.id
                console.log('New Style Selection Created')

                callback(true, '')
            }, (error) => {
                console.log('Failed to create new Style Selection, with error code: ' + error.message);
                callback(false, error.message)
            })
    }

    // Update
    update(callback) {
        if (this.objectId == null) {
            console.log("Error : No object id to update Style Selection")
            callback(false, 'Error : No object id to update Style Selection')
        }

        var query = new Parse.Query(StyleSelectionClass);
        query.get(this.objectId)
            .then((ob) => {
                ob.set('name', this.name)
                ob.set('key', this.key)
                ob.set('iconImageUrl', this.iconImageUrl)
                ob.set('imageUrl', this.imageUrl)
                ob.set('applicableOn', this.applicableOn)
                ob.set('options', this.options)
                ob.save()
                callback(true, '')
            }, (error) => {
                callback(false, 'Error =' + error.message)
            });

    }

    // Get All or for Applicable On Particular Product
    static getAll(applicableOn = [], callback) {

        var query = new Parse.Query(StyleSelectionClass);
        query.ascending('createdAt')
        query.limit(1000)

        if (applicableOn) {
            if (applicableOn.length) {
                query.containedIn("applicableOn", applicableOn)
            }
        }

        query.find().then((objects) => {
            console.log('Style Selections =')
            console.log(objects)
            if (objects.length > 0) {

                let allSS = []
                for (let i = 0; i < objects.length; i++) {
                    const thisSSObj = objects[i];
                    let thisSS = StyleSelection.initFromPFObject(thisSSObj)
                    if (thisSS !== null) {
                        allSS.push(thisSS)
                    }
                }
                callback(true, allSS, '')
            } else {
                callback(true, [], "No Style Selections")
            }
        }, (error) => {
            console.log('Error while getting all Style Selections ', error.message)
            callback(false, null, error.message)
        })
    }

    toPFObject() {
        let thisObj = new StyleSelectionClass()
        thisObj.id = this.objectId
        return thisObj
    }

    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete StyleSelection ")
        }
        let thisObj = new StyleSelectionClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((ss) => {
            callback(true, "")
        }, (error) => {
            console.log('Error while deleting Style Selection ', error.message)
            callback(false, error.message)
        });
    }

}



// Measurement
export class Measure {

    constructor(name, unit, defaultValue, value) {
        this.name = name
        this.unit = unit
        this.defaultValue = defaultValue
        this.value = value
        if (value == null) {
            this.value = defaultValue
        }

        this.autoFromMeasureName = ""
        this.autoRegularAddVal = 0
        this.autoSlimAddVal = 0
        this.autoComfortAddVal = 0
        this.isAuto = false
        this.isAutoCreateSuccess = null

    }

    static copyFrom(obj) {
        let nm = new Measure("", enums.MeasurementUnits.inches, 0, 0)
        nm.name = obj.name
        nm.unit = obj.unit
        nm.defaultValue = obj.defaultValue
        nm.value = obj.value
        if (obj.value == null) {
            nm.value = obj.defaultValue
        }
        nm.autoFromMeasureName = obj.autoFromMeasureName ?? ""
        nm.autoRegularAddVal = obj.autoRegularAddVal ?? 0
        nm.autoSlimAddVal = obj.autoSlimAddVal ?? 0
        nm.autoComfortAddVal = obj.autoComfortAddVal ?? 0
        nm.isAuto = obj.isAuto ?? false
        nm.isAutoCreateSuccess = obj.isAutoCreateSuccess ?? null
        return nm
    }


    static measure_toString(ms) {
        let this_autoFromMeasureName = ms.autoFromMeasureName ?? ""
        let this_autoRegularAddVal = (ms.autoRegularAddVal !== null) ? `${Number(ms.autoRegularAddVal).toFixed(2)}` : "0"
        let this_autoSlimAddVal = (ms.autoSlimAddVal != null) ? `${Number(ms.autoSlimAddVal).toFixed(2)}` : "0"
        let this_autoComfortAddVal = (ms.autoComfortAddVal != null) ? `${Number(ms.autoComfortAddVal).toFixed(2)}` : "0"

        let str = `name:${ms.name}:name,unit:${ms.unit}:unit,defaultValue:${ms.defaultValue}:defaultValue,value:${ms.value ?? ms.defaultValue}:value,autoFromMeasureName:${this_autoFromMeasureName}:autoFromMeasureName,autoRegularAddVal:${this_autoRegularAddVal}:autoRegularAddVal,autoSlimAddVal:${this_autoSlimAddVal}:autoSlimAddVal,autoComfortAddVal:${this_autoComfortAddVal}:autoComfortAddVal`
        return str

        // let str = `name:${ms.name}:name,unit:${ms.unit}:unit,defaultValue:${ms.defaultValue}:defaultValue,value:${ms.value ?? ms.defaultValue}:value`
        // return str
    }

    static measure_description(ms) {
        let str = `${ms.name}  =   ${ms.value ?? ms.defaultValue} ${ms.unit}`
        return str
    }

    toString() {
        let this_autoFromMeasureName = this.autoFromMeasureName ?? ""
        let this_autoRegularAddVal = (this.autoRegularAddVal !== null) ? `${Number(this.autoRegularAddVal).toFixed(2)}` : "0"
        let this_autoSlimAddVal = (this.autoSlimAddVal != null) ? `${Number(this.autoSlimAddVal).toFixed(2)}` : "0"
        let this_autoComfortAddVal = (this.autoComfortAddVal != null) ? `${Number(this.autoComfortAddVal).toFixed(2)}` : "0"

        let str = `name:${this.name}:name,unit:${this.unit}:unit,defaultValue:${this.defaultValue}:defaultValue,value:${this.value ?? this.defaultValue}:value,autoFromMeasureName:${this_autoFromMeasureName}:autoFromMeasureName,autoRegularAddVal:${this_autoRegularAddVal}:autoRegularAddVal,autoSlimAddVal:${this_autoSlimAddVal}:autoSlimAddVal,autoComfortAddVal:${this_autoComfortAddVal}:autoComfortAddVal`
        return str

        // let str = `name:${this.name}:name,unit:${this.unit}:unit,defaultValue:${this.defaultValue}:defaultValue,value:${this.value ?? this.defaultValue}:value`
        // return str
    }

    description() {
        let str = `${this.name}  =   ${this.value ?? this.defaultValue} ${this.unit}`
        return str
    }

    static initFromString(fromString) {

        if (typeof fromString !== "string"){
            console.log("FROM STRING IS NOT A STRING")
            console.log(fromString)
            return null
        }

        //TODO: Add throws .. if this initialisation fails.

        // console.log("Converting fromString.")
        // console.log(fromString)

        if (fromString == null) {
            console.log("Cannot initialise Measure because String is empty")
        }


        let string = fromString

        let name = getStringInMiddleOf(string, "name")
        let unitStr = getStringInMiddleOf(string, "unit")
        let unit = unitStr
        let defaultValueStr = getStringInMiddleOf(string, "defaultValue")
        let defaultValue = Number(defaultValueStr).toFixed(2)

        let valueStr = getStringInMiddleOf(string, "value")
        let value = Number(valueStr).toFixed(2)

        let thisMeasure = new Measure(name, unit, defaultValue, value)


        var thisAutoFromName = ""
        var regVal = 0
        var slimVal = 0
        var comfortVal = 0

        let autoFromMeasureName = getStringInMiddleOf(string, "autoFromMeasureName")
        if (autoFromMeasureName !== null && autoFromMeasureName !== "") {
            thisMeasure.autoFromMeasureName = autoFromMeasureName
            thisAutoFromName = autoFromMeasureName
        }

        let autoRegularAddVal = getStringInMiddleOf(string, "autoRegularAddVal")
        if (Number(autoRegularAddVal) !== null && Number(autoRegularAddVal) !== NaN && Number(autoRegularAddVal) !== "NaN") {
            let thisVal = Number(autoRegularAddVal).toFixed(2)
            thisMeasure.autoRegularAddVal = thisVal
            regVal = thisVal
        }

        let autoSlimAddVal = getStringInMiddleOf(string, "autoSlimAddVal")
        if (Number(autoSlimAddVal) !== null && Number(autoSlimAddVal) !== NaN && Number(autoSlimAddVal) !== "NaN") {
            let thisVal = Number(autoSlimAddVal).toFixed(2)
            thisMeasure.autoSlimAddVal = thisVal
            slimVal = thisVal
        }


        let autoComfortAddVal = getStringInMiddleOf(string, "autoComfortAddVal")
        if (Number(autoComfortAddVal) !== null && Number(autoComfortAddVal) !== NaN && Number(autoComfortAddVal) !== "NaN") {
            let thisVal = Number(autoComfortAddVal).toFixed(2)
            thisMeasure.autoComfortAddVal = thisVal
            comfortVal = thisVal
        }


        if (thisAutoFromName !== "") {
            if (regVal !== null && slimVal !== null && comfortVal !== null) {
                if (regVal !== 0.0 || slimVal !== 0.0 || comfortVal !== 0.0) {
                    thisMeasure.isAuto = true
                }
            }
        }


        // console.log("After conversion from string.")
        // console.log(thisMeasure)



        return thisMeasure
    }


}

export class Measurement {

    constructor(type, name, customer, product, measures, objectId) {
        this.type = type
        this.name = name
        this.customer = customer
        this.product = product
        this.measures = measures
        this.objectId = objectId
        this.isConciergeMeasurement = false
        this.order = null  // for Concierge Order Measurement
        this.fitPreference = null  // Currently Not in use
    }


    static init(type) {
        let mt = new this(type, '', null, null, [], null)
        return mt
    }

    static copyFrom(obj) {
        let mt = Measurement.init(obj.type)
        mt.type = obj.type
        mt.name = obj.name
        mt.customer = obj.customer
        mt.product = obj.product
        mt.measures = obj.measures
        mt.objectId = obj.objectId
        mt.isConciergeMeasurement = obj.isConciergeMeasurement
        mt.order = obj.order  // for Concierge Order Measurement
        mt.fitPreference = obj.fitPreference  // Currently Not in use
        return mt
    }

    static initFromPFObject(obj, customer, product) {

        let name = obj.get('name')
        let type = obj.get('type')

        let measuresStrArray = obj.get('measures')
        // as we will always load measurements under either a customer or product, we will always have it
        //        if type == .body {
        //            guard let custObj = obj["cutsomer"] as? PFObject, let cust = Customer.initFromPFObject(obj: custObj) else{
        //                return null
        //            }
        //            customer = cust
        //        }else {
        //            guard let custObj = obj["product"] as? PFObject, let cust = Customer.initFromPFObject(obj: custObj) else{
        //                return null
        //            }
        //            customer = cust
        //        }

        var measures = []
        measuresStrArray.forEach(ms => {
            let mso = Measure.initFromString(ms)
            if (mso !== null) {
                measures.push(mso)
            }
        });


        let measurement = new Measurement(type, name, customer, product, measures, obj.id)


        measurement.isConciergeMeasurement = obj.get('isConciergeMeasurement') ?? false
        measurement.order = obj.get('order') ?? null
        measurement.fitPreference = obj["fitPreference"] ?? null


        // console.log('Sending Measuement')
        // console.log(measurement)
        return measurement
    }


    add(callback) {

        let ob = new MeasurementClass()
        ob.set('type', this.type)
        ob.set('name', this.name)

        if (this.type === enums.MeasurementType.body) {
            // set customer
            if (this.customer == null) {
                callback(false, "Did not find Customer for Body-Measurement")
                return
            }
            let customerPointer = {
                __type: 'Pointer',
                className: 'Customer',
                objectId: this.customer.objectId
            }
            ob.set('customer', customerPointer)
        }

        if (this.type === enums.MeasurementType.product) {
            // set customer
            if (this.product == null) {
                callback(false, "Did not find Product for Product-Measurement")
                return
            }
            let pointer = {
                __type: 'Pointer',
                className: 'Product',
                objectId: this.product.objectId
            }
            ob.set('product', pointer)

        }

        var measureStrArray = []

        this.measures.forEach(ms => {
            measureStrArray.push(ms.toString())
        })

        ob.set('measures', measureStrArray)

        // not readindg this.order, as it is only read from concierge and is never changed
        ob.set('isConciergeMeasurement', this.isConciergeMeasurement ?? false)
        if (this.fitPreference) { ob.set('fitPreference', this.fitPreference) }


        ob.save()
            .then((measurement) => {
                this.objectId = measurement.id
                console.log('New Measurement Created')


                if (this.type === enums.MeasurementType.product) {
                    if (this.product != null) {
                        let thisProd = Product.copyFrom(this.product)
                        thisProd.measurementsCount += 1
                        thisProd.update((sc, err) => {
                            if (sc !== true) {
                                console.log("Error while updating measurement count of Product, after succesfully adding product measurment : " + err)
                            }
                        })
                    }
                }

                if (this.type === enums.MeasurementType.body) {
                    if (this.customer != null) {
                        let thisCust = Customer.copyFrom(this.customer)
                        thisCust.bodyMeasurementsCount += 1
                        thisCust.update((sc, err) => {
                            if (sc !== true) {
                                console.log("Error while updating measurement count of Customer, after succesfully adding body measurment: " + err)
                            }
                        })
                    }
                }


                callback(true, '')
            }, (error) => {
                console.log('Failed to create new measurement, with error code: ' + error.message);
                callback(false, error.message)
            })

    }


    update(callback) {
        if (this.objectId == null) {
            console.log("Error : No object id to update Measurement")
            callback(false, 'Error : No object id to update Measurement')
        }

        var query = new Parse.Query(MeasurementClass);
        query.get(this.objectId)
            .then((mm) => {
                mm.set('type', this.type)
                mm.set('name', this.name)

                if (this.type === enums.MeasurementType.body) {
                    // set customer
                    if (this.customer == null) {
                        callback(false, "Did not find Customer for Body-Measurement")
                        return
                    }
                    let customerPointer = {
                        __type: 'Pointer',
                        className: 'Customer',
                        objectId: this.customer.objectId
                    }
                    mm.set('customer', customerPointer)
                }

                if (this.type === enums.MeasurementType.product) {
                    // set customer
                    if (this.product == null) {
                        callback(false, "Did not find Product for Product-Measurement")
                        return
                    }
                    let pointer = {
                        __type: 'Pointer',
                        className: 'Product',
                        objectId: this.product.objectId
                    }
                    mm.set('product', pointer)

                }

                var measureStrArray = []

                this.measures.forEach(ms => {
                    measureStrArray.push(ms.toString())
                })

                mm.set('measures', measureStrArray)


                // not readindg this.order, as it is only read from concierge and is never changed
                mm.set('isConciergeMeasurement', this.isConciergeMeasurement ?? false)
                if (this.fitPreference) { mm.set('fitPreference', this.fitPreference) }

                mm.save()
                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }


    static findCount(type, forCustomer, forProduct, callback) {

        var query = new Parse.Query(MeasurementClass);
        query.ascending('createdAt')


        if (forCustomer != null) {
            let pointer = {
                __type: 'Pointer',
                className: 'Customer',
                objectId: forCustomer.objectId
            }
            query.equalTo('customer', pointer)
        }

        if (forProduct != null) {
            let pointer = {
                __type: 'Pointer',
                className: 'Product',
                objectId: forProduct.objectId
            }
            query.equalTo('product', pointer)
        }

        query.count()
            .then((count) => {

                callback(true, count, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, null, error.message)
            });
    }



    static getAll(type, forCustomer, forProduct, callback) {

        var query = new Parse.Query(MeasurementClass);
        query.ascending('createdAt')

        if (forCustomer != null) {
            let pointer = {
                __type: 'Pointer',
                className: 'Customer',
                objectId: forCustomer.objectId
            }
            query.equalTo('customer', pointer)
        }

        if (forProduct != null) {
            let pointer = {
                __type: 'Pointer',
                className: 'Product',
                objectId: forProduct.objectId
            }
            query.equalTo('product', pointer)
        }


        query.find().then((objects) => {
            console.log('Measurements =')
            console.log(objects)
            if (objects.length > 0) {
                let allMMs = []
                for (let i = 0; i < objects.length; i++) {
                    const thisMMObj = objects[i];
                    let thisMM = Measurement.initFromPFObject(thisMMObj, forCustomer, forProduct)
                    if (thisMM !== null) {
                        allMMs.push(thisMM)
                    }
                }
                callback(true, allMMs, '')
            } else {
                callback(true, [], "No Measurements")
            }

        }, (error) => {
            console.log('Error while getting all Measurements ', error.message)
            callback(false, null, error.message)
        })
    }


    toPFObject() {
        let thisObj = new MeasurementClass()
        thisObj.id = this.objectId
        return thisObj
    }

    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Measurement ")
        }
        let thisObj = new MeasurementClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {

            if (this.type === enums.MeasurementType.product) {
                if (this.product != null) {

                    if (this.product.measurementsCount > 0) {
                        this.product.measurementsCount -= 1

                        let prod = Product.copyFrom(this.product)

                        prod.update((sc, err) => {
                            if (sc == null) {
                                console.log("Error while updating measurement count of Product, after initiated product measurment delete: " + err)
                            }
                        })
                        callback(true, "")
                    } else {
                        callback(true, "")
                    }
                }
            }

            if (this.type === enums.MeasurementType.body) {
                if (this.customer != null) {
                    if (this.customer.bodyMeasurementsCount > 0) {
                        this.customer.bodyMeasurementsCount -= 1
                        this.customer.update((sc, err) => {
                            if (sc == null) {
                                console.log("Error while updating measurement count of Product, after initiated product measurment delete: " + err)
                            }
                        })
                        callback(true, "")
                    } else {
                        callback(true, "")
                    }
                }
            }

        }, (error) => {
            console.log('Error while deleting measurement ', error.message)
            callback(false, error.message)
        });

    }

}

export class MeasurementTemplate {

    // it has same vals as its parent, Measurement .. but no customer & product

    constructor(type, name, measures, objectId) {
        this.type = type
        this.name = name
        this.measures = measures
        this.objectId = objectId
    }

    static init(type) {
        let mt = new this(type, '', [], null)
        return mt
    }

    static copyFrom(obj) {
        let mt = MeasurementTemplate.init(obj.type)
        mt.name = obj.name
        mt.measures = obj.measures
        mt.objectId = obj.objectId
        return mt
    }

    static initFromPFObject = (obj) => {
        let name = obj.get('name')
        let type = obj.get('type')

        let measuresStrArray = obj.get('measures')
        // as we will always load measurements under either a customer or product, we will always have it
        //        if type == .body {
        //            guard let custObj = obj["cutsomer"] as? PFObject, let cust = Customer.initFromPFObject(obj: custObj) else{
        //                return null
        //            }
        //            customer = cust
        //        }else {
        //            guard let custObj = obj["product"] as? PFObject, let cust = Customer.initFromPFObject(obj: custObj) else{
        //                return null
        //            }
        //            customer = cust
        //        }

        var measures = []
        measuresStrArray.forEach(ms => {
            let mso = Measure.initFromString(ms)
            if (mso !== null) {
                measures.push(mso)
            }
        });


        let measurementTemplate = new MeasurementTemplate(type, name, measures, obj.id)
        return measurementTemplate
    }



    static getAll(type, callback) {

        var query = new Parse.Query(MeasurementTemplateClass);
        query.ascending('createdAt')
        query.equalTo("type", type)


        query.find().then((objects) => {
            console.log('Templates =')
            console.log(objects)
            if (objects.length > 0) {

                let allTMps = []
                for (let i = 0; i < objects.length; i++) {
                    const thisTmObj = objects[i];
                    let thisTmp = MeasurementTemplate.initFromPFObject(thisTmObj)
                    if (thisTmp !== null) {
                        allTMps.push(thisTmp)
                    }
                }
                callback(true, allTMps, '')
            } else {
                callback(true, [], "No Measurement Templates")
            }

        }, (error) => {
            console.log('Error while getting all Measurement Templates ', error.message)
            callback(false, null, error.message)
        })

    }




    add(callback) {
        let ob = new MeasurementTemplateClass()
        ob.set('type', this.type)
        ob.set('name', this.name)

        var measureStrArray = []

        this.measures.forEach(ms => {
            measureStrArray.push(Measure.measure_toString(ms))
        })

        ob.set('measures', measureStrArray)

        ob.save()
            .then((mt) => {
                this.objectId = mt.id
                console.log('New Measurement Template Created')

                callback(true, '')
            }, (error) => {
                console.log('Failed to create new measurement template, with error code: ' + error.message);
                callback(false, error.message)
            })
    }

    update(callback) {

        if (this.objectId == null) {
            console.log("Error : No object id to update Measurement Template")
            callback(false, 'Error : No object id to update Measurement Template')
        }

        var query = new Parse.Query(MeasurementTemplateClass);
        query.get(this.objectId)
            .then((mt) => {
                mt.set('type', this.type)
                mt.set('name', this.name)

                var measureStrArray = []

                this.measures.forEach(ms => {
                    measureStrArray.push(Measure.measure_toString(ms))
                })

                mt.set('measures', measureStrArray)


                mt.save()
                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }



    toPFObject() {
        let thisObj = new MeasurementTemplateClass()
        thisObj.id = this.objectId
        return thisObj
    }

    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Measurement ")
        }
        let thisObj = new MeasurementTemplateClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting measurement ', error.message)
            callback(false, error.message)
        });

    }

}


export class Remark {


    constructor(byUser, byName, product, type, order, isIssue, isIssueResolved, isUrgent, isTimeLineNode, remark, objectId, locationName) {
        this.byUser = byUser
        this.locationName = locationName
        this.byName = byName
        this.product = product
        this.type = type
        this.order = order
        this.isIssue = isIssue
        this.isIssueResolved = isIssueResolved
        this.isUrgent = isUrgent
        // this.forPosition = forPosition
        // this.forUser = forUser
        this.isTimeLineNode = isTimeLineNode
        this.remark = remark
        this.objectId = objectId
        this.createOn = Date()
    }


    static init = () => {
        let rm = new Remark(null, null, null, enums.RemarkType.product, null, null, null, null, false, null, null, null)
        return rm
    }


    static copyFrom = (obj) => {
        let rm = Remark.init()
        rm.byUser = obj.byUser
        rm.locationName = obj.locationName
        rm.byName = obj.byName
        rm.product = obj.product
        rm.type = obj.type
        rm.order = obj.order
        rm.isIssue = obj.isIssue
        rm.isIssueResolved = obj.isIssueResolved
        rm.isUrgent = obj.isUrgent
        rm.isTimeLineNode = obj.isTimeLineNode
        rm.remark = obj.remark
        rm.objectId = obj.objectId
        rm.createOn = obj.createOn
        // this.forPosition = forPosition
        // this.forUser = forUser
        return rm
    }

    static initFromPFObject(obj, product) {

        if (product == null) {
            console.log("No product to instantiate Remark")
            return null
        }

        let type = obj.get('type')
        let isIssue = obj.get('isIssue')
        let isIssueResolved = obj.get('isIssueResolved')
        let isUrgent = obj.get('isUrgent')
        let isTimeLineNode = obj.get('isTimeLineNode')
        let byName = obj.get('byName')
        let remark = obj.get('remark')
        let createdOn = obj.get('createdOn')
        var locName = ""

        let locNameObj = obj.get('locationName')

        if (locNameObj !== null) {
            locName = locNameObj
        }

        let objId = obj.id

        var randomUser = currentUser

        let existingUser = obj.get('byUser')


        if (existingUser != null) {
            let allUsers = getUsers()
            let matchingUser = allUsers.filter((itm) => {
                return (itm.objectId === existingUser.id)
            })
            if (matchingUser.length > 0) {
                existingUser = matchingUser[0]
            } else {
                if (existingUser.isDataAvailable === true) {
                    let thisUser = new User.initFromPFObject(existingUser, true)
                    if (thisUser !== null) {
                        existingUser = thisUser
                    }
                }
            }
        }



        let thisRemark = new Remark(randomUser, byName, product, type, product.order, isIssue, isIssueResolved, isUrgent, false, remark, objId, locName)

        thisRemark.createOn = createdOn
        thisRemark.isTimeLineNode = isTimeLineNode

        let forUserPFObject = obj.get('forUser')

        if (forUserPFObject != null) {
            if (forUserPFObject.username != null) {
                // init User from PFObject and show here
                let forUser = User.initFromPFObject(forUserPFObject, true)
                if (forUser != null) {
                    thisRemark.forUser = forUser
                }
            }
        }

        let forPositionPFObject = obj.get('forPosition')

        if (forPositionPFObject != null) {
            if (forPositionPFObject.name != null) {
                // init User from PFObject and show here
                let forPos = Position.initFromPFObject(forPositionPFObject)
                if (forPos != null) {
                    thisRemark.forPosition = forPos
                }
            }
        }

        console.log('Sendin Remark')
        console.log(thisRemark)

        return thisRemark

    }



    add(callback) {

        let ob = new RemarkClass()

        let currentUser = getCurrentUser()

        let byUserPointer = {
            __type: 'Pointer',
            className: 'Users',
            objectId: currentUser.objectId
        }
        ob.set('byUser', byUserPointer)

        ob.set('byName', currentUser.name)


        let productPointer = {
            __type: 'Pointer',
            className: 'Product',
            objectId: this.product.objectId
        }
        ob.set('product', productPointer)


        let orderPointer = {
            __type: 'Pointer',
            className: 'Order',
            objectId: this.order.objectId
        }
        ob.set('order', orderPointer)

        ob.set('type', this.type)

        ob.set('isIssue', this.isIssue)

        ob.set('isIssueResolved', this.isIssueResolved)

        ob.set('isUrgent', this.isUrgent)

        ob.set('isTimeLineNode', this.isTimeLineNode)

        ob.set('remark', this.remark)

        ob.set('createdOn', this.createdOn)

        ob.set('locationName', this.locationName)

        // if (this.forUser != null) { ob["forUser"] = this.forUser.toPFObject() }
        // if (this.forPosition != null) { ob["forPosition"] = this.forPosition.toPFObject() }

        ob.save()
            .then((remark) => {
                this.objectId = remark.id

                if (this.isTimeLineNode != null) {
                    if (this.product) {
                        let prod = Product.copyFrom(this.product)

                        this.product.remarksCount += 1
                        prod.remarksCount += 1

                        prod.update((succ3, errMsg3) => {
                            if (succ3 !== true) {
                                console.log('Error While updating RemarksCount of Product after remark has been succesfully uploaded : ' + errMsg3)
                            }
                        })
                    }

                }

                callback(true, '')
            }, (error) => {
                console.log('Failed to create new measurement, with error code: ' + error.message);
                callback(false, error.message)
            })
    }




    update(callback) {


        if (this.objectId === null) {
            console.log("Error : No object id to update Remark")
            callback(false, 'Error : No object id to update Remark')
            return
        }


        var query = new Parse.Query(RemarkClass);
        query.get(this.objectId)
            .then((ob) => {
                // let byUserPointer = {
                //     __type: 'Pointer',
                //     className: 'Users',
                //     objectId: this.byUser.objectId
                // }
                // ob.set('byUser', byUserPointer)

                // ob.set('byName', this.byName)


                // let productPointer = {
                //     __type: 'Pointer',
                //     className: 'Product',
                //     objectId: this.product.objectId
                // }
                // ob.set('product', productPointer)


                // let orderPointer = {
                //     __type: 'Pointer',
                //     className: 'Order',
                //     objectId: this.order.objectId
                // }
                // ob.set('order', orderPointer)

                ob.set('type', this.type)

                ob.set('isIssue', this.isIssue)

                ob.set('isIssueResolved', this.isIssueResolved)

                ob.set('isUrgent', this.isUrgent)

                ob.set('isTimeLineNode', this.isTimeLineNode)

                ob.set('remark', this.remark)

                // ob.set('createdOn', this.createdOn)

                // ob.set('locationName', this.locationName)

                // if (this.forUser != null) { ob["forUser"] = this.forUser.toPFObject() }
                // if (this.forPosition != null) { ob["forPosition"] = this.forPosition.toPFObject() }

                ob.save()

                callback(true, '')
            }, (error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and message.
                callback(false, 'Error =' + error.message)
            });

    }


    static getAll(forProduct, forOrder, isTimelineNode, callback) {
        var query = new Parse.Query(RemarkClass);
        query.ascending('createdAt')

        let forProductPointer = {
            __type: 'Pointer',
            className: 'Product',
            objectId: forProduct.objectId
        }
        query.equalTo('product', forProductPointer)

        query.include("byUser")
        // query.includeKey("forPosition")
        // query.includeKey("forUser")

        query.equalTo("isTimeLineNode", false)


        //        if let isTimelineNode = isTimelineNode {
        //            if isTimelineNode == false {
        //                query.whereKey("isTimelineNode", notEqualTo: "True")
        //            }else{
        //                query.whereKey("isTimelineNode", equalTo: "True")
        //            }
        //        }

        //TODO:- We can show if any issue exists in product (as a remark, directly in order) by using for Order, just that we need to put product as sample in that case. forOrder is not currently used.. but it can be if we want to show which orders have issues later
        //MARK:-

        query.find().then((objects) => {
            console.log('Remarks =')
            console.log(objects)
            if (objects.length > 0) {

                let allRms = []
                for (let i = 0; i < objects.length; i++) {
                    const thisRmObj = objects[i];
                    let thisRm = Remark.initFromPFObject(thisRmObj, forProduct)
                    if (thisRm !== null) {
                        allRms.push(thisRm)
                    }
                }
                callback(true, allRms, '')
            } else {
                callback(true, [], "No Remarks")
            }

        }, (error) => {
            console.log('Error while getting all Remarks ', error.message)
            callback(false, null, error.message)
        })

    }

    toPFObject() {
        let thisObj = new RemarkClass()
        thisObj.id = this.objectId
        return thisObj
    }

    delete(callback) {
        if (this.objectId == null) {
            callback(false, "Error : No object id to delete Remark ")
        }

        let thisObj = new RemarkClass()
        thisObj.id = this.objectId

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            console.log('Error while deleting remark ', error.message)
            callback(false, error.message)
        });
    }


}


// class StockRemark {

//         init(type, byUser, byEnd, time, remark, stock, product , objectId, atLocationName) {
//             this.type = type
//             this.byUser = byUser
//             this.byEnd = byEnd
//             this.time = time
//             this.remark = remark
//             this.stock = stock
//             this.product = product
//             this.objectId = objectId
//             this.atLocationName = atLocationName
//         }

//         static initFromPFObject(obj, stock)  {
//             var randomProduct = sampleProduct
//                 var randomUser = currentUser ?? sampleUser
//                 var randomStock = sampleStock

//                 let type = obj.get('type')
//                 let remark = obj.get('remark')
//                 let objId = obj.get('objectId')
//                 let byEnd = obj.get('byEnd')
//                 let time = obj.get('time')
//                 var locName = ""

//                 let thisLocName = obj.locationName
//                 if (thisLocName != null) {
//                     locName = thisLocName
//                 }


//                 let existingUser = obj.get('byUser')
//                 if (existingUser !== null) {
//                     randomUser.objectId = existingUser.id
//                     if (existingUser.isDataAvailable == true) {
//                         // init User from PFObject and show 
//                         let thisUser = User.initFromPFObject(existingUser, true)
//                         if (thisUser != null) {
//                             randomUser = thisUser
//                         }
//                     }
//                 }

//                 let existingProd = obj.get('product')
//                 if (existingProd != null) {
//                     randomProduct.objectId = existingProd.id
//                     if (existingProd.isDataAvailable == true) {
//                         // init User from PFObject and show here
//                         let thisProduct = Product.initFromPFObject(existingProd, null, null)
//                         if (thisProduct != null) {
//                             randomProduct = thisProduct
//                         }
//                     }
//                 }

//                 // if stock is given in method
//                 if (stock != null) {
//                     randomStock = stock
//                 }

//                 // if stock exists within the remark object
//                 if (stock == null) {
//                     let existingStock = obj.get('stock')

//                     if (existingStock != null)  {
//                         randomStock.objectId = existingStock.id
//                         if (existingStock.isDataAvailable != null) {
//                             // init User from PFObject and show here
//                             let thisStock = Stock.initFromPFObject(existingStock, null)
//                             if (thisStock != null)  {
//                                 randomStock = thisStock
//                             }
//                         }
//                     }
//                 }

//         let thisStockRemark = new StockRemark(type, randomUser, byEnd, time, remark, randomStock, randomProduct, objId, locName)

//         return thisStockRemark
//     }




//     add(callback) {
//         let ob = new StockRemarkClass()
//         ob["byUser"] = this.byUser.toPFObject()
//         ob["byEnd"] = this.byEnd
//         if (this.product != null) { ob["product"] = this.product.toPFObject() }
//         ob["stock"] = this.stock.toPFObject()
//         ob["type"] = this.type
//         ob["time"] = this.time
//         ob["remark"] = this.remark
//         ob["atLocationName"] = this.atLocationName

//         ob.save()
//             .ob((stockRemark) => {
//                 this.objectId = stockRemark.id

//                 callback(true, '')
//             }, (error) => {
//                 console.log('Failed to create new Stock Remark, with error code: ' + error.message);
//                 callback(false, error.message)
//         })

//     }

//     update(callback) {

//         if (this.objectId === null) {
//             console.log("Error : No object id to update Stock Remark")
//             callback(false, 'Error =' + 'Error : No object id to update Stock Remark')
//             return
//         }


//         var query = new Parse.Query(StockRemarkClass);
//         query.get(this.objectId)
//             .then((sr) => {
//                 sr["byUser"] = this.byUser.toPFObject()
//                 sr["byEnd"] = this.byEnd
//                 if (this.product != null) { sr["product"] = this.product.toPFObject() }
//                 sr["stock"] = this.stock.toPFObject()
//                 sr["type"] = this.type
//                 sr["time"] = this.time
//                 sr["remark"] = this.remark
//                 sr["atLocationName"] = this.atLocationName

//                 sr.save()

//                 callback(true, '')
//             }, (error) => {
//                 // The object was not retrieved successfully.
//                 // error is a Parse.Error with an error code and message.
//                 callback(false, 'Error =' + error.message)
//             });

//     }


//     static getAll(forStock, callback) {


//         var query = new Parse.Query(StockRemarkClass);
//         query.descending('createdAt')
//         query.equalTo('stock', forStock.toPFObject())
//         query.includeKey("byUser")
//         query.includeKey("stock")
//         query.includeKey("product")



//         query.find().then((objects) => {
//             console.log('Stock Remarks =')
//             console.log(objects)
//             if (objects.length > 0) {
//                 let allSRs = []
//                 for (let i = 0; i < objects.length; i++) {
//                     const thisSrObj = objects[i];
//                     let thisSR = StockRemark.initFromPFObject(thisSrObj, forStock)
//                     if (thisSR !== null) {
//                         allSRs.push(thisSR)
//                     }
//                 }
//                 return callback(true, allSRs, '')
//             } else {
//                 callback(true, [], "No Stock Remarks")
//             }

//         }, (error) => {
//             console.log('Error while getting all Stock Remarks ', error.message)
//             callback(false, null, error.message)
//         })
//     }



//     toPFObject()  {
//         let thisObj = new StockRemarkClass()
//         thisObj.id = this.objectId
//         return thisObj
//     }

//     delete (callback) {
//         if (this.objectId == null) {
//             callback(false, "Error : No object id to delete Stock Remark ")
//         }

//         let thisObj = new StockRemarkClass()
//         thisObj.id = this.objectId

//         thisObj.destroy().then((myObject) => {
//             callback(true, '')
//         }, (error) => {
//             console.log('Error while deleting Stock Remark ', error.message)
//             callback(false, error.message)
//         });
//     }


// }



// var sampleCustomer = new Customer('', '', null, null, null, 'INDIA (91)', '8800166001', null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0, [], [], null, null, null, null, null, null, null, 0, null, currentLocation)
var sampleOrder = new Order(sampleCustomer, sampleCustomer.name, sampleCustomer.phone, '0000', null, null, null, enums.Status.Pending, [], null, currentLocation, currentUser, null)
var sampleProduct = new Product(sampleCustomer, sampleOrder, '', ProductType.Suit2piece, enums.Stage.Requested, enums.Status.Pending, enums.Purpose.Create, 0, 0, 0, null, null, 0, 0, [], currentUser, null, null, null, null, null, null, '', currentLocation, null, false)
