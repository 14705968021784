import React, { useState, useEffect } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Table, Button, Tabs, Image, Modal, Heading, Card, Label, InputFile } from 'react-bulma-components/dist';
import { MeasurementType } from '../../utilities/enums'
import ColumnAux from '../../containers/ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import { MeasurementTemplate } from '../../utilities/Classes'
import MeasurementModal from '../../containers/MeasurementModal'
import cogoToast from 'cogo-toast';
import MeasurementTemplates from '../../containers/MeasurementTemplates';
import {showOnlyClientFeature} from '../../ClientInfo/clientInfo'

const TemplateSettings = (props) => {



    const [templates, setTemplates] = useState([])
    const [type, setType] = useState(MeasurementType.body)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [addTemplate, setAddTemplate] = useState(false)


    const templateTappedToUse = (tmp) => {
        console.log('Template tapped to use')
        // props.selectedTemplate(tmp)
    }


    useEffect(() => {
        let ct = type
        setTemplates([])
        MeasurementTemplate.getAll(ct, (succ, tmps, errMsg) => {
            if (ct !== type){
                return
            }
            if (succ) {
                setTemplates(tmps)
            } else {
                cogoToast.error('Could not fetch Measurement Templates. An error occured.')
                // alert(errMsg)
            }
        })

    }, [type])





    const tmpBox = (mt) => {
        return (
            // <Box className='hoverShine noBreakWords generalCardBG imagesItem has-background-black' onClick={() => { templateTappedToUse(tmp) }} >
            // </Box>

            <Card className='is-radiusless  noBreakWords generalCardBG measureTemplateItem has-background-white' onClick={() => { templateTappedToUse(mt) }} >
                <div className='type55 has-text-weight-semibold marginTop10 marginLeft10'> {mt.name} </div>
                <br />
                <Table className=' is-size-7'>
                    <tbody>
                        {
                            mt.measures.map((ms, index) => {
                                return <tr key={index}><td className='type40'>{ms.name}</td><td className='has-text-right type40'>{ms.defaultValue}&nbsp;{ms.unit}</td></tr>
                            })
                        }
                    </tbody>
                </Table>
            </Card>

        )
    }


    const templateIsSelected = (tmp) => {
        setSelectedTemplate(tmp)
    }


    const tmpTile = (tmp, index) => {
        return (
            <Tile key={index} size={4} kind='parent' className='' onClick={() => { templateIsSelected(tmp) }} >
                <Tile className='fullHeightAll' >
                    {tmpBox(tmp)}
                </Tile>
            </Tile>
        )
    }

    const allTemplateTiles = () => {
        return (
            <div>
                <Tile kind="ancestor" className='grid measurementsTileContainer' >
                    {
                        templates.map((tmp, index) => {
                            return tmpTile(tmp, index)
                        })
                    }
                </Tile>
            </div>
        )
    }


    const addPressed = ()  => {
        setAddTemplate(true)
    }

    const changeTab = (tab) => {
        setType(tab)

        setTemplates([])
        let ct = tab
        MeasurementTemplate.getAll(ct, (succ, tmps, errMsg) => {

            if (ct !== type){
                return
            }

            if (succ) {
                setTemplates(tmps)
            } else {
                cogoToast.error('Could not fetch Measurement Templates. An error occured.')
                // alert(errMsg)
            }
        })

    }

    let tabs = (
        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={false}
            align='centered'
            size='small'
            className="marginTop10"
            type='toggle'
        >
            <Tabs.Tab active={type === MeasurementType.body} onClick={() => { changeTab(MeasurementType.body) }} > Body Templates </Tabs.Tab>
            <Tabs.Tab active={type === MeasurementType.product} onClick={() => { changeTab(MeasurementType.product) }} > Product Templates </Tabs.Tab>
        </Tabs>
    )



    const modal = () => {
        return (


            <div>
                    {
                        showOnlyClientFeature ?
                            null : 
                            <div>
                                {tabs}
                            </div>
                    }

                <br />

                <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > { type.toUpperCase() } Measurement Templates </Heading>
                <div>
                    <Button className='is-small is-pulled-right is-rounded marginBottom10' onClick={addPressed}> Add </Button>
                    <br />
                    <br />
                </div>
                {/* <Heading className='type50 marginTop20  has-text-grey has-text-centered' > TAP TO USE </Heading> */}

                <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                    {allTemplateTiles()}
                </ColumnAux>
            </div>



        )
    }




    const closed = () => {
        setSelectedTemplate(null)
        setAddTemplate(false)
    }


    const templateSaved = (tmp) => {

        if (addTemplate) {
            // add
            let tmps = [...templates]
            tmps.push(tmp)
            setTemplates(tmps)

        } else {

            // update
            let tmps = [...templates]
            const index = tmps.findIndex((t) => {
                return t.objectId === tmp.objectId
            })

            if (index != null) {
                tmps[index] = tmp
                setTemplates(tmps)
            }


        }
    }


    const templateRemoved = (tmp) => {
        let tmps = [...templates]
        const index = tmps.findIndex((t) => {
            return t.objectId === tmp.objectId
        })

        if (index != null) {
            delete tmps[index]
            setTemplates(tmps)
        }

    }


    return (
        <div>
            {addTemplate ? <MeasurementModal isTemplateMode={true} isNew={true} measurement={MeasurementTemplate.init(type)} type={type} closed={closed} templateSaved={templateSaved} /> : null}
            {selectedTemplate ? <MeasurementModal isTemplateMode={true} isNew={false} measurement={selectedTemplate} type={type ?? MeasurementType.body} closed={closed} templateSaved={templateSaved} templateRemoved={templateRemoved} /> : modal()}
        </div>

    )

}

export default TemplateSettings