import React, { useState } from 'react'
import MainMenu from '../components/Menu/MainMenu'
import SideMenu from '../components/Menu/SideMenu'
import Aux from './Aux'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Container, Section, Heading, Breadcrumb, Tabs, Image, Button } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab } from '../utilities/enums'
import { showOnlyClientFeature } from '../ClientInfo/clientInfo';

import ClientInfoForm from './ClientInfoForm'
import ClientMeasurementsInfo from './ClientMeasurementsInfo'
import ClientOrdersInfo from './ClientOrdersInfo'
import ProductsOfOrder from './ProductsOfOrder'




const PageHeader = (props) => {

    let getTitle = () => {
        // if ((props.crumbItems.length <= 1) && (props.crumbItems[props.crumbItems.length - 1]).toLowerCase() === 'store' ){
        //     return 'New Order'
        // }
        let thisTitle = props.crumbItems[props.crumbItems.length - 1]
        thisTitle = thisTitle.replace(/([A-Z])/g, ' $1').trim()

        if (thisTitle.toLowerCase() === "new order" && showOnlyClientFeature === true){
            thisTitle = "New Client"
        }

       return thisTitle
    }

    const [items, setItems] = useState([...props.crumbItems])
    const [title, setTitle] = useState(getTitle)




    const breadcrumbs = () => {
        if (props.crumbItems.length <= 1) {
            return <br />
        }

        return (
            <div className=' '>
                {/* <Button className='is-small is-text margin10' > Back </Button> */}
                <Breadcrumb
                    separator='succeeds'
                    size='small'
                    className='breadcrumbMargin is-capitalized'
                    items={
                        items.map( (itm, index) => {
                            if (index === 0) {
                                return crumbItem(itm, '/'+ itm)
                            }
                            if ( showOnlyClientFeature && itm.toLowerCase() === "neworder"){
                                return crumbItem("new", itm)
                            }
                            return crumbItem(itm, '' + itm)
                        })
                    }
                />
            </div>
        )
    }



    const crumbItem = (name, url) => {
        return  { name: name, url: url }
    }

    const heading = () => {
        return   <Heading className='topMarginMinus20 is-capitalized' size={4}> {getTitle()} </Heading>
    }


    return (
        <Aux>
            {breadcrumbs()}
            {heading()}
        </Aux>

      )


}

export default PageHeader