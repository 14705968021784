import React from 'react'
import { getLocations, getUsers, getCurrentUser, getPositions } from '../../utilities/Store';
import { Box, Columns, Button, Tag, Image } from 'react-bulma-components/dist';
import { Position } from '../../utilities/UserClasses'



const UserCard = (props) => {

    // constructor(id, type, name, description, createdOn, dueDate, amount, currency, paidFull, paymentDate, paymentId, fileURL, status, orderId, userId) {
    const userBox = (user) => {



        let allLocations = getLocations()

        let userLocation = null

        let userLocationMatch = allLocations.filter((thisLoc) => {
            return thisLoc.objectId === user.locationId
        })

        if (userLocationMatch) {
            if (userLocationMatch.length) {
                userLocation = userLocationMatch[0]
            }
        }



        console.log('Got User')
        console.log(user)

        // let imageUrlOfUser = 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200'

        let imageUrl = null

        if (user.imageUrl != null && user.imageUrl !== '') {
            imageUrl = user.imageUrl
        }
        else {
            imageUrl = 'https://ui-avatars.com/api/?background=808080&color=f2f2f2&name=' + user.name
        }

        return (
            <Box className='hoverShine userCard has-text-centered noBreakWords has-background-grey-lighter	' onClick={() => { props.selectUser(user) }} style={{cursor: 'pointer'}}>

                <div className=" marginBottom20  has-text-centered ">
                    <Image className=' userImage has-image-centered' src={imageUrl} alt="User Profile Image" size={48} />
                </div>

                <p className="fieldHeight20 marginBottom5 has-text-weight-semibold type65 is-uppercase" >{user.username}</p>
                {user.isAdmin || user.isFranchiseAdmin ?

                    (user.isFranchiseAdmin ?
                            <Tag className=' newline is-centered has-text-centered has-text-info type45 marginBottom20' color="white" >Franchise Admin</Tag>
                        :
                        <Tag className=' has-text-link type45 marginBottom20' color="light" > ADMIN  </Tag>

                    )

                    :
                    <p className="fieldHeight20  has-text-grey has-text-weight-semibold type55" >{user.name}</p>
                }



                <br />

                {/* <Button className='  is-small  is-fullwidth is-outlined' onClick={() => { props.selectUser(user) }}> View </Button> */}
            </Box>
        )
    }



    return (
        // invoiceCardToShow(props.status)
        userBox(props.user)
    )
}

export default UserCard