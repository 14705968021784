import React, { useState, useRef, useEffect } from 'react'
import { Tile, Columns, Heading, Button, Box } from 'react-bulma-components/dist'
import CustomerCard from '../components/Cards/CustomerCard'
import { Customer } from '../utilities/Classes'
import { CustomerFilter } from '../utilities/Filters'
import Client from '../Departments/Store/client'
import { screen } from '../utilities/enums'
import cogoToast from 'cogo-toast'
import ColumnAux from '../containers/ColumnAux'
import { showOnlyClientFeature, useClientCid } from '../ClientInfo/clientInfo'




const Customers = (props) => {


    let SearchKeys = {
        ClientPhone: 'Client Phone',
        ClientEmail: 'Client Email',
        ClientName: 'Client Name',
        ClientCId: ' Client Id',
    }

    let [customers, setCustomers] = useState([])
    let [custFilter, setCustFilter] = useState(CustomerFilter.init())

    let [fetchCustomers, setFetchCustomers] = useState(props.isSearchable ? false : true)
    let [selectedCustomer, setSelectedCustomer] = useState(null)


    let [searchKey, setSearchKey] = useState(SearchKeys.ClientPhone)
    let [searchVal, setSearchVal] = useState(null)
    let [searching, setSearching] = useState(false)
    const valFieldRef = useRef('valFieldRef')


    useEffect(() => {
        if (fetchCustomers === true) {
            setSearching(true)
            Customer.getAll(CustomerFilter.init(), (succ, customers, msg) => {
                if (succ) {
                    setCustomers(customers)
                } else {
                    cogoToast.error('Could not fetch Customers. An error occured.')
                    // console.log(msg)
                }
                setSearching(false)
            })
            setFetchCustomers(false)
        }
    }, [fetchCustomers])



    const closeModals = () => {
        setSelectedCustomer(null)
    }



    const searchKeyChanged = (e) => {
        setSearchVal(null)
        setCustFilter(CustomerFilter.init())
        setCustomers([])
        valFieldRef.current.value = null
        setSearchKey(e.target.value)
    }

    const searchValChanged = (e) => {
        setSearchVal(e.target.value)
    }


    const selectCustomerPressed = (cust) => {
        setSelectedCustomer(cust)
    }

    const customerUpdated = (custom) => {
        let custs = [...customers]

        const index = custs.findIndex((cs) => {
            return cs.objectId === custom.objectId
        })

        if (index != null) {
            custs[index] = custom
        }

        setCustomers(custs)
    }



    const startSearching = () => {
        if (searchVal != null) {
            // search
            if (searchKey === SearchKeys.ClientPhone) {
                custFilter.phone = searchVal
            }

            if (searchKey === SearchKeys.ClientEmail) {
                custFilter.email = searchVal
            }

            if (searchKey === SearchKeys.ClientName) {
                custFilter.name = searchVal
            }

            if (searchKey === SearchKeys.ClientCId) {
                custFilter.customId = searchVal
            }

            searchForCustomers()

        } else {
            cogoToast.warn('Enter Value to Search')
            // alert('Enter Value to Search')
            return
        }
    }

    const searchForCustomers = () => {
        if (fetchCustomers === true || props.isSearchable === true) {

            if (fetchCustomers) {
                setFetchCustomers(false)
            }

            // console.log('Searching for Customers for screen- ' + props.screen)

            setSearching(true)
            Customer.getAll(custFilter, (succ, customers, msg) => {
                if (succ) {
                    setCustomers(customers)
                } else {
                    cogoToast.error('Could not find Customers. An error occured.')
                    // console.log(msg)
                }
                setSearching(false)
            })

        } else {
            console.log('Fetch Customers is false')
        }
    }



    let searchForm = () => {

        return (

            <form>
                <div>
                    <div className="is-centered has-text-centered">
                        <ColumnAux isMiddle={true} size={4}>
                            <Box className="has-background-white-ter">
                            <Heading size={5} className='is-centered  has-text-centered'> Customers where</Heading>
                                <div className="control ">
                                    <span className="select has-text-weight-semibold type70 is-fullwidth ">
                                        <select as='select' defaultValue={searchKey} className='select' name='searchKey' onChange={(e) => { searchKeyChanged(e) }}>
                                            <option value={SearchKeys.ClientPhone} >{SearchKeys.ClientPhone}</option>
                                            <option value={SearchKeys.ClientEmail} >{SearchKeys.ClientEmail}</option>
                                            <option value={SearchKeys.ClientName} >{SearchKeys.ClientName}</option>
                                            {
                                                useClientCid ?
                                                <option value={SearchKeys.ClientCId} >{SearchKeys.ClientCId}</option>
                                                :
                                                null
                                            }
                                        </select>
                                    </span>
                                </div>
                                <div className='control'>
                                    <div className=' field'>
                                        <input ref={valFieldRef} name='searchVal' defaultValue={searchVal ?? null} type='input' className='input has-text-weight-semibold type70 marginTop20' placeholder='Equal to' onChange={(e) => { searchValChanged(e) }} />
                                    </div>
                                </div>
                                <div className='control '>
                                    <Button type="submit" disabled={searching} loading={searching} className='type70 is-dark is-fullwidth marginTop40' onClick={() => { startSearching() }} > Search </Button>
                                </div>
                            </Box>
                            <br/>
                        </ColumnAux>
                    </div>


                    {/* <div className="field has-addons has-addons-centered">
                        <div className="control ">
                            <span className="select has-text-weight-semibold type70">
                                <select as='select' defaultValue={searchKey} className='select' name='searchKey' onChange={(e) => { searchKeyChanged(e) }}>
                                    <option value={SearchKeys.ClientPhone} >{SearchKeys.ClientPhone}</option>
                                    <option value={SearchKeys.ClientEmail} >{SearchKeys.ClientEmail}</option>
                                    <option value={SearchKeys.ClientName} >{SearchKeys.ClientName}</option>
                                </select>
                            </span>
                        </div>
                        <div className='control'>
                            <div className=' field'>
                                <input ref={valFieldRef} name='searchVal' defaultValue={searchVal ?? null} type='input' className='input has-text-weight-semibold type70' placeholder='Equal to' onChange={(e) => { searchValChanged(e) }} />
                            </div>
                        </div>
                        <div className='control'>
                            <Button disabled={searching} loading={searching} className='type70 is-black ' onClick={() => { startSearching() }} > Search </Button>
                        </div>
                    </div> */}


                </div>
                <br />

            </form>
        )
    }


    const customersView = () => {
        return (
            <div>
                <div>
                    {(props.isSearchable === true) ? searchForm() : null}
                </div>
                {/* <Heading size={6} className="  marginBottom6">Customers </Heading> */}

                <Tile kind="ancestor" className=' customerGrid' >
                    {/* <Fade right cascade> */}
                    {
                        customers.map((cust, index) => {
                            return (
                                <Tile kind='parent' className=''>
                                    <Tile className='' >
                                        <CustomerCard key={index} customer={cust} selectCustomer={selectCustomerPressed} />
                                    </Tile>
                                </Tile>
                            )
                        })
                    }
                    {/* </Fade> */}
                </Tile>
            </div>
        )
    }




    return (
        <div>
            {selectedCustomer ? <Client isEmbedded={true} screen={props.screen}  customer={selectedCustomer} customerUpdated={customerUpdated} backPressed={closeModals} /> : customersView()}
        </div>
    )

}


export default Customers