import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Card, Image, Columns, Table, Dropdown, Tag } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faPaperPlane, faEdit, faEllipsisV, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
// import ProductForm from '../../containers/ProductForm'
import { Product, ProductPic, Measurement, Remark, Customer } from '../../utilities/Classes'
import { MeasurementType, picType, departments, departmentForscreen, screen, Stage, PrintSheetType } from '../../utilities/enums'

import { nextStageForScreen, nextStageForScreen_Title, nextStageCompletion_Title } from '../../utilities/Classes'
import { randomAlphabets, randomIntegerString } from '../../utilities/utils'

import ImageUploader from '../../containers/ImageUploader'
import ImageViewer from '../../containers/ImageViewer'
import MeasurementModal from '../../containers/MeasurementModal'
import MeasurementTemplates from '../../containers/MeasurementTemplates'
import RemarkModal from '../Cards/RemarkModal'
import VendorsModal from '../../General/VendorsModal'
import ChoiceModal from './ChoiceModal'

import DatePicker from 'react-datepicker'
import { Profession_options, ProductType_StyleOptions, SpendRange_options, ClientCategory_options, ProductType } from '../../utilities/enums'
import { getUsers, getCurrentUser } from '../../utilities/Store';
import cogoToast from 'cogo-toast';
import ProductPrint from '../../containers/printProduct';
import StyleSelectionModal from '../../Departments/Settings/styleSelectionModal';
import StylesModal from './StylesModal';
import MaterialModal from './MaterialModal';
import ProductEditModal from './ProductEditModal';
import ProductEditResetStageModal from './ProductEditResetStageModal';


import ProductCancelModal from './ProductCancelModal';
import PrintOrder from '../../containers/PrintOrder';
import { ProductType_options_NonFormik, usesVendorViaFactory } from '../../ClientInfo/clientInfo';


const ProductModal = (props) => {

    const setupProduct = (prd) => {
        if (props.product != null) {
            return props.product
        } else {
            let np = Product.init()
            if (props.order) {
                np.order = props.order
            }
            if (props.customer) {
                np.customer = props.customer
            }
            return np
        }
    }


    let [product, setProduct] = useState(props.product)
    let [productPics, setProductPics] = useState([])
    let [productMeasurements, setProductMeasurements] = useState([])
    let [productRemarks, setProductRemarks] = useState([])


    // used while printing
    let [customer, setCustomer] = useState(props.customer ?? null)
    let [printingOrder, setPrintingOrder] = useState(false)



    let [pf, setPF] = useState(true)
    let [mf, setMF] = useState(true)
    let [rf, setRF] = useState(true)


    let [addProdPic, setAddProdPic] = useState(false)
    let [viewProdPic, setViewProdPic] = useState(null)
    let [showVendors, setShowVendors] = useState(false)
    let [styleSelectionsSelected, setStyleSelectionsSelected] = useState(false)
    let [materialSelected, setMaterialSelected] = useState(false)
    let [productEditSelected, setProductEditSelected] = useState(false)
    let [productResetStageSelected, setProductResetStageSelected] = useState(false)
    let [cancelProductSelected, setCancelProductSelected] = useState(false)


    let [addProdMesurement, setAddProdMesurement] = useState(false)
    let [useProdMesurementTemp, setUseProdMesurementTemp] = useState(null)
    let [viewProdMesurement, setViewProdMesurement] = useState(null)

    let [addProdRemark, setAddProdRemark] = useState(false)
    let [editSelectedRemark, setEditSelectedRemark] = useState(null)

    let [isNewProduct, setIsNewProduct] = useState(props.isNewProduct)
    let [showTrialChoices, setShowTrialChoices] = useState(false)
    let [showFinishingChoices, setShowFinishingChoices] = useState(false)

    let [saving, setSaving] = useState(false)
    let [sending, setSending] = useState(false)

    let [productPrint, setProductPrint] = useState(false)

    let cu = getCurrentUser()
    let dept = departmentForscreen(props.screen ?? screen.NewOrder)

    let [enableDescription, setEnableDescription] = useState(props.isNewProduct ?? false)


    const actingOnProductsAllowed = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }
        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.actingOnProducts; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.actingOnProducts; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.actingOnProducts; break;
            default: break;
        }
        return al
    }

    const addingPicsMeasurementsAllowed = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }
        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.addingPicsMeasurementsRemarks; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.addingPicsMeasurementsRemarks; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.addingPicsMeasurementsRemarks; break;
            default: break;
        }
        return al
    }


    let saveProduct = () => {

        console.log('SAVING PRODUCT')


        if (isNewProduct === true) {
            setSaving(true)
            console.log('ADDING PRODUCT')

            let np = Product.init()
            np.type = product.type
            np.order = props.order
            np.customer = props.customer ?? props.order.customer
            np.addedByUser = getUsers()[0]
            np.trialDate = product.trialDate
            np.deliveryDate = product.deliveryDate
            np.productId = props.order.orderNo + '-P' + randomAlphabets(2) + randomIntegerString(1) + randomAlphabets(1)

            np.add((succ, errMsg) => {

                console.log('PRODUCT ADDED UPDATEDa')

                if (succ) {
                    // console.log('Product Added')
                    // console.log(np)
                    cogoToast.success('Product Added')
                    setProduct(np)
                    props.productAdded(np)
                    setIsNewProduct(false)
                    setEnableDescription(false)
                    setClosed()
                } else {
                    cogoToast.error('Could not save Product. Check internet and try again.')
                    // console.log(errMsg)
                }
                setSaving(false)
            })

        } else {
            if (product.objectId == null) {
                cogoToast.warn('No id found to update product')
                // alert('No Product Id found to update product')
            }

            let pp = Product.copyFrom(product)

            setSaving(true)
            pp.update((succ, errMsg) => {
                if (succ) {
                    // console.log('Product Updated')
                    // console.log(product)
                    cogoToast.success('Product Updated')
                    props.productUpdated(product)
                    setEnableDescription(false)
                    setClosed()
                } else {
                    cogoToast.error('Could not update Product. Check internet and try again.')
                    // console.log(errMsg)
                }
                setSaving(false)
            })
        }

    }


    const setClosed = () => {
        props.closed()
    }


    const closeModals = () => {
        setViewProdPic(null)
        setViewProdMesurement(null)
        setAddProdPic(false)
        setAddProdMesurement(false)
        setAddProdRemark(false)
        setUseProdMesurementTemp(null)
        setShowVendors(false)
        setShowTrialChoices(false)
        setProductPrint(false)
        setEnableDescription(false)
        setStyleSelectionsSelected(false)
        setMaterialSelected(false)
        setProductEditSelected(false)
        setProductResetStageSelected(false)
        setCancelProductSelected(false)
        setEditSelectedRemark(null)
        setPrintingOrder(false)
    }




    const showDescriptionChanged = () => {
        setEnableDescription(!enableDescription)
    }


    const productEdited = (prod) => {
        // let prodCopy = { ...product }
        // prodCopy.type = prod.type
        setProduct(prod)
        props.productUpdated(prod)
    }


    const productCancelled = (prod) => {
        setProduct(prod)
        props.productUpdated(prod)
    }

    const stylesAreSaved = (updatedSS) => {

        console.log("Updating SS is")
        console.log(updatedSS)

        let prodCopy = { ...product }
        prodCopy.styleSelections = updatedSS
        setProduct(prodCopy)
        closeModals()
    }

    const materialsUpdated = (updatedMM) => {
        let prodCopy = { ...product }
        prodCopy.materialToSource = updatedMM
        setProduct(prodCopy)
        closeModals()
    }

    const toggleProductEdit = () => {
        setProductEditSelected(!productEditSelected)
    }

    const toggleProductResetStage = () => {
        setProductResetStageSelected(!productResetStageSelected)
    }

    const toggleCancelProduct = () => {
        setCancelProductSelected(!cancelProductSelected)
    }

    const toggleStyleSelectionsSelected = () => {
        setStyleSelectionsSelected(!styleSelectionsSelected)
    }

    const toggleMaterials = () => {
        setMaterialSelected(!materialSelected)
    }


    const productTrialChanged = (td) => {
        console.log('Product Trial Changed = ')
        let pr = { ...product }
        pr.trialDate = td
        setProduct(pr)
        // props.productUpdated(pr)
        console.log(td)
    }

    const productTypeChanged = (type) => {
        console.log('Product Type Changed = ')

        let pr = { ...product }
        pr.type = type.target.value
        setProduct(pr)
        console.log(type.target.value)

    }

    const productStyleChanged = (e) => {
        console.log('Product Style Changed = ' + e.target.value)
        let val = e.target.value
        let pr = { ...product }
        pr.styleNo = val
        setProduct(pr)
    }


    const productDescriptionChanged = (e) => {
        console.log('Product Description Changed = ' + e.target.value)
        let val = e.target.value
        let pr = { ...product }
        pr.description = val
        setProduct(pr)
    }



    const productDeliveryChanged = (dd) => {
        console.log('Product Delivery Changed = ')

        let pr = { ...product }
        pr.deliveryDate = dd
        setProduct(pr)
        console.log(dd)

        // props.productUpdated(pr)


    }


    const errorOccured = (err) => {
        // cogoToast.warn(err)
        // alert(err)
    }


    const prodRemarkAdded = (rm) => {

        console.log('REMARK ADDED ')
        console.log(rm)

        let rms = [...productRemarks]
        rms.push(rm)
        setProductRemarks(rms)
        closeModals()
    }

    const prodPicAdded = (pic, isArray) => {
        let pps = [...productPics] ?? []

        if (isArray) {
            pps.push.apply(pps, pic)
        } else {
            pps.push(pic)
        }

        setProductPics(pps)
        closeModals()
    }

    const prodMeasurementAdded = (pm) => {
        let pms = [...productMeasurements]
        pms.push(pm)
        setProductMeasurements(pms)
        closeModals()
    }

    const prodMeasurementTemplateSelected = (tmp) => {
        tmp.type = MeasurementType.product
        tmp.product = product
        closeModals()
        setUseProdMesurementTemp(tmp)
    }

    const prodMeasurmentUpdated = (pm) => {
        let pms = [...productMeasurements]
        const index = pms.findIndex((thisPm) => {
            return thisPm.objectId === pm.objectId
        })

        if (index != null) {
            pms[index] = pm
        }
        setProductMeasurements(pms)
        closeModals()
    }


    const measurementRemoved = (pm) => {
        let pms = [...productMeasurements]
        let newPms = pms.filter((thisPm) => {
            return thisPm.objectId !== pm.objectId
        })

        setProductMeasurements(newPms)
        closeModals()
    }


    const addImagePressed = () => {
        setAddProdPic(true)
    }

    const addMeasurementPressed = () => {
        setAddProdMesurement(true)
    }

    const addRemarkPressed = () => {
        setAddProdRemark(true)
    }

    const imagesSelected = (pp) => {
        console.log('Image was selected')
        setViewProdPic(pp)
    }

    const imageDeleted = (imgObj) => {
        // Find this image and remove
        let ppCopy = [...productPics]
        let fileteredPP = ppCopy.filter((thisPP) => {
            return thisPP.url !== imgObj.url
        })

        setProductPics(fileteredPP)
    }

    const measurementSelected = (pm) => {
        console.log('Measurement was selected')
        setViewProdMesurement(pm)
    }



    const searchProductImages = () => {
        if (pf === true) {
            // console.log('FETCHING PRODUCT PICS for product = ' + product.objectId)
            setPF(false)
            ProductPic.getAll(product, (success, pics, errorMsg) => {
                if (success) {
                    setProductPics(pics)


                } else {
                    cogoToast.error('Could not fetch Product Images. An error occured.')
                    // console.log(errorMsg)
                }


            })
        } else {
            // alert('PRODUCT FETCH FALSE')
        }
    }


    const searchProductMeasurements = () => {
        if (mf === true) {
            // console.log('FETCHING PRODUCT MEASUREMENTS for product = ' + product.objectId)
            setMF(false)

            Measurement.getAll(MeasurementType.product, null, product, (success, msts, errorMsg) => {
                if (success) {
                    setProductMeasurements(msts)

                } else {
                    cogoToast.error('Could not fetch Product Measurements. An error occured.')
                    // console.log(errorMsg)
                }

            })
        } else {
            // alert('PRODUCT FETCH FALSE')
        }
    }


    const searchProductRemarks = () => {
        if (rf === true) {
            // console.log('FETCHING PRODUCT REMARKS for product = ' + product.objectId)
            setRF(false)

            Remark.getAll(product, null, false, (success, rmks, errorMsg) => {
                if (success) {
                    setProductRemarks(rmks)

                } else {
                    cogoToast.error('Could not fetch Product Remarks. An error occured.')
                    // console.log(errorMsg)
                }

            })
        } else {
            // alert('PRODUCT FETCH FALSE')
        }
    }


    const sendProductNow = (selectedVendor, explicitNextStage) => {
        console.log("SEND PRODUCT PRESSED")

        let npr = Product.copyFrom(product)
        let dept = departmentForscreen(props.screen)
        console.log("DEPT IS " + dept)
        let isVendor = (dept !== departments.Factory)
        // Not needed while moving orders after made
        if (props.screen !== screen.SendOrders_Store_Vendor) {
            isVendor = false
        }
        if (props.screen === screen.SendOrders_FactoryVendorWindow){
            isVendor = true
        }

        console.log("IS VENDOR " + dept)

        if (isVendor && selectedVendor == null) {
            console.log('SELECTED VENDOR LAST CHECK')
            console.log(selectedVendor)

            // alert('No Vendor Selected. Select Again or report a problem.')
            cogoToast.warn('Select a Vendor')
            return
        }
        setSending(true)

        console.log("Updating Product ")
        console.log(npr)

        console.log("explicitNextStage " + explicitNextStage)


        npr.sendToNextStage(props.screen, isVendor, selectedVendor, explicitNextStage, null, (succ, msg) => {
            if (succ) {
                // alert(' PRODUCT SENT TO NEXT STAGE ')

                let completionTitle = nextStageCompletion_Title(props.screen, isVendor, explicitNextStage) ?? "";
                cogoToast.success(completionTitle);

                if (props.productSent) {
                    console.log("Confirming Product Sent");
                    props.productSent(product)
                }
            } else {
                cogoToast.error('Could not move Product. Check and try again.')
                // alert(msg)
            }

            setSending(false)
        })
    }




    const choiceMade = (choice) => {
        console.log('CHOICE MADE IS')
        console.log(choice)
        closeModals()

        let npr = Product.copyFrom(product)


        switch (choice.key) {

            case 'retrial':

                // Send for retrial
                setSending(true)
                npr.sendForRetrial(choice.remark, (succ, errMsg) => {
                    if (succ) {
                        cogoToast.success('Producr Sent for Retrial with changes.')
                        // alert('Product is Sent for Changes')
                        if (props.productSent) {
                            props.productSent(product)
                        }
                    } else {
                        cogoToast.error('Could not send Product for retrial. Check internet or report error.')
                        // console.log('Error while sending product for retrial:  ' + errMsg)
                    }
                    setSending(false)
                });
                break;
            case 'trial':

                // Send  to finishing with remark
                let thisRm = null
                if (choice.remark) {
                    thisRm = (choice.remark !== "") ? ('For Trial: ' + choice.remark) : null
                }

                setSending(true)
                npr.sendToNextStage(props.screen, false, false, Stage.RecievedFromVendor, thisRm, (succ, msg) => {
                    if (succ) {
                        // alert(' PRODUCT SENT TO NEXT STAGE ')
                        cogoToast.success('Product sent for Trial')
                        if (props.productSent) {
                            props.productSent(product)
                        }
                    } else {
                        cogoToast.error('Could not send Product. Check internet or report case.')
                        // alert(msg)
                    }

                    setSending(false)
                });
                break;
            case 'finishing':

                // Send  to finishing with remark
                let rm = (choice.remark !== "") ? ('For Finishing: ' + choice.remark) : null



                setSending(true)
                npr.sendToNextStage(props.screen, false, false, null, rm, (succ, msg) => {
                    if (succ) {
                        // alert(' PRODUCT SENT TO NEXT STAGE ')
                        cogoToast.success('Product sent for Finishing')
                        if (props.productSent) {
                            props.productSent(product)
                        }
                    } else {
                        cogoToast.error('Could not send Product. Check internet or report case.')
                        // alert(msg)
                    }

                    setSending(false)
                });

                break;
            case 'delivery':

                // Send straigt to delivery
                setSending(true)
                npr.sendToNextStage(props.screen, false, null, Stage.Finished, null, (succ, msg) => {
                    if (succ) {
                        // alert(' PRODUCT SENT TO NEXT STAGE ')
                        cogoToast.success('Product sent to Delivery')
                        if (props.productSent) {
                            props.productSent(product)
                        }
                    } else {
                        cogoToast.error('Could not send Product. Check internet and try again.')
                        // alert(msg)
                    }

                    setSending(false)
                });

                break;
            default: break;

        }


    }

    const trialChoices = [
        {
            key: 'retrial',
            title: 'Send for Retrial (To Vendor/Factory)',
            needsRemark: true,
            remark: '',
            remarkPlaceholder: 'Add Changes Needed before Retrial',
            buttonTitle: 'Send to Vendor/Factory',
            highlight: false
        },
        {
            key: 'finishing',
            title: 'or Send to Finishing',
            needsRemark: true,
            remark: '',
            remarkPlaceholder: 'Add Finishing Points',
            buttonTitle: 'Send to Finishing',
            highlight: false
        },
        {
            key: 'delivery',
            title: 'or Send to Delivery',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Ready to Deliver',
            highlight: true
        }
    ]

    const finishingChoices = [
        {
            key: 'retrial',
            title: 'Send for edits To Vendor/Factory',
            needsRemark: true,
            remark: '',
            remarkPlaceholder: 'Add Changes Needed before Retrial (optional)',
            buttonTitle: 'Send to Vendor/Factory',
            highlight: false
        },
        {
            key: 'trial',
            title: 'or Send for Trial',
            needsRemark: true,
            remark: '',
            remarkPlaceholder: 'Add comment if needed (optional)',
            buttonTitle: 'Send for Trial',
            highlight: false
        },
        {
            key: 'delivery',
            title: 'or Mark Final',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Ready to Deliver',
            highlight: true
        }
    ]



    const handleFinishingScreen = () => {

        // Show Modal to choose 
        // Send to Factory for Retrial
        // Send to Finishing 
        // Perfect, Ready to deliver.
        setShowFinishingChoices(true)
    }



    const handleTrialScreen = () => {

        // Show Modal to choose 
        // Send to Factory for Retrial
        // Send to Finishing 
        // Perfect, Ready to deliver.
        setShowTrialChoices(true)
    }


    const sendProductPressed = (explicitStage) => {

        if (props.screen === screen.ApproveTrial) {
            handleTrialScreen()
            return
        }

        if (props.screen === screen.ApproveFinishing) {
            handleFinishingScreen()
            return
        }

        let dept = departmentForscreen(props.screen)
        let isVendor = (dept !== departments.Factory)
        // Not needed while moving orders after made
        if (props.screen !== screen.SendOrders_Store_Vendor) {
            isVendor = false
        }
        if (props.screen === screen.SendOrders_FactoryVendorWindow){
            isVendor = true
        }


        console.log("DEPT IS " + dept)
        console.log("IS VENDOR = " + isVendor)



        if (isVendor) {
            console.log("CASE 1 ")

            // Show Vendor Selection
            if (product.vendor == null) {
                setShowVendors(true)
            } else {
                sendProductNow(product.vendor, explicitStage)
            }
        } else {
            console.log("CASE 2 ")

            sendProductNow(null, explicitStage)
        }

    }

    const vendorIsChosen = (vendor) => {
        console.log('VENDOR IS SELECTED')
        console.log(vendor)

        closeModals()
        // continue Sending the product here now
        setTimeout(() => {
            sendProductNow(vendor)
        }, 600)

    }



    const fields = () => {

        console.log('RENDERING FIELDS')

        return (
            <div>

                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Product:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className=" ">
                            {
                                product.objectId ?
                                    <label className="label type70 has-text-grey has-text-weight-bold  marginTop10 ">{product.type}</label>
                                    :
                                    <span className="select has-text-weight-semibold type65 ">
                                        {ProductType_options_NonFormik('type', '  ', productTypeChanged, product.type)}
                                    </span>

                            }
                            {/* <label>{ product.type}</label> */}

                        </div>

                    </Columns.Column>
                </Columns>


                { product.objectId ?
                    <div>
                        <Columns className='is-mobile'>
                            <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                <label className="label type55  marginTop10 has-text-right">Style Name:</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className="control width180">
                                    <div className='field '>
                                        <input name='styleNo' defaultValue={product.styleNo} placeholder='Style No.' type='input' className='input field has-text-weight-semibold type65' onChange={e => { productStyleChanged(e) }} />
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>

                        <Columns className='is-mobile' >
                            <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                <label className="label type55  marginTop10 has-text-right">Style Selections:</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className="control ">
                                    <div className='field '>
                                        <div className='type70 has-text-grey marginTop8 has-text-weight-semibold'> {product.styleSelections ? `${product.styleSelections.length} Selections` : ""}


                                            {/* <Button className="  no-decoration is-pulled-right  is-text is-small has-text-weight-bold" onClick={toggleStyleSelectionsSelected}><FontAwesomeIcon className='' icon={faEdit} /></Button> */}

                                            <Button className=" is-small is-rounded is-pulled-right marginBottom10 " onClick={toggleStyleSelectionsSelected}>Edit</Button>

                                        </div>

                                        {/* <textarea disabled={enableDescription ? false : true} name='description' defaultValue={product.description ?? null} placeholder='Description' type='input' className='input textarea has-text-weight-medium type65' onChange={e => { productDescriptionChanged(e) }} /> */}
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>

                        <Columns className='is-mobile'>
                            <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                <label className="label type55  marginTop10 has-text-right">Materials:</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className="control ">
                                    <div className='field '>
                                        <div className='type70 has-text-grey marginTop8 has-text-weight-semibold'> {product.materialToSource ? (product.materialToSource.length ? `${product.materialToSource.join(", ")}` : "") : ""}
                                            {/* <Button className="  no-decoration is-pulled-right  is-text is-small has-text-weight-bold" onClick={toggleMaterials}><FontAwesomeIcon className='' icon={faEdit} /></Button> */}
                                            <Button className=" is-small is-rounded is-pulled-right marginBottom10 " onClick={toggleMaterials}>Edit</Button>

                                        </div>

                                        {/* <textarea disabled={enableDescription ? false : true} name='description' defaultValue={product.description ?? null} placeholder='Description' type='input' className='input textarea has-text-weight-medium type65' onChange={e => { productDescriptionChanged(e) }} /> */}
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>
                    </div>
                    : null
                }




                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Trial Date:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='trialDate' placeholderText='mm/dd/yyyy' selected={product.trialDate ?? null} onChange={date => productTrialChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Delivery Date:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='deliveryDate' placeholderText='mm/dd/yyyy' selected={product.deliveryDate ?? null} onChange={date => productDeliveryChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                {
                    (product.vendor != null) ?
                        <Columns className='is-mobile'>
                            <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                <label className="label type55  marginTop10 has-text-right">Vendor:</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='type70 has-text-grey marginTop8 has-text-weight-semibold'> {product.vendorName} </div>
                                {/* <div className="control is-fullwidth is-expanded"> */}
                                {/* <DatePicker onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='deliveryDate' placeholderText='mm/dd/yyyy' selected={product.deliveryDate ?? null} onChange={date => productDeliveryChanged(date)} /> */}
                                {/* </div> */}
                            </Columns.Column>
                        </Columns>
                        :
                        null
                }




                {/* <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Style Description:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <Button className="marginTopMinus20  no-decoration is-pulled-right  is-text is-small has-text-weight-bold" onClick={showDescriptionChanged}><FontAwesomeIcon className='' icon={faEdit} /></Button>
                                <textarea disabled={enableDescription ? false : true} name='description' defaultValue={product.description ?? null} placeholder='Description' type='input' className='input textarea has-text-weight-medium type65' onChange={e => { productDescriptionChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns> */}






                {(product.objectId == null) ? <div> <br /> <div className=' type70 is-centered has-text-centered has-text-grey '> Save product once to add assets. </div> </div> : null}


                <br />
                {(product.objectId != null) ? remarksContainer() : null}

                <br />
                {(product.objectId != null) ? prodImagesContainer() : null}

                <br />
                {(product.objectId != null) ? measurementsContainer() : null}


                {(product.objectId != null) ? <div> <br /> <br /> </div> : null}



                {(isNewProduct !== true) ?

                    <div>
                        {
                            (nextStageForScreen(props.screen) != null && actingOnProductsAllowed()) ?
                            <div>
                                {
                                    usesVendorViaFactory && props.screen === screen.OrdersRecieved_Factory ?
                                        <div className="buttons has-addons is-centered">
                                            <Button loading={sending} disabled={sending} className=' sendProductButton' onClick={ () => { sendProductPressed() } } >  {nextStageForScreen_Title(props.screen)}    <FontAwesomeIcon className='marginLeft10 is-primary' icon={faPaperPlane} /> </Button>
                                            <Button className=' sendProductButton' loading={sending} disabled={sending} onClick={() => { sendProductPressed(Stage.FactoryRecievedForVendor) } } >   {nextStageForScreen_Title(props.screen, false, Stage.FactoryRecievedForVendor)}  &nbsp; <FontAwesomeIcon className='marginLeft10 is-primary' icon={faPaperPlane} /> </Button>

                                        </div> 
                                    :
                                        <div className="buttons has-addons is-centered">
                                            <Button loading={sending} disabled={sending} className=' sendProductButton' onClick={ () => { sendProductPressed() }} >  {nextStageForScreen_Title(props.screen)}    <FontAwesomeIcon className='marginLeft10 is-primary' icon={faPaperPlane} /> </Button>
                                        </div> 
                                }
                            </div>
                            :
                            null
                        }   
                    </div>
                    :
                    null
                }

                <br />

                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveProduct} > Save </Button>

            </div>
        )

    }


    const editRemarkPressed = (rm) => {
        // console.log("Edit Remark Pressed on")
        // console.log(rm)
        setEditSelectedRemark(rm)
    }


    const remarksContainer = () => {


        if (isNewProduct) {

        } else {
            searchProductRemarks()
        }

        return (
            <div className='marginTop20'>
                {props.addingPicsMeasurementsAllowed ?? true ? <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={product.objectId == null} onClick={addRemarkPressed} > Add </Button> : null}
                <Heading className=" type55 is-uppercase  ">Remarks </Heading>
                <div className='marginHorizontal8'>
                    {
                        productRemarks.map((rm, index) => {
                            return <div key={index} className="type60 has-text-weight-normarl has-text-grey marginBottom6 leftMarginMinus8 " ><Button className="is-small is-text textDecorationNone leftMarginMinus8 topMarginMinus6 has-text-grey-lighter" onClick={() => { editRemarkPressed(rm) }}><FontAwesomeIcon className='' icon={faEdit} /></Button> {rm.remark}   <span className='type50'>· by {rm.byName} </span> </div>
                        })
                    }
                </div>
                <hr />
            </div>
        )

    }

    const prodImagesContainer = () => {

        if (isNewProduct) {

        } else {
            searchProductImages()
        }

        return (
            <div className='marginTop20'>
                {addingPicsMeasurementsAllowed() ? <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={product.objectId == null} onClick={addImagePressed}> Add </Button> : null}
                <Heading className=" type55 is-uppercase  ">Images </Heading>
                <div className=" bodyInfoContainer">
                    {
                        productPics.map((im, index) => {
                            return <div key={index} className="bodyInfoImage">
                                {<Image src={im.url} size={128} onClick={() => { imagesSelected(im) }} />}
                            </div>
                        })
                    }
                </div>
                <hr />
            </div>
        )

    }


    const measurementsContainer = () => {


        if (isNewProduct) {

        } else {
            searchProductMeasurements()
        }

        return (
            <div className='marginTop20'>
                {props.addingPicsMeasurementsAllowed ?? true ? <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={product.objectId == null} onClick={addMeasurementPressed}> Add </Button> : null}
                <Heading className=" type55 is-uppercase  ">Measurements </Heading>
                <div className=" measurementInfoContainer">

                    {
                        productMeasurements.map((mt, index) => {
                            return <div key={index + mt.name} className="bodyInfoMeasurement">
                                {
                                    <Box className='is-radiusless' onClick={() => { measurementSelected(mt) }} >
                                        <div className='type55 has-text-weight-semibold meginBottom10'> {mt.name} </div>
                                        <Table className='is-hoverable is-size-7'>
                                            <tbody>
                                                {
                                                    mt.measures.map((ms, index) => {
                                                        return <tr key={index}><td className='type50'>{ms.name}</td><td className='has-text-right type50'>{ms.value}&nbsp;{ms.unit}</td></tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Box>
                                }
                            </div>
                        })
                    }
                </div>
                <hr />
            </div>
        )

    }



    const printPressed = () => {
        setProductPrint(true)
    }


    const dropdownValChanged = (val) => {
        if (val === "productType") {
            toggleProductEdit()
        }

        if (val === "cancelProduct") {
            toggleCancelProduct()
        }

        if (val === "hardResetStage") {
            toggleProductResetStage()
        }

    }





    const existingProductHeader = (product) => {

        // console.log('PRODUCT IS ')
        // console.log(product)

        return (
            <div>

                {/* <Button key='1' className="is-size-7  has-text-grey is-pulled-right no-decoration is-light is-small" onClick={printPressed}> Email </Button> */}

                <div className='type50 is-capitalized is-family-monospace has-text-grey-light  has-text-centered'> {product.isGrouped ? 'Part of ' + product.groupType : null} </div>
                <p className='is-size-5-mobile has-text-grey-lighter has-text-semibold has-text-centered has-text-weight-semibold'>  Product Details </p>
                <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered bottomMarginMinus10' > {product.productId} </Heading>

                {/* { product.isCancelled ?  <Heading size={6} className='has-text-danger has-text-centered'>  CANCELLED </Heading>  : null} */}

                {/* { product.addedByUser ?  <div className='type50 has-text-centered is-centered'> &nbsp; via {product.addedByUser.name} &nbsp; </div>  : null} */}
                <div className="is-centered has-text-centered topMarginMinus10">
                    {
                        (product.stage === Stage.Delivered) ?
                            <Tag color="success" className="has-text-weight-medium" rounded> {product.stage} </Tag>
                            :
                            <Tag color="" className="has-text-info" rounded> {product.stage} </Tag>

                    }
                </div>


                {/* <Heading  size={6} className={product.stage === Stage.Delivered ? 'is-size-6 has-text-success has-text-medium has-text-centered' : 'is-size-6 has-text-grey has-text-medium has-text-centered'} > {product.stage}  </Heading> */}
                {product.retrial > 0 ? <p className='is-size-7-mobile type74 has-text-danger  has-text-centered marginBottom20' > {'Fit ' + product.retrial}  </p> : null}

                <br />

            </div>
        )
    }

    const newProductHeader = () => {
        return (
            <div>
                <Heading key='3' size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > New Product </Heading>
                <br />
            </div>
        )
    }


    const deleteSelectedRemark = () => {
        let rm = Remark.copyFrom(editSelectedRemark)

        rm.delete((succ, errMsg) => {
            if (succ) {
                let prs = [...productRemarks]
                prs = prs.filter((thisRm) => {
                    return (thisRm.objectId !== editSelectedRemark.objectId)
                })
                setProductRemarks(prs)
                closeModals()
            } else {
                cogoToast.error("Could not delete remark. Please check internet and try again.")
            }
        })
    }

    const remarkEditChoiceMade = (choice) => {
        console.log('CHOICE MADE IS')
        console.log(choice)
        closeModals()

        switch (choice.key) {
            case 'delete':

                deleteSelectedRemark()

                break;
            case 'back':
                break;
            default: break;

        }
    }


    const remarkEditChoices = () => {
        let choices = [
            {
                key: 'delete',
                title: editSelectedRemark.remark,
                needsRemark: false,
                remark: '',
                remarkPlaceholder: '',
                buttonTitle: 'Delete this Remark',
                highlight: false
            },
            {
                key: 'back',
                title: '',
                needsRemark: false,
                remark: '',
                remarkPlaceholder: '',
                buttonTitle: 'Back',
                highlight: false
            }
        ]
        return choices
    }


    const productPrintPressed = () => {



        console.log("ORDER DETAILS ARE")
        console.log(props.order)

        console.log("ORDER customerObjId")
        console.log(props.order.customerObjId)

        // order.customerObjId  get customer details from hee

        Customer.getCompleteInfo(props.order.customerObjId, (success, customer, errMsg) => {
            if (success) {
                // cogoToast.success("Got Customer complete info")
                console.log("Customer complete info is")
                console.log(customer)
                setCustomer(customer)


                setPrintingOrder(true)



            } else {
                console.log("Could not fetch customer complete info for order print")
                cogoToast.error("")
            }

            // setPrintingOrder(true)
        })


    }


    const modal = () => {



        return (
            <div>

                { printingOrder ? <PrintOrder order={props.order} customer={customer} products={[product]} sheetType={PrintSheetType.productSheet} closed={closeModals} /> : null}
                {showTrialChoices ? <ChoiceModal choices={trialChoices} choiceMade={choiceMade} closed={closeModals} /> : null}
                {showFinishingChoices ? <ChoiceModal choices={finishingChoices} choiceMade={choiceMade} closed={closeModals} /> : null}

                {productPrint ? <ProductPrint product={product} productPics={productPics} productMeasurements={productMeasurements} productRemarks={productRemarks} closed={closeModals} /> : null}

                {showVendors ? <VendorsModal isToChoose={true} vendorChosen={vendorIsChosen} closed={closeModals} /> : null}
                {(viewProdPic !== null) ? <ImageViewer imgObj={viewProdPic} type='productPic' closed={closeModals} imageDeleted={imageDeleted} /> : null}
                {(addProdPic === true) ? <ImageUploader customer={null} product={product} type={picType.ProductPic} closed={closeModals} imageAdded={prodPicAdded} /> : null}
                {(addProdRemark === true) ? <RemarkModal product={product} closed={closeModals} errorOccured={errorOccured} remarkAdded={prodRemarkAdded} /> : null}
                {editSelectedRemark ? <ChoiceModal choices={remarkEditChoices()} choiceMade={remarkEditChoiceMade} closed={closeModals} /> : null}

                {styleSelectionsSelected ? <StylesModal product={product} closed={closeModals} stylesAreSaved={stylesAreSaved} /> : null}
                {materialSelected ? <MaterialModal product={product} closed={closeModals} materialsUpdated={materialsUpdated} /> : null}
                {productEditSelected ? <ProductEditModal product={product} closed={closeModals} productEdited={productEdited} /> : null}
                {productResetStageSelected ? <ProductEditResetStageModal product={product} closed={closeModals} productEdited={productEdited} /> : null }
                {cancelProductSelected ? <ProductCancelModal product={product} closed={closeModals} productCancelled={productCancelled} /> : null}

                {(viewProdMesurement !== null) ? <MeasurementModal purpose='edit' measurement={viewProdMesurement} product={product} type={MeasurementType.product} closed={closeModals} measurementUpadted={prodMeasurmentUpdated} measurementRemoved={measurementRemoved} /> : null}
                {(useProdMesurementTemp !== null) ? <MeasurementModal purpose='useTemplate' measurement={useProdMesurementTemp} product={product} type={MeasurementType.product} closed={closeModals} templateUsed={prodMeasurementAdded} /> : null}
                {(addProdMesurement === true) ? <MeasurementTemplates type={MeasurementType.product} closed={closeModals} selectedTemplate={prodMeasurementTemplateSelected} isToSelectTemplate={true} /> : null}



                <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                    <Modal.Content className='is-padingless has-background-light'>

                        {
                            isNewProduct ? null :
                                <Dropdown className=" is-arrowless is-grey noDropdownIcon ml-2" color="light" label='•••' onChange={(val) => { dropdownValChanged(val) }} >
                                    <Dropdown.Item value="productType" >
                                        Product Type
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item value="hardResetStage">
                                        Hard Reset Stage
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item value="cancelProduct">
                                        Cancel Product
                                    </Dropdown.Item>
                                </Dropdown>
                        }

                        {(isNewProduct === true) ? null :
                            <Button className=" marginTop10 marginRight10  has-text-grey-light is-pulled-right  no-decoration is-text " onClick={productPrintPressed} > <FontAwesomeIcon className=' type74' icon={faFileAlt} /></Button>
                        }


                        <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>



                            {(isNewProduct === true) ? newProductHeader() : existingProductHeader(props.product)}

                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {fields()}
                            </ColumnAux>

                        </Box>
                    </Modal.Content>
                </Modal>
            </div>


        )
    }

    return modal()

}

export default ProductModal