import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Table, Dropdown } from 'react-bulma-components/dist';

import ColumnAux from '../../containers/ColumnAux'
import { LocationType, Position } from '../../utilities/UserClasses'
import {  getUsers, getCurrentUser, getPositions, getCurrentLocation, getAptLocations , locationFromId} from '../../utilities/Store';
import { hasFactory, showOnlyClientFeature } from '../../ClientInfo/clientInfo';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';

import cogoToast from 'cogo-toast';

const PositionModal = (props) => {



    const setClosed = () => {
        props.closed()
    }

    const getLocation = (pos) => {
        let loc = null
        if (pos){
            if (pos.forLocation){
                loc = pos.forLocation
            }
        }
        return loc
    }

    let [position, setPosition] = useState(props.position)
    let [saving, setSaving] = useState(false)


    let allPositions = getPositions()

    // {(newposition === true) ? <positionModal customer={customer} isNewposition={true} positionAdded={newpositionAdded} positionUpdated={positionWasUpdated} closed={setNoSelected} /> : null}



    const saveposition = () => {

        console.log("IS NEW POSITION = " + props.isNewposition);

        if (position.name == null) {
            // alert('No Position Name set')
            cogoToast.warn('Add Position Name')
            return
        }

        if (position.name.length <= 2) {
            // alert('Position Name must be atleast 3 characters long.')
            cogoToast.warn('Position Name must be atleast 3 characters long')
            return
        }

        if (props.isNewposition) {

            let thisPos = Position.copyFrom(position)

            if (getCurrentLocation().isFranchise || getCurrentUser.isFranchiseAdmin) {
                thisPos.forLocation = getCurrentLocation()
            }

            // save 
            setSaving(true)
            thisPos.add((succ, result, errMsg) => {
                setSaving(false)
                if (succ) {
                    props.positionAdded(result)
                } else {
                    cogoToast.error('Could not save Position. Check internet and try again.')
                    // alert(errMsg)
                }
            })

        } else {
            // update

            console.log('COPINGF FROM POS IS')
            console.log(position)


            let thisPos = Position.copyFrom(position)


            console.log('OBJECT ID OF OBJECT = ', thisPos.objectId)
            console.log('POS IS')
            console.log(thisPos)

            setSaving(true)
            thisPos.update((succ, errMsg) => {
                setSaving(false)
                if (succ) {
                    props.positionUpdated(position)
                } else {
                    console.log("ERROR = " + errMsg);
                    cogoToast.error('Could not update Position. Check internet and try again.')
                    // alert(errMsg)
                }
            })

        }


    }


    const positionNameChanged = (e) => {
        console.log('position NO WAS TRIED TO CHANGE')
        let o = { ...position }
        o.name = e.target.value
        setPosition(o)
    }

    const locationIsChanged = (e) => {
        console.log('Location is changed to : ' + e.target.value)

        let thisLoc = locationFromId(e.target.value)
        // setPositionOptions(calculatePositionOptions(thisLoc))
        let o = { ...position }
        o.forLocation = thisLoc
        setPosition(o)
    }



    const createTables = () => {
        let storeControl = position.control.ordersControl.storeControl.allPropertiesKeysWithAllowedStatus()

        if (showOnlyClientFeature){
            storeControl = position.control.ordersControl.storeControl.clientOnly_PropertiesKeysWithAllowedStatus()
        }

        delete storeControl['isAllowed']


        // if (!hasFactory){
        // }
        let factoryControl = position.control.ordersControl.factoryControl.allPropertiesKeysWithAllowedStatus()

        
        let vendorControl = position.control.ordersControl.vendorControl.allPropertiesKeysWithAllowedStatus()
        delete vendorControl['searchOrders']

        // let transitControl = position.control.transitControl.allPropertiesKeysWithAllowedStatus()

        let clientsControl = position.control.clientsControl.allPropertiesKeysWithAllowedStatus()
        delete clientsControl['isAllowed']
        delete clientsControl['messages']
        delete clientsControl['notifications']


        // let stocksControl = position.control.stocksControl.allPropertiesKeysWithAllowedStatus()


        let reportsControl = position.control.reportsControl.allPropertiesKeysWithAllowedStatus()
        delete reportsControl['isAllowed']
        delete reportsControl['stockReports']

        if (showOnlyClientFeature){
            reportsControl = position.control.reportsControl.clientOnly_PropertiesKeysWithAllowedStatus()
        }

        // console.log("STORE CONTROL = ")
        // console.log(storeControl)

        // console.log("VENDOR CONTROL = ")
        // console.log(vendorControl)

        // console.log("CLIENTS CONTROL = ")
        // console.log(clientsControl)

        // console.log("REPORTS CONTROL = ")
        // console.log(reportsControl)

        let cl = getCurrentLocation()

        // console.log("!!!!!!!")
        // console.log("CURRENT LOCATION IS")
        // console.log(cl)


        let allControls = [
            { title: 'Store ', controlName: 'storeControl', control: storeControl },
            { title: 'Vendors ', controlName: 'vendorControl', control: vendorControl },
            { title: 'Clients ', controlName: 'clientsControl', control: clientsControl },
            { title: 'Reports ', controlName: 'reportsControl', control: reportsControl },
        ]


        if (hasFactory){
            let factoryControlObj =  { title: 'Factory ', controlName: 'factoryControl', control: factoryControl };
            allControls.splice(1, 0, factoryControlObj);
        }

        if (cl.isFranchise === true || cl.type === LocationType.franchise){
            // Remove VENDORS & FACTORY
            allControls = [
                { title: 'Store ', controlName: 'storeControl', control: storeControl },
                { title: 'Clients ', controlName: 'clientsControl', control: clientsControl },
                { title: 'Reports ', controlName: 'reportsControl', control: reportsControl },
            ]

        }


        if (showOnlyClientFeature) {
            allControls = [
                { title: 'Store ', controlName: 'storeControl', control: storeControl },
                { title: 'Clients ', controlName: 'clientsControl', control: clientsControl },
                { title: 'Reports ', controlName: 'reportsControl', control: reportsControl },
            ]
        }


        // console.log("________ALL CONTROLS ARE________");
        // console.log(allControls)

        return allControls.map((cr) => {
            return createControlTable(cr.title, cr.controlName, cr.control)
        })


    }

    const createControlTable = (title, controlName, control) => {


        // console.log("Title = ", title)
        // console.log("controlName = ", controlName)
        // console.log("Control = ")
        // console.log(control)

        return (
            <div key={title}>
                < Table  striped={false}  >
                    <thead>
                        <tr>
                            <th className='is-size-6' > {title} </th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(control).map((key, index) => {

                            // if (index === 0) {
                            //     console.log('Sending ControlName=' + controlName + ' Key=' + key)
                            // }

                            return createRow(controlName, key, control[key])
                        })}
                    </tbody>
                </Table >
                <br />
            </div>
        )
    }


    const createRow = (controlName, key, val) => {

        // console.log('ControlName IS = ' + controlName)
        // console.log('KEY IS = ' + key)
        // console.log('VAL IS = ' + val)


        return (
            <tr key={controlName + key} className='' onClick={()=> {featureControlChanged(controlName, key, val)} }>
                <td>
                    <div className=' padVertical10 type74'>
                        {camelToSentenceCase(key)}
                    </div>
                </td>
                <td align="center" className="is-narrow" >
                    <div className=' padVertical10 '>
                    {
                        val === true ?
                        <MdCheckBox  className="is-size-4" /> :

                        <MdCheckBoxOutlineBlank className="is-size-4"  />
                    }
                        {/* <input type="checkbox" className='largerCheckbox ' onChange={(e) => { featureControlChanged(controlName, key, e) }} defaultChecked={val} /> */}
                    </div>
                </td>
            </tr>
        )

    }


    const camelToSentenceCase = (text) => {
        var result = text.replace(/([A-Z])/g, " $1");
        var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }






    // const updateObject = (keyLevel1, keyLevel2, value) => {
    //     ley obj = {...myObject}

    //     obj.keyLevel1.keyLevel2 = value
    //     obj['keyLevel1']['keyLevel2'] = value

    //     setMyObject(obj)
    // }

    const featureControlChanged = (controlName, key, val) => {
        console.log('' + controlName + ' ' + key + ' value changed to = ' + !val)
        let value = !val
        let o = { ...position }

        if (['storeControl', 'factoryControl', 'vendorControl'].includes(controlName)) {
            o['control']['ordersControl'][controlName][key] = value
        } else {
            o['control'][controlName][key] = !val
        }

        setPosition(o)
        console.log('Position Changed to ')
        console.log(position)
    }



    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>


                <br />

                {/* <Table>
                    <thead>
                        <tr>
                            <th> Store </th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>New Orders</td>
                            <td className='has-text-right'>Checkmark</td>
                        </tr>
                    </tbody>
                </Table> */}


                {createTables()}


                <br />
                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveposition} > Save </Button>
                <br />


            </div>
        )

    }


    const tryDeletePosition = () => {
        let allUsers = getUsers()
        let isInUse = false

        allUsers.map((thisUser) => {
            if (thisUser.position.objectId === position.objectId) {
                isInUse = true
            }
        })

        if (isInUse) {
            cogoToast.warn("Position is in use. Position can only be deleted if no user is using it.")
            return
        }

        let thisPos = Position.copyFrom(position)
        setSaving(true)
        thisPos.delete((succ, errMsg) => {
            setSaving(false)
            if (succ) {
                props.positionDeleted(position)
            } else {
                cogoToast.error('Could not delete Position. Check internet and try again.')
            }
        })
    }

    const dropDownChoiceMade = (val) => {
        if (val === 'delete') {
            tryDeletePosition()
        }
    }


    const modal = () => {

        createTables()

        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless has-background-light'>
                    <div>
                        <Dropdown className=" is-small is-arrowless  is-pulled-left has-background-white noDropdownIcon" color="light" label='•••' onChange={(val) => { dropDownChoiceMade(val) }} >
                            <Dropdown.Item value="delete">
                                Delete
                        </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>


                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.isNewposition ? 'New Position' : 'Position Details'}  </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                        <div className='select is-fullwidth'>
                                            <select name='location' className='select ' defaultValue={getLocation(position)} onChange={ (e) => {locationIsChanged(e)} }>

                                                {
                                                    getAptLocations().map((loc, index) => {
                                                        return <option key={index} value={loc.objectId} > {loc.type + " • " + loc.name} </option>
                                                    })
                                                }

                                            </select>
                                        </div>


                            <div className='field is-centered'>
                                {/* <label className="label type60 ">Email</label> */}
                                <input name={position.name} defaultValue={position.name} placeholder={'Name of Position'} type='input' className='input  has-text-weight-semibold type65' onChange={e => { positionNameChanged(e) }} />
                            </div>

                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default PositionModal