import React, {  useState } from 'react'
import { withRouter } from 'react-router-dom'
// import 'react-bulma-components/basic/react-bulma-components.min.css';
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {  Navbar, Image } from 'react-bulma-components/dist';
import MenuProvider from './MenuProvider'
import {  departments, departmentForscreen } from '../../utilities/enums'
import { getCurrentUserInfo } from '../../utilities/Store'
import userImage from '../../assets/userImage.svg'
import { clientMenuLogoUrl, hasFactory, showOnlyClientFeature } from '../../ClientInfo/clientInfo'
import packageJson from '../../../package.json'



const MainMenu = (props) => {

  let [active, setActive] = useState(false)
  let [user, setUser] = useState(getCurrentUserInfo())
  const [openDepts, setOpenDepts] = useState([props.currentDept])


  // useEffect(()=> {

  //   let userInLDB = getCurrentUserInfo()
  //   console.log('USER in LDB =')
  //   console.log(userInLDB)
  //   setUser( userInLDB )
  // }, [])

  const linkClickedTo = (screen) => {
    console.log('Page Clicked =' + screen)
    props.linkClickedTo(screen)
    toggleMenu()
    setOpenDepts([departmentForscreen(screen)])

  }

  const navDepToggle = (dep) => {

    console.log('Nav Dep Toggle ' + dep)
    // console.log('OPEN DEPS =', openDepts)

    let newDeps = [...openDepts]
    if (openDepts.includes(dep)) {
      newDeps.splice(newDeps.indexOf(dep), 1);
    } else {
      newDeps.push(dep)
    }
    setOpenDepts(newDeps)

  }

  const toggleMenu = () => {
    setActive(!active)
  }


  // const getCurrentUserInfo = () => {
  //     let us = getCurrentUserInfo()
  //     if (us != null){
  //       setUser(us)
  //     }
  // }


  // const position = () => {
  //   let position = getCurrentUserInfo().position
  //   console.log('POSITION IN MENU =')
  //   console.log(position)
  //   return position
  // }

  let nav = () => {

    console.log('RENDERED MAIN MENU')

    let nameOfUser = ''
    let imageUrlOfUser = userImage
    let posAtLocInfo = ''
    let loginInfo = ''

    let us = getCurrentUserInfo()

    if (us != null) {
      nameOfUser = us.name
      if (us.imageUrl) {
        if (us.imageUrl !== "") {
          imageUrlOfUser = us.imageUrl
        }
      }
      posAtLocInfo = us.posAtLocInfo
      loginInfo = us.loginInfo
    }


    const storeM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Store} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Store)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const factoryM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Factory} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Factory)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const vendorM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Vendors} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Vendors)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const clientsM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Clients} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Clients)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const stocksM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Stocks} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Stocks)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const reportsM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Reports} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Reports)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }
    const usersM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Users} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Users)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }

    const settingsM = () => { return <MenuProvider location={props.location} isNavBar={true} department={departments.Settings} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Settings)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} /> }


    const allowedLinks = () => {

      let links = null

      // console.log('USER IS = ')
      // console.log(user)

      if (user.isAdmin) {
        links = (
          <div>
            {storeM()}
            { hasFactory ? factoryM() : null}
            {vendorM()}
            {clientsM()}
            {/* {stocksM()} */}
            {reportsM()}
            {usersM()}
            {settingsM()}
          </div>
        )
      } else {
        links = (
          <div>
            {user.position.control.ordersControl.storeControl.isAllowed === true ? storeM() : null}
            { hasFactory ? (user.position.control.ordersControl.factoryControl.isAllowed === true ? factoryM() : null) : null}
            {user.position.control.ordersControl.vendorControl.isAllowed === true ? vendorM() : null}
            {user.position.control.clientsControl.isAllowed === true ? clientsM() : null}
            {/* {user.position.control.stocksControl.isAllowed === true ? stocksM() : null} */}
            {user.position.control.reportsControl.isAllowed === true ? reportsM() : null}
            {/* {user.position.control.usersControl.isAllowed === true ? usersM() : null} */}
          </div>
        )
      }

        if (showOnlyClientFeature){
          links = (
              <div>
                  {(user.isAdmin || user.isFranchiseAdmin || user.position.control.ordersControl.storeControl.isAllowed === true) ? storeM() : null}
                  {(user.isAdmin || user.isFranchiseAdmin || user.position.control.clientsControl.isAllowed === true) ? clientsM() : null}
                  {(user.isAdmin || user.isFranchiseAdmin || user.position.control.reportsControl.isAllowed === true) ? reportsM() : null}
                  {(user.isAdmin || user.isFranchiseAdmin ) ? usersM() : null}
                  {user.isAdmin   ? settingsM() : null}
              </div>
          )
        }

        return links
    }


    let items = () => {


      if (user === null) {
        console.log('No Menu')
      } else {
        console.log('USER IN MENU IS ')
        console.log(user)
      }


      console.log('USER NAME IN MENU IS - ' + user.name)


      // {(position.ordersControl.storeControl.isAllowed === true) ? storeM() : null}
      // {(position.ordersControl.factoryControl.isAllowed === true) ?  vendorM() : null}
      // {(position.clientsControl.isAllowed === true) ? clientsM() : null}
      // {(position.stocksControl.isAllowed === true) ?  stocksM() : null}
      // {(position.reportsControl.isAllowed === true) ?  reportsM() : null}
      // {position.slidesControl.isAllowed === true ? slidesM() : null}

      return (
        <div className="is-hidden-desktop">

          {allowedLinks()}

          {/* <MenuProvider location={props.location} isNavBar={true} department={departments.Store} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Store)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle}    />
        <MenuProvider location={props.location} isNavBar={true} department={departments.Vendors} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Vendors)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} />
        <MenuProvider location={props.location} isNavBar={true} department={departments.Clients} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Clients)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} />
        <MenuProvider location={props.location} isNavBar={true} department={departments.Stocks} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Stocks)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle}   />
        <MenuProvider location={props.location} isNavBar={true} department={departments.Reports} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Reports)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle} />
        <MenuProvider location={props.location} isNavBar={true} department={departments.Slides} currentScreen={props.currentScreen} opened={openDepts.includes(departments.Slides)} linkClickedTo={linkClickedTo} navdeptoggle={navDepToggle}   /> */}

        </div>
      )
    }

    return (
      <Navbar fixed='top' transparent active={active} className=' has-shadow' >
        <Navbar.Brand >
          <Navbar.Item className='logo'>
            <a href="/">
              <img src={clientMenuLogoUrl} alt="Tailorwise: Streamlined Bespoke Tailoring" width="140" />
            </a>
          </Navbar.Item>
          <Navbar.Burger onClick={toggleMenu} />
        </Navbar.Brand>
        <Navbar.Menu >
          {items()}
          <Navbar.Container position="end">
            <Navbar.Item>  </Navbar.Item>
            <Navbar.Item>  </Navbar.Item>

            <Navbar.Item className="has-dropdown is-hoverable has-user-avatar currentUserNavItem has-text-centered " >
              {/* <Navbar.List>

                </Navbar.List> */}
              {/* {navButtons} */}

              <Navbar.Link arrowless={true} hoverable="true" dropdown="true" className=" has-user-avatar"  >
                <div className="is-user-avatar">
                  <img src={imageUrlOfUser} alt="User Profile " className=" userImage39 " />
                  {/* <img src="https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&amp;options[accessoriesChance]=93" alt="John Doe" /> */}
                </div>
                {/* <div className="is-user-name type60">
                  <span>{nameOfUser}</span>
                </div> */}


              </Navbar.Link>


              <Navbar.Dropdown className="is-right ">
                <p className="has-text-grey-light type50 has-text-centered">{packageJson.version}</p>

                <Navbar.Item className="has-text-grey has-text-weight-light">
                  <p className="has-text-centered" > {posAtLocInfo} </p>
                </Navbar.Item>
                <Navbar.Item className=" type55  has-text-grey has-text-weight-light">
                  <p className="has-text-centered" > {loginInfo} </p>
                </Navbar.Item>
                <hr className="navbar-divider" />
                <Navbar.Item className=" " onClick={props.logout} >
                  Log Out
                </Navbar.Item>
              </Navbar.Dropdown>
              <br />
              <br />
              <br />
            </Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    )
  }


  return (
    nav()
  )

}

// class Menu extends Component {

//   state = {
//     isActive: false,
//     isLoggingOut: false
//   }

//   componentDidMount = () => {
//     // console.log('Menu was mounted')
//     // console.log('Menu Props = ', this.props)
//   }

//   toggleMenu = () => {
//     const newState = { ...this.state }
//     newState['isActive'] = !newState['isActive']
//     this.setState(newState)
//   }

//   linkClickedTo = (pageId) => {
//     // console.log('LinkClickedTo Called on MENU -', pageId)
//     // this.props.clicked(pageId)
//     if (this.props.isLoggedIn) {
//       if (pageId === pages.Dashboard) {
//         this.props.history.push('/dashboard')
//       } else {
//         this.props.history.push('/dashboard/' + pageId)
//       }
//     }
//     this.props.clicked(pageId)
//     this.toggleMenu()
//   }

//   menuButtonPressed = (actionId) => {

//     // console.log('MENU PRESSED')


//     if (actionId === menuButtonActions.SignIn) {
//       this.props.history.replace('/signin')
//     }

//     if (actionId === menuButtonActions.SignUp) {
//       this.props.history.replace('/signup')
//     }

//     if (actionId === menuButtonActions.Logout) {

//       Parse.User.logOut().then(() => {
//         // var currentUser = Parse.User.current();  // this will now be null

//         // newState['currentPageId'] = pages.SignIn
//         // this.setState(newState)

//         this.props.history.replace('/')
//       });

//     }

//     this.toggleMenu()

//     // console.log('Action Taken on MENU -', actionId)
//     // this.props.menuAction(actionId)
//     // const newState =  {...this.state}
//     // newState['isActive'] = false
//     // this.setState(newState)

//     // if (actionId === menuButtonActions.Logout) {
//     //   const newState =  {...this.state}
//     //   newState['isLoggingOut'] = true
//     //   this.setState(newState)

//     //   setTimeout(() => {
//     //     const newState =  {...this.state}
//     //     newState['isLoggingOut'] = false
//     //     this.setState(newState)
//     //   }, 1000);

//     // }


//   }



//   render() {

//     let navButtons = null

//     let navItems = (<div></div>)

//     if (this.props.isLoggedIn === true) {
//       navButtons = (
//         <Button.Group>
//           <Button color='light' loading={this.state.isLoggingOut} href="#" onClick={() => this.menuButtonPressed(menuButtonActions.Logout)}>Logout</Button>
//         </Button.Group>
//       )

//       navItems = (
//         <Navbar.Container className='is-hidden-desktop'>
//           <MenuItem name='Dashboard' active={this.props.pageId === pages.Dashboard} hidden={false} clicked={() => this.linkClickedTo(pages.Dashboard)} />
//           <MenuItem name='Purchases' active={this.props.pageId === pages.Purchases} hidden={false} clicked={() => this.linkClickedTo(pages.Purchases)} />
//           <MenuItem name='Invoices' active={this.props.pageId === pages.Invoices} hidden={false} clicked={() => this.linkClickedTo(pages.Invoices)} />
//           <MenuItem name='Profile' active={this.props.pageId === pages.Profile} hidden={false} clicked={() => this.linkClickedTo(pages.Profile)} />
//           <MenuItem name='Compliance' active={this.props.pageId === pages.Compliance} hidden={false} clicked={() => this.linkClickedTo(pages.Compliance)} />
//           {this.props.showMessages ? <MenuItem name='Messages' active={this.props.pageId === pages.Messages} hidden={false} clicked={() => this.linkClickedTo(pages.Messages)} /> : null}
//           {this.props.showCheckout ? <MenuItem name='Checkout' tagname='1' active={this.props.pageId === pages.Checkout} hidden={false} clicked={() => this.linkClickedTo(pages.Checkout)} /> : null}
//         </Navbar.Container>

//       )



//     return (
//       <Aux>

//         <Navbar fixed='top' transparent active={this.state.isActive} className=' has-shadow' >
//           <Navbar.Brand >
//             <Navbar.Item className='logo'>
//               <img src={ClientLogoMenu} alt="Tailorwise: Streamlined Bespoke Tailoring" width="140" height="30" />
//             </Navbar.Item>
//             <Navbar.Burger onClick={this.toggleMenu} />
//           </Navbar.Brand>
//           <Navbar.Menu >
//             {navItems}
//             <Navbar.Container position="end">
//               <Navbar.Item>
//                 {navButtons}
//               </Navbar.Item>
//             </Navbar.Container>
//           </Navbar.Menu>

//         </Navbar>
//       </Aux>
//     )
//   }

// }



export default withRouter(MainMenu)